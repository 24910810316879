.modal-fullview {
  width: 100% !important;
  max-width: 100% !important;
  height: 100%;
  top: 0;
  margin: 0;
  padding-bottom: 0;

  .text-head {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 30px;
    text-align: center;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-modal-content {
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: scroll;

    .ant-modal-body {
      height: 100%;
      margin-bottom: 5%;

      .ant-picker {
        width: 100%;
        height: 40px;
      }

      .password-requirement {
        h5 {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
        }
        ul {
          padding: 0 !important;
          li {
            margin-top: 5px;
            list-style: none;
            color: rgba(0, 0, 0, 0.45);
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            img {
              margin-top: -3px;
              margin-right: 5px;
            }
          }
        }
      }
      .profile-success-frame {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h1 {
          font-style: normal;
          font-weight: 500;
          font-size: 38px;
          line-height: 46px;
          margin-top: 20px;
          color: #169f5b;
        }
        h4 {
          font-weight: 500;
          font-size: 20px;
          line-height: 28px;
          color: rgba(0, 0, 0, 0.45);
        }
      }
      .change-profile-picture-wrapper {
        width: 465px;
        height: 465px;
        .ant-upload-drag {
          background-color: transparent;
          .ant-upload {
            padding: 0;
          }
        }
        .change-profile-picture-container {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 48px;
          gap: 48px;

          width: 100%;
          // height: 465px;
          border: 1px solid #d9d9d9;

          h3 {
            font-weight: 400;
            font-size: 24px;
            line-height: 32px;
            text-align: center;
          }

          .text-validation {
            font-size: 20px;
            line-height: 28px;
          }
        }

        button {
          padding: 8px 24px;
          height: 40px;
        }
        h5 {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          margin-top: 15px;
        }

        @media (max-width: 767px) {
          width: 100%;
        }
      }

      .crop-profile-picture-wrapper {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .avatar-editor-wrapper {
          border: 1px solid #d9d9d9;
          position: relative;
          height: calc(100% - 10px);
          .loading-overlay {
            background-color: white;
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;

            .loading-container {
              flex-direction: column;
              display: flex;
              height: 100%;
              width: 100%;
              align-items: center;
              justify-content: center;
              font-weight: 400;
              font-size: 24px;
              line-height: 32px;
              text-align: center;
              color: #169f5b;

              .anticon {
                margin-bottom: 20px;
                font-size: 48px;
              }
            }

            h5 {
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              text-align: center;
              margin-top: 15px;
            }
          }
        }

        canvas {
          border: 1px solid #d9d9d9;
          display: block;
          width: 100% !important;
          height: auto !important;
        }
        .zoom-slider {
          margin-top: 25px;
          width: 100%;
          display: flex;
          align-items: center;
          .ant-slider {
            margin-top: 5px;
            width: 100%;
            .ant-slider-track {
              background-color: #02b8f6;
              height: 8px;
              border-radius: 4px;
            }
            .ant-slider-rail {
              height: 8px;
            }
            .ant-slider-handle {
              // opacity: 0;
              height: 24px;
              width: 24px;
              margin-top: -8px;
              background: #02b8f6;
              border: none;
            }
          }

          .anticon {
            width: 24px;
            height: 24px;
            border: 1px solid #d9d9d9;
            padding: 5px;
            border-radius: 100%;
            font-size: 12px;
            cursor: pointer;
          }
        }
        h4 {
          margin-top: 25px;
          font-weight: 500;
          font-size: 20px;
          line-height: 28px;
        }
        a {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #02b8f6;
        }
      }
    }

    // @media (max-height: 850px) {
    //   height: auto;
    // }
  }

  @media (max-width: 576px) {
    .ant-modal-content {
      .ant-modal-body {
        height: 100%;
        .profile-success-frame {
          img {
            height: 250px;
          }
        }
      }
    }
  }
}

.form-modal-fullview {
  .ant-form-item {
    margin-bottom: 0;
  }
}
