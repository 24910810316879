.widget-container {
  background-color: white;
  padding: 25px 24px 10px 25px;

  .widget-title {
    color: rgba(0, 0, 0, 0.45);
  }

  .widget-title-2 {
    color: rgba(0, 0, 0, 0.85);
  }
  .text-head {
    font-size: 30px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0em;
  }

  .text-desc {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
  }

  .widget-border {
    hr {
      border: 1px solid rgba(0, 0, 0, 0.03);
    }
  }

  .graph-filter-container {
    margin-top: -8px;

    .graph-filter {
      height: 22px;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.85);
      padding: 0 17px;
      cursor: pointer;
    }
    .graph-filter-active {
      color: #0ec1fe;
    }
  }

  .widget-list-container {
    ul {
      padding: 0;
      li {
        display: flex;
        list-style: none;
        margin-bottom: 12px;

        .circle-bg {
          height: 20px;
          width: 20px;
          border-radius: 50%;
          background-color: #ff4d4f;
          margin-right: 15px;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          color: $breeze-white;
        }
      }
    }
  }

  .plot-container {
    .plot-title {
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.85);
      padding-bottom: 10px;
    }
  }
  .progress-chart {
    .ant-progress-inner {
      border-radius: 0;
    }
    .ant-progress-bg {
      background-color: #13c2c2;
      border-radius: 0;
    }
  }
}

.custom-tab {
  .tab-item {
    text-align: center;
    width: 48px;
    height: 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $breeze-color-main;
    cursor: pointer;
    padding-bottom: 35px;
  }
  .tab-active {
    border-bottom: 2px solid $breeze-color-main;
    padding-bottom: 33px;
  }

  .tab-border {
    position: relative;
    top: -1px;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.03);
    width: 100%;
  }
}

.custom-tab-2 {
  .tab-item {
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    height: 24px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;
    padding-bottom: 15px;
    margin-right: 10px;
  }
  .tab-active {
    color: $breeze-color-blue-1;

    border-bottom: 2px solid $breeze-color-blue-1;
    padding-bottom: 30px;
  }

  .tab-border {
    position: relative;
    top: -1px;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.03);
    width: 100%;
  }
}

  .dashboard-upcoming-events{
      // width: 100%;
      padding: 24px;
      background: #ffffff;
      margin-bottom: 20px;

      .menus {
          height: 37px;
          background-color: transparent;

          p{
              margin: 0px;
              font-size: 14px;
              line-height: 22px;
          }
      
          li::after {
            border-bottom: none !important;
          }
          li:hover {
            color: $breeze-color-blue-2;
          }
          .ant-menu-item {
              display: flex;
              flex-direction: row;
              padding-right: 5px;
              padding-left: 5px;
          }
          .ant-menu-title-content {
              background-color: #fafafa;
              padding: 8px 16px;
              border-radius: 5px;
              a:hover {
                  color: $breeze-color-blue-2;
              }
          }
          .ant-menu-item-selected {
            color: #fff;
            .ant-menu-title-content {
              background-color: $breeze-color-blue-2;
              padding: 8px 16px;
              border-radius: 5px;
              
              a {
                color: $breeze-color-blue-2;
              }
            }
          }
      }

      .events {
          display: flex;
          margin: 15px 0px;
          flex-direction: row;
          overflow: auto;

          .no-events-container {
            width: 100%;
            height: 406px;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            h4{
                font-size: 16px;
                margin-top: 20px;
                font-weight: 400;
            }
          }

          .loading-container{
            position: relative;
            display: flex;
            flex-direction: column;
            height: 406px;
            width: 99%;
          }

          .event-table{
            width:99%;
            height: 406px;
          }   
       
      }
     
      Button{
          margin-left: 5px;
          border-radius: 5px;
      }
  }

  .dashboard-distribution {
    // width: 100%;
    padding: 24px;
    background: #ffffff;
    margin-bottom: 20px;

    .header {
      font-size: 24px;
      font-weight: 500;
      padding: 16px 0;
    }

    .table {
      margin: 16px 0;
      .header {
        font-weight: 700;
        font-size: 16px;
      }

      .body {

        height: 240px;
        overflow-y: scroll;

        .row {
          margin: 6px 0;

          .col {
            vertical-align: middle;
            
            font-weight: 400;
            font-size: 14px;

            .legend {
              padding: 2px 6px;
              border-radius: 100px;
              font-weight: 400;
              font-size: 12px;
              color: white;
              background-color: #003A8C;
            }
          }
        }
        .row-odd {
          background-color: #F5F5F5;
        }
    
        .row-even {
          background-color: transparent;
        }
      }
    }


    

    .menus {
        height: 37px;
        background-color: transparent;
        p{
            margin: 0px;
            font-size: 14px;
            line-height: 22px;
        }
        li::after {
          border-bottom: none !important;
        }
        li:hover {
          color: $breeze-color-blue-2;
        }
        .ant-menu-item {
            display: flex;
            flex-direction: row;
            padding-right: 5px;
            padding-left: 5px;
        }
        .ant-menu-title-content {
            background-color: #fafafa;
            padding: 8px 16px;
            border-radius: 5px;
            a:hover {
                color: $breeze-color-blue-2;
            }
        }
        .ant-menu-item-selected {
          color: #fff;
          .ant-menu-title-content {
            background-color: $breeze-color-blue-2;
            padding: 8px 16px;
            border-radius: 5px;
            
            a {
              color: $breeze-color-blue-2;
            }
          }
        }
    }

    .events {
        display: flex;
        margin: 15px 0px;
        flex-direction: row;
        overflow: auto;

        .no-events-container {
          width: 100%;
          height: 406px;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;

          h4{
              font-size: 16px;
              margin-top: 20px;
              font-weight: 400;
          }

        }

        .loading-container{
          position: relative;
          display: flex;
          flex-direction: column;
          height: 406px;
          width: 99%;
        }

        .event-table{
          width:99%;
          height: 406px;
        }   
     
    }
   
    Button{
        margin-left: 5px;
        border-radius: 5px;
    }
}

  .dashboard-pending-approval{
      padding: 24px;
      background: #ffffff;
      margin-bottom: 20px;

    .header{
        height: 34px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .menus {
          width: 50%;
          display: flex;
          justify-content: end;
          background-color: transparent;
    
          p{
              margin: 0px;
              font-size: 14px;
              line-height: 22px;
          }
      
          li::after {
            border-bottom: none !important;
          }
          li:hover {
            color: $breeze-color-blue-2;
          }
          .ant-menu-item {
              display: flex;
              flex-direction: row;
              padding-right: 5px;
              padding-left: 5px;
          }
          .ant-menu-title-content {
              background-color: #fafafa;
              padding: 8px 16px;
              border-radius: 5px;
              a:hover {
                  color: $breeze-color-blue-2;
              }
          }
          .ant-menu-item-selected {
            color: #fff;
            .ant-menu-title-content {
              background-color: $breeze-color-blue-2;
              padding: 8px 16px;
              border-radius: 5px;
              
              a {
                color: $breeze-color-blue-2;
              }
            }
          }
        }     
    }

    .view-btn{
      margin-left: 5px;
      border-radius: 5px;
    }

    .pending{
        display: flex;
        height: 443px;
        margin: 15px 0px;
        flex-direction: column;
        position: relative;
        overflow: auto;

        .no-events-container {
            width: 100%;
            height: 442px;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            h4{
                font-size: 16px;
                margin-top: 20px;
                font-weight: 400;
            }
        }

        .loading-container{
          height: 442px;
        }

        .invitation{
            height: 64px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin: 10px 0px;

            .invite-image {
                width: 64px;
                height: 64px;
                border-radius: 4px;
                margin-right: 20px;
                background-color: #e2e2e2;
            }

            .start-group{
                width: 80%;
                height: 64px;
                display: flex;
                flex-direction: row;
    
                .text{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                            
                    h3{
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 20px;
                        margin-top: 10px;
                        margin-bottom: 0px;
                    }

                    p{
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 22px;
                        color: rgba($color: #000000, $alpha: 0.45);
                    }
                }
            }
        
            .end-group{
                width: 20%;
                height: 64px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: end;

                .invite-option{
                    width: 46px;
                    height: 40px;
                    margin-left: 10px;
                    border-radius: 4px;
                    color:rgba(0, 0, 0, 0.85);
                }
            }
            
          }

   
        .spin-overlay{
            z-index: 99;
            width: 100%;
            height: 100%;
            display: flex;
            border-radius: 4px;
            position: absolute;
            align-items: center;
            justify-content: center;
            background-color: rgba($color: #fafafa, $alpha: 0.45);
        }
    }
}
  
.dashboard-students-boards{
      padding: 24px;
      background: #ffffff;
      margin-bottom: 20px;

    .header{
        height: 34px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .month{
          font-weight: 500;
          color: rgba($color: #000000, $alpha: 0.45);
        }

        .needs-help{
          color: #FF4D4F;
        }
    }

    .students{
        display: flex;
        height: 443px;
        margin: 15px 0px;
        flex-direction: column;
        position: relative;
        overflow: auto;

        .no-events-container {
            width: 100%;
            height: 442px;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            h4{
                font-size: 16px;
                margin-top: 20px;
                font-weight: 400;
            }
        }

        .loading-container{
          height: 442px;
        }
    }

    .point-labels{
      right: 0px;
      width: 52%;
      text-align: end;
      display: flex;
      font-size: 14px;
      font-weight: 400;
      position: absolute;
      flex-direction: row;
      align-items: center;
      color:rgba(0, 0, 0, 0.45);
      justify-content: space-between;
    }

    .student{
      height: 64px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0px;

      .start-group{
        width: 55%;
        height: 64px;
        display: flex;
        flex-direction: row;

        .invite-image {
          width: 64px;
          height: 64px;
          font-size: 40px;
          border-radius: 50%;
          margin-right: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #e2e2e2;
          }

        .text{
          display: flex;
          flex-direction: column;
          justify-content: center;

            h3{
              font-size: 16px;
              font-weight: 500;
              line-height: 20px;
              margin-top: 10px;
              margin-bottom: 0px;
            }

            p{
              font-size: 14px;
              font-weight: 500;
              line-height: 22px;
              color: rgba($color: #000000, $alpha: 0.45);
            }
        }
      }
      .end-group{
        width: 45%;
        height: 64px;
        padding-left: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
  }
}

.dashboard-faculty{
  padding: 24px;
  background: #ffffff;
  margin-bottom: 20px;

.header{
    height: 34px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .menus {
      width: 50%;
      display: flex;
      justify-content: end;
      background-color: transparent;

      p{
          margin: 0px;
          font-size: 14px;
          line-height: 22px;
      }
  
      li::after {
        border-bottom: none !important;
      }
      li:hover {
        color: $breeze-color-blue-2;
      }
      .ant-menu-item {
          display: flex;
          flex-direction: row;
          padding-right: 5px;
          padding-left: 5px;
      }
      .ant-menu-title-content {
          background-color: #fafafa;
          padding: 8px 16px;
          border-radius: 5px;
          a:hover {
              color: $breeze-color-blue-2;
          }
      }
      .ant-menu-item-selected {
        color: #fff;
        .ant-menu-title-content {
          background-color: $breeze-color-blue-2;
          padding: 8px 16px;
          border-radius: 5px;
          
          a {
            color: $breeze-color-blue-2;
          }
        }
      }
    }     
}

.view-btn{
  margin-left: 5px;
  border-radius: 5px;
}

.point-labels{
  right: 0px;
  width: 70%;
  text-align: end;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  flex-direction: row;
  align-items: center;
  color:rgba(0, 0, 0, 0.45);
  justify-content: space-between;
}

.faculty-members{
  display: flex;
  height: 443px;
  margin: 15px 0px;
  flex-direction: column;
  position: relative;
  overflow: auto;

  .no-events-container {
      width: 100%;
      height: 442px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      h4{
          font-size: 16px;
          margin-top: 20px;
          font-weight: 400;
      }
  }

  .loading-container{
    height: 442px;
  }
}

.faculty{
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px;

  .start-group{
    width: 34%;
    height: 64px;
    display: flex;
    flex-direction: row;

    .invite-image {
      width: 64px;
      height: 64px;
      font-size: 40px;
      border-radius: 50%;
      margin-right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #e2e2e2;
      }

    .text{
      display: flex;
      flex-direction: column;
      justify-content: center;

        h3{
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          margin-top: 10px;
          margin-bottom: 0px;
        }

        p{
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          color: rgba($color: #000000, $alpha: 0.45);
        }
      }
    }
    .end-group{
      width: 66%;
      height: 64px;
      padding-left: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }


    .spin-overlay{
        z-index: 99;
        width: 100%;
        height: 100%;
        display: flex;
        border-radius: 4px;
        position: absolute;
        align-items: center;
        justify-content: center;
        background-color: rgba($color: #fafafa, $alpha: 0.45);
    }
}
}