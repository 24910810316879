// width max reference:
// mobile 430px
// tablet 820px
// tag app class for content specific changes
// * {
//   outline: 1px solid #f00 !important;
// }
// Disable Flutter Components

.empty-state.flutter {
  img {
    height: 320px;
    width: 100%;
    text-align: center;
  }

  h1 {
    font-size: 42px;
  }

  p {
    font-size: 24px;
  }

  button {
    height: 57px !important;
    width: 150px !important;

    span {
      font-size: 21px;
      font-weight: 400;
      line-height: 25px;
      letter-spacing: 0em;
      text-align: left;

    }
  }

  @media screen and (max-width: 430px) {
    img {
      height: 230px;
    }
    h1 {
      font-size: 24px;
    }

    p {
      font-size: 14px;
    }

    button {
      height: 54px !important;
      width: 115px !important;

      span {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
}

.menu-wrapper.app {
  display: none !important;
}

// Flutter Components
.app-member-layout.flutter {
  
  .mobile-header {
    display: flex;
  }

  .mobile-flyout-backdrop {
    .mobile-flyout {
      .menu-list::-webkit-scrollbar {
        display: none;
      } 
      .menu-list {
        padding-bottom: 12px;
        height: 100vh;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
      }
    }
  }

  .desktop-header {
    display: none;
  }

  .unavailable-qr {
    img {
      width: 100%;
      height: 320px;

      @media screen and (max-width: 430px) {
        height: 230px;
      }
    }
  }

  .empty-state.state-no-events {
    display: none;
  }
  .empty-state.state-no-events.app {
    display: flex;
    .text-content {
      width: 100%;
    }
  }

  .empty-state.state-no-result {
    flex-direction: column-reverse;
  }

  .coming-soon-state {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;

    h1 {
      text-align: center;
      font-size: 42px;
      font-weight: 500;
      line-height: 63px;
      letter-spacing: 0em;

      @media screen and (max-width: 430px) {
        font-size: 24px;
      }
    }
    p {
      font-size: 24px;
      font-weight: 400;
      line-height: 36px;
      letter-spacing: 0em;
      text-align: center;

      @media screen and (max-width: 430px) {
        font-size: 14px;
      }
    }

    img {
      width: 100%;
      height: 320px;


      @media screen and (max-width: 430px) {
        height: 230px;
      }
    }
    
    button {
      width: 178px;
      height: 57px;
      border-radius: 10px;

      @media screen and (max-width: 430px) {
        height: 54px;
        width: 134px !important;
      }

      span {
        font-size: 21px;
        font-weight: 400;
        letter-spacing: 0em;
        text-align: left;

        @media screen and (max-width: 430px) {
          font-size: 14px;
        }
      }
    }
  }
  
  .page-header-wrapper {
    transition: 0.1s;
    transform-origin: top center;
    

    .page-header-container {
      @media screen and (max-height: 1080px) {
        padding: 16px;
        .profile-header-details {
          h3 {
            font-size: 18px;
            line-height: 12px;

          }
          .more-detail {
            h5 {
              font-size: 12px;
              line-height: 12px;

            }
            img {
              width: 12px;
              height: 12px;
            }
          }
        }
        .profile-picture-div {
          width: 48px !important;
          height: 48px !important;

          .ant-avatar {
            width: 48px !important;
            height: 48px !important;

            .ant-avatar-string {
              line-height: 48px !important;
            }
          }

          .profile-avatar {
            width: 48px !important;
            height: 48px !important;
          }

          .btn-change-profile {
            width: 16px;
            height: 16px;
            img {
              width: 8px !important;
            }
          }
        }
      }


      .controls-container {
        align-self: flex-start;
      }
    }
  }
  
  // .empty-state {
  //   img {
  //     height: 320px;
  //     width: 100%;
  //     text-align: center;
  //   }
  
  //   h1 {
  //     font-size: 42px;
  //   }
  
  //   p {
  //     font-size: 24px;
  //   }
  
  //   button {
  //     height: 57px !important;
  //     width: 150px !important;
  
  //     span {
  //       font-size: 21px;
  //       font-weight: 400;
  //       line-height: 25px;
  //       letter-spacing: 0em;
  //       text-align: left;
  
  //     }
  //   }
  
  //   @media screen and (max-width: 430px) {
  //     img {
  //       height: 230px;
  //     }
  //     h1 {
  //       font-size: 24px;
  //     }
  
  //     p {
  //       font-size: 14px;
  //     }
  
  //     button {
  //       height: 54px !important;
  //       width: 115px !important;
  
  //       span {
  //         font-size: 14px;
  //         line-height: 22px;
  //       }
  //     }
  //   }
  // }
  
  .profile-page-single-content-wrapper {
    padding: 0 5%;

    .profile-page-single-content {
      .profile-summary {
        background: transparent;
        padding: 0;
      }
      .profile-summary > h3 {
        display: none;
      }
  
      .profile-club-interest {
        .col-club-interest {
          flex: 0 0 50%;

          @media screen and (max-width: 430px) {
            flex: 0 0 100%;
          }

          .interest-container {
            flex-wrap: wrap;
            gap: 12px;
            .interest-item {
              width: 45% !important;
              margin: 0 !important;
            }
    
            a {
              margin: 0 !important;
              width: 50% !important;
              .interest-item {
                margin: 0 !important;
                width: 100% !important;
              }
            }
          }
        }

        
      }
  
      .profile-softskill {

        .skill-attributes {
          .skill-chart {
            flex: 0 0 40%;

            @media screen and (max-width: 430px) {
              flex: 0 0 100%;
            }

            .doughnut-container {
              width: 100%;
              
              @media screen and (max-width: 430px) {
                width: 200px;
              }
              
              canvas {
                width: 100% !important;
                height: 100% !important;
              }
            }
          }

          .skill-detail {
            flex: 0 0 60%;

            @media screen and (max-width: 430px) {
              flex: 0 0 100%;
            }

            .doughnut-legend {
              .point-label {
                display: none;
              }
              ul {
                h5 {
                  font-weight: normal;
                }
                .legend-percentage {
                  font-weight: normal;
                }
              }
            }
          }
        }
      }

      .profile-ratings {
        .summary-details {
          .summary-details-item {
            flex: 0 0 25%;
            @media screen and (max-width: 430px) {
              flex: 0 0 50%;
            }
          }
        }
      }
  
      .profile-upcoming-event {
        .event-image {
          height: 124px !important;
        }
        .event-details {
          flex-direction: column;
          justify-content: start;
          gap: 12px;
          color: rgba(0, 0, 0, 0.45);
        }
      }

      .profile-club-joined-container {
        li {
          width: 50%;
          @media screen and (max-width: 430px) {
            width: 100%;
          }
        }
      }
    }
  }
  

  .col-calendar.app {
    display: block;
    flex: 0 0 50%;
    @media screen and (max-width: 430px) {
      display: none;
    }
  }
  
  .col-calendar {
    max-width: 100%;
    display: none;

    @media screen and (max-width: 430px) {
      flex: 0 0 100%;
      display: block;
    }

    .content-right-main.profile-calendar {
      height: initial;
  
      .profile-calendar-container {
        display: block !important;
        margin-top: 24px;
        position: unset;

        @media screen and (max-width: 430px) {
          width: 100% !important;
        }
      }
    }
  }

  .profile-club-joined {
    ul {
      display: flex;
      flex-wrap: wrap;
      li {

        width: 50%;
      }
    }
  }

  .dashboard-page-single-content {
    .dashboard-upcoming-events {
      .navigation {
        .navigation-unary {
          align-items: flex-start;
          gap: 16px;
          align-self: stretch;
          display: none;
    
          @media screen and (max-width: 430px) {
            display: flex;
          }
          
          .arrow {
            height: 40px;
            width: 46px;
            border-radius: 5px;
            border: 1px solid #D9D9D9;
            background: #FFF;
        
            box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
          }
        
          .label {
            width: 100%;
            border-radius: 4px;
            background: #02B8F6;
            padding: 8px 16px;
        
            p {
              font-size: 16px;
              text-align: center;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
              color: #FFF;
              margin: auto;
            }
          }
        }
    
        .navigation-menu {
          @media screen and (max-width: 430px) {
            display: none;
          }
        }
      }
    
      .events {
        justify-content: flex-start;
        
        @media screen and (max-width: 430px) {
          flex-wrap: wrap;
        }
    
        .event {
          padding: 0;
          width: 329px;
    
          @media screen and (max-width: 430px) {
            width: 100%;
          }
    
          .event-header {
            width: 329px;
    
            @media screen and (max-width: 430px) {
              width: 100%;
            }
    
            .dropdown {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 36px;
              height: 32px;
            }
          }
    
          .image-banner, .image-placeholder {
            height: 200px;
            width: 329px;
            
            @media screen and (max-width: 430px) {
              width: 100%;
            }
          }
    
          .event-information {
            margin: 16px 0;
    
            .event-title, .event-details {
              margin: 0;
            }
    
            .event-club {
              margin: 10px 0 0;
            }
          }
        }
      }
    }
  
    .section-club-event {
      .dashboard-top-clubs {
        .clubs {
          height: 425.5px;
          
          .point-label {
            position: relative;
            justify-content: right;
          }
          .club-top-content {
            height: 455.5px;
            overflow-y: scroll;
          }
        }
        .club-top-content {
          .club {
            .start-group {
              .text {
                width: calc(100% - 64px);
                padding: 0 12px;

                h3 {
                  text-wrap: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                
              }
            }
            .end-group {
              h3 {
                padding: 0 12px;
              }
            }
          }
        }
      }
  
      .dashboard-pending-invites {
        .header {
          display: flex;
          justify-content: space-between;
  
          .menu-wrapper {
            width: 160.7px;
          }
        }
  
        .pending {
          .invitation {
            .start-group {
              width: calc(100% - 96px);
              
              .invite-image-wrapper {
                .invite-image {
                  margin: 0;
                }
              }
              
              .text {
                padding: 0 12px;
                width: calc(100% - 64px);
  
                h3 {
                  height: 24px;
                  font-size: 16px;
                  text-wrap: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
  
                p {
                  height: 18px;
                  font-size: 14px;
                  text-wrap: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }
            .end-group {
              display: flex;
              justify-content: flex-end;
              width: 96px;
              gap: 12px;
  
              @media screen and (max-width: 430px) {
                button {
                  margin: 0;
                  padding: 0 0;
                  height: 32px;
                  width: 40%;
                }
              }
            }
          }
        }
  
        @media screen and (max-width: 430px) {
          .header {
            display: flex;
            flex-direction: column;
          }
        }
        
      }
    }
  
    .skill-progress-container {
      .skill-header {
        justify-content: space-between;
        flex-wrap: nowrap;
  
        @media screen and (max-width: 430px) {
          flex-direction: column-reverse;
        }
        .menu-wrapper {
          width: 320.64px;
          overflow: hidden;
        }
      }
      .skill-content {
        .skill-item {
          .skill-attributes {
            justify-content: center;
            .skill-chart {
              .doughnut-wrapper {
                display: flex;
                justify-content: center;
              }
    
              .doughnut-container {
                width: 50%;
                @media screen and (max-width: 430px) {
                  width: 100%;
                }
              }
              
            }
  
            .skill-detail {
  
            }
          }
        }
      }
    }
  
    .menu-wrapper {
      display: block;
      margin: 0;
    }
    
    .menu-wrapper.app {
      display: none;
    }

    .view-all {
      width: 100% !important;
    }
  
    @media screen and (max-width: 430px) {
      .view-all {
        height: 40px !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
      }
  
      .menu-wrapper {
        display: none !important;
        width: 100% !important;
      }
  
      .menu-wrapper.app {
        display: flex !important;
  
        .menus::after {
          content: unset;
        }
  
        .menus::before {
          content: unset;
        }
  
        .menus {
          padding: 0;
          width: 100%;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
  
          .ant-menu-item:hover {
            color: #FFF;
          }
  
          .ant-menu-item {
            margin: 0;
            padding: 0;
            width: 48%;
  
            .ant-menu-title-content {
              width: 100%;
              text-align: center;

              p {
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
    }
    
  }

  .ant-layout-footer {
    @media screen and (max-width: 430px) {
      padding-left: 5%;
      padding-right: 5%;
    }
    padding-left: 50px;
    padding-right: 50px;
    .footer-wrapper {
      width: 100%;
      .footer-links {
        display: block;
      }
    }
  }
  
}

