.component-container{
    background-color: #FFF;
    padding: 24px;
    height: auto;
    height: 550px;
    // max-height: 45vh;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .header{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 16px;
        justify-content: space-between;
        
        .title{
            position: relative;
            color: #595959;
            font-size: 16px;
            font-weight: 600;
            .sub-title{
                font-size: 12px;
                font-weight: lighter;
                margin-bottom: 0px;
            }
            .overview-tooltip{
                top:4px;
                left: 90px;
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 15px;
                width: 15px;
                font-size: 10px;
                color: #fff;
                border-radius: 50px;
                background-color: #8c8c8c;
            }
        }

        .filter{
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }
    .tab-container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 16px,
    }

    .divider{
        margin: 0px;
        margin-bottom: 16px;
    }

    .text-btn{
        color: #02B8F6;
        width: min-content;
    }

    .construction{
        position: absolute;
        height: calc(100% - 48px);
        width: 100%;
        max-height: 500px;
        max-width: 500px;
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto; 
        background-image: url('../../images/illustration/under-construction.svg');
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: end;
        padding-bottom: 30px;
        font-size: 18px;
        opacity: 0.7;
    }
    .construction-sm{
        position: absolute;
        height: calc(100% - 48px);
        width: 100%;
        max-height: 500px;
        max-width: 600px;
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto; 
        background-image: url('../../images/illustration/under-construction.svg');
        background-size: 40%;
        background-repeat: no-repeat;
        background-position:left;
        display: flex;
        flex-direction: row;
        justify-content: end;
        align-items: center;
        padding-bottom: 30px;
        font-size: 18px;
        opacity: 0.7;
    }
    .no-data-graph{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100% !important;
    }
}

.overview{
    .section{
        height: 124px;
        padding: 20px;
        border-radius: 4px;
        background-color: #F3F6F9;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .top{
            display: flex;
            flex-direction: row;
            .white-sq{
                width: 50px;
                height: 50px;
                border-radius: 4px;
                margin-right: 10px;
                background-color: #FFF;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 20px;
                color: #8c8c8c;
            }
            .data{
                height: 50px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                h1{
                    font-size: 20px;
                    color: #595959;
                    line-height: 24px;
                    margin-bottom: 0px;
                }
                p{
                    font-size: 11px;
                    color: #595959;
                    margin-bottom: 0px;
                }
            }
            
        }

        .bottom{
            font-size: 12px;
            color: #595959;
            .green{
                color: #169F5B !important;
                margin-bottom: 0px;
            }
            .red{
                color: #E74731 !important;
                margin-bottom: 0px;
            }
            .blue{
                color: #02B8F6 !important;
                margin-bottom: 0px;
            }
        }
        .spin-loading{
            position: absolute;
            right: 24px;
        }
    }
}

.overview-info{
    color: #8c8c8c !important;
    font-size: 12px;
    h3{
        font-size: 14px;
        margin-bottom: 0px;
    }
}

.date-filter{
    color: #000;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.date-picker{
    margin-left: 10px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    background-color: #F3F6F9;
}

.date-picker-dropdown{
    .ant-picker-week-panel-row-selected td{ 
        background-color: transparent !important;
        .ant-picker-cell-inner{
            background-color: #02B8F6 !important;
        }
    }
    .ant-picker-week-panel-row-selected .ant-picker-cell-week { //week number selected
        color: #000 !important;
        opacity: 0.3;
    }
    .ant-picker-week-panel-row:hover td{
        background-color: transparent !important;
        .ant-picker-cell-inner{
            color: #fff;
            background-color: #02B8F6 !important;
        }
    }
    .ant-picker-cell-selected .ant-picker-cell-inner {
        border-radius: 4px;
        background-color: #02B8F6 !important;
    }
    .ant-picker-cell:hover .ant-picker-cell-inner{
            color: #fff;
            border-radius: 4px;
            background-color: #02B8F6 !important;
    }
}

.filter-dropdown{
    max-height: 30px;
    min-width: 110px;
    margin-left: 10px;
    padding: 3px 10px;
    text-align: center;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    background-color: #F3F6F9;
    display: flex;
    align-items: center;
    justify-content: center;

    a{
        color: #595959 !important;
    }
}
.filter-dropdown:hover{
    border: 1px solid #02B8F6;
    a{
        color: #02B8F6 !important;
    }
}
.tab{
    margin-right: 5px;
    border-radius: 4px;
    border: 1px solid #F5F5F5;
    background-color: #FAFAFA;
}
.tab-selected{
    color: #FFF;
    margin-right: 5px;
    border-radius: 4px;
    background-color: #02B8F6;
}

.breakdown{
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
}
.data-container{
    height: 65px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
    padding: 10px 0px;
    justify-content: space-between;
    .grey-sq{
        padding: 10px;
        width: 50px;
        height: 50px;
        border-radius: 4px;
        margin-right: 10px;
        background-color: #F3F6F9;
    }
    .half{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 50%;
        .name{
            margin-bottom: 0px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .ant-progress{
        .ant-progress-inner{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            background-color: transparent;
        }
    }
}

.analytics-bottom{
    display: flex;
    height: 100%;
    flex-direction: row;
    @media (max-width: 991px) {
        flex-direction: column;
    }
    .options{
        height: 100%;
        width: 250px;
        display: flex;
        margin-right: 20px;
        flex-direction: column;
        justify-content: space-between;

        @media (max-width: 991px) {
            height: 100px;
            width: 100%;
            flex-direction: row;
            margin-right: 0px;
            margin-bottom: 20px;
        }

        .analytic-option{
            height: 115px;
            width: 250px;
            padding: 16px;
            border-radius: 4px;
            background-color: #F3F6F9;
            cursor: pointer;
            .top{
                display: flex;
                flex-direction: row;
                margin-bottom: 10px;
                .data{
                    height: 50px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    h1{
                    font-size: 14px;
                    color: #595959;
                    font-weight: 700; 
                    margin-bottom: 0px;
                    }
                    h4{
                        font-size: 12px;
                        color: #595959;
                        margin-bottom: 0px;
                    }
                    .last-wk{
                        font-size: 11px !important;
                        color: #595959;
                        margin-bottom: 0px;
                    }
                }
                .white-sq{
                    width: 50px;
                    height: 50px;
                    border-radius: 4px;
                    margin-right: 10px;
                    background-color: #FFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 20px;
                    color: #8c8c8c;
                }
                
            }
            @media (max-width: 991px) {
                height: 115px;
                width: 32%;
                flex-direction: row;
            }
        }
        .analytic-option-selected{
            height: 115px;
            width: 250px;
            padding: 16px;
            border-radius: 4px;
            background-color: #EBFAFF;
            cursor: pointer;
            .top{
                display: flex;
                flex-direction: row;
                margin-bottom: 10px;
                .data{
                    height: 50px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    h1{
                    font-size: 14px;
                    color: #595959;
                    font-weight: 700; 
                    margin-bottom: 0px;
                    }
                    h4{
                        font-size: 12px;
                        color: #595959;
                        margin-bottom: 0px;
                    }
                    .last-wk{
                        font-size: 10px;
                        color: #595959;
                        margin-bottom: 0px;
                    }
                }
                .white-sq{
                    width: 50px;
                    height: 50px;
                    border-radius: 4px;
                    margin-right: 10px;
                    background-color: #D3F4FF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 20px;
                    color: #02B8F6;
                }
                
            }
            @media (max-width: 991px) {
                height: 115px;
                width: 32%;
                flex-direction: row;
            }
        }
    }

    .analytics-data{
        height: 100%;
        width: 100%;
        @media (max-width: 991px) {
            margin-top: 10px;
        }

        .day-analytic{
            width: 100%;
            padding: 24px 18px;
            min-height: 187px;
            border-radius: 5px;
            background-color: #F0F0F0;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            overflow: auto;
            .main{
                display: flex;
                flex-direction: column;

                .title{
                    color: #8c8c8c;
                    font-weight: 600;
                    font-size: 15px;
                    margin-bottom: 0px;
                }
                .time{
                    color: #595959;
                    font-weight: 600;
                    font-size: 18px;
                    margin-bottom: 0px;
                }
                .previous{
                    font-size: 12px;
                    margin-bottom: 0px;
                    .blue{
                        color: #02B8F6;
                    }
                }
            }
            .medal-box{
                // position: absolute;
                // right: 20px;
                height: 50px;
                width: 50px !important;
                border-radius: 4px;
                background-color: #FFF;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

.event-container{
    height: calc(100% - 50px);
    min-width: 520px;
    overflow: hidden;
    .event-list{
        height: calc(100% - 24px);
        min-width: 520px;
        overflow: auto;
    }
    .table-label{
        cursor: pointer;
        color: #4f4f4f;
        font-size: 14px;
        font-weight: 700px;
        text-align: center;
        margin-bottom: 10px;
        .sort-arrow{
            margin-left: 5px;
            font-size: 10px;
        }
    }
}
.event-row{
    padding: 15px 0px;
    border-top: 1px solid #ECECEC;
    width: 100%;
    min-width: 520px;

    .main-data{
        display: flex;
        flex-direction: row;
        overflow: hidden;
        .img{
            min-width: 64px;
            height: 56px;
            border-radius: 4px;
            margin-right: 10px;
            background-color: #F5F5F5;
        }
        .texts{
            .title{
                max-width: 150px;
                color: #4f4f4f;
                font-size: 12px;
                font-weight: 500;
                margin-bottom: 0px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .location{
                max-width: 120px;
                color: #8c8c8c;
                font-size: 10px;
                margin-bottom: 0px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .upcoming{
                padding: 0px 10px;
                width: min-content;
                color: #02B8F6;
                font-size: 12px;
                margin-bottom: 0px;
                border-radius: 50px;
                background-color: #D3F4FF;
            }
            .past-event{
                padding: 0px 10px;
                width: min-content;
                color: #8c8c8c;
                font-size: 12px;
                margin-bottom: 0px;
                border-radius: 50px;
                background-color: #F5F5F5;
            }
        }
    }
    .other-data{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.popular-container{
    margin-top: 10px;
    height: calc(100% - 91px);
    overflow: hidden;
    .popular-list{
        height: calc(100% - 24px);
        padding-bottom: 24px;
        overflow: auto;
    }
    .table-label{
        cursor: pointer;
        color: #4f4f4f;
        font-size: 14px;
        font-weight: 700px;
        text-align: center;
        margin-bottom: 10px;
        .sort-arrow{
            margin-left: 5px;
            font-size: 10px;
        }
    }
    .table-label2{
        color: #4f4f4f;
        font-size: 14px;
        font-weight: 700px;
        text-align: center;
        margin-bottom: 10px;
    }
}
.info-row{
    padding: 15px 0px;
    border-top: 1px solid #ECECEC;
    .main-data{
        display: flex;
        flex-direction: row;
        align-items: center;
        .img{
            padding: 8px;
            min-width: 55px;
            height: 45px;
            border-radius: 4px;
            margin-right: 10px;
            background-color: #F5F5F5;
        }
        .name{
            width: 300px;
            color: #4f4f4f;
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 0px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .other-data{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ant-progress{
        .ant-progress-inner{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            background-color: transparent;
        }
    }
}
.blue-text{
    color: #02B8F6 !important;
}

.analytics-tooltip{
    width: 250px;
    padding: 10px;
    border-radius: 5px;
    background-color: #FFF;
    border: 1px solid #d9d9d9;
    .title{
        color: #4f4f4f;
        font-weight: 700;
        margin-bottom: 5px;
    }
    .total{
        display: flex;
        font-weight: 500;
        color: #02B8F6;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 5px;
        border-bottom: 1px dashed #d9d9d9;
    }
    .point-info{
        display: flex;
        padding: 10px 0px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px dashed #d9d9d9;
        .left{
            display: flex;
            flex-direction: column;
            align-items: left;
            margin-right: 10px;
            .label{
                display: flex;
                flex-direction: row;
                align-items: center;
                .point{
                    width: 10px;
                    height: 10px;
                    border-radius: 50px;
                    margin-right: 10px;
                }
            }
            .description{
                font-size: 12px;
                color: #8c8c8c;
                margin-bottom: 0px;
            }
        }
        .point-total{
            font-size: 24px;
            margin-bottom: 0px;
        }
    }
    .summary-info{
        display: flex;
        padding: 0px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .left{
            display: flex;
            flex-direction: column;
            align-items: left;
            margin-right: 10px;
            .label{
                display: flex;
                flex-direction: row;
                align-items: center;
                color: #000;
                .point{
                    width: 10px;
                    height: 10px;
                    border-radius: 50px;
                    margin-right: 10px;
                }
            }
        }
        .point-total{
            font-size: 14px;
            margin-bottom: 0px;
        }
    }
}

.interest-tooltip{
    width: 250px;
    padding: 10px;
    border-radius: 5px;
    background-color: #FFF;
    border: 1px solid #d9d9d9;
    .title{
        color: #4f4f4f;
        font-weight: 700;
        margin-bottom: 5px;
    }
    .total{
        display: flex;
        font-weight: 500;
        color: #02B8F6;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 5px;
        border-bottom: 1px dashed #d9d9d9;
    }
    .point-info{
        display: flex;
        padding: 2px 0px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .left{
            display: flex;
            flex-direction: column;
            align-items: left;
            margin-right: 10px;
            .label{
                display: flex;
                flex-direction: row;
                align-items: center;
                .point{
                    width: 10px;
                    height: 10px;
                    border-radius: 50px;
                    margin-right: 10px;
                }
            }
            .description{
                font-size: 12px;
                color: #8c8c8c;
                margin-bottom: 0px;
            }
        }
        .point-total{
            margin-bottom: 0px;
        }
    }
}

.time-section-tooltip{
    .ant-tooltip-inner{
        padding: 0px !important;
        width: 100% !important;
    }
    .ant-tooltip-arrow{
        display: none;
    }

}