$wrapper-max-width: 1400px;
$margin-sides-space: 0 15px;

.app-member-layout {
  @import "layout.scss";
  min-height: 100%;
  background: #f0f2f5;

  //if footer not sticky
  display: flex;
  flex-direction: column;
}

.ant-layout-footer {
  // margin-top: 30px;
}

@import "notification-widget.scss";
@import "notification.scss";
@import "groups.scss";
@import "event.scss";
@import "club-member.scss";
@import "onboarding.scss";
@import "profile.scss";
@import "dashboard.scss";
@import "calendar-page.scss";
@import "not-found.scss";
@import "./modal/modal-action-success.scss";
@import "./modal/modal-invite-member.scss";
@import "./modal/modal-user-agreement.scss";
@import "./modal/modal-streak.scss";
@import "./modal/modal-promotions.scss";
@import "./modal/modal-join-breeze.scss";
@import "flutter.scss";
@import "contact.scss";
