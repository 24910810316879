.detailed-footer{
    z-index: 10 !important;
    height: 50px;
    background-color: #0c1A36!important;
    padding-left: 15% !important;
    padding-right: 15% !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #FFF;

    @media (max-width: 1450px) {
      padding: 25px 5% !important;
    }

    .logo-custom {
        float: left;
        width: 120px;
        height: 25px;
        margin: 16px 24px 16px 0;
        // background: rgba(255, 255, 255, 0.2);
        object-fit: fill;
    }

    .privacy-policy{
        color: #BFBFBF;
        margin-bottom: 0px;
    }


}