.softskill-distribution-widget {
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }
  .softskill-distribution-container {
    background-color: #fafafa;
    padding: 16px;
    border-radius: 10px;
    .softskill-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      .ant-input-number {
        width: 80px;
      }
    }
    .softskill-distribution-footer {
      border-top: 1px solid rgba(0, 0, 0, 0.03);
      display: flex;
      justify-content: end;

      .total-text {
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 0;
      }
    }
  }
}
