.profile-pic-wrapper {
  .profile-picture-container {
    height: 79px;
    width: 79px;
    position: relative;
    border-radius: 50%;
    z-index: 1;
    .profile-picture {
      img {
        border-radius: 50%;
        object-fit: contain;
      }
    }

    .change-photo-container {
      cursor: pointer;
      opacity: 1;
      height: 79px;
      width: 79px;
      position: absolute;
      top: 0;
      border-radius: 50%;
      overflow: hidden;

      .change-photo {
        opacity: 0;
        background-color: black;
        height: 35px;
        position: absolute;
        bottom: 0;
        width: 100%;
        transition: all 0.5s ease;

        .anticon {
          color: white;
          margin-left: 28px;
          margin-top: 5px;
          font-size: 20px;
        }
      }
    }
    .change-photo-container:hover {
      .change-photo {
        opacity: 0.6;
        transition: all 0.5s ease;
      }
    }
  }
}

.profile-details {
  margin-left: 20px;

  .profile-infos {
    color: $breeze-light-gray;

    div {
      margin-right: 20px;
      p {
        margin-left: 5px;
        display: inline-block;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
      }
    }

    .icon-custom {
      position: relative;
      left: 4px;
      top: -1px;
    }
  }

  .stats-container {
    margin-top: 20px;
    display: flex;

    .stat {
      width: 150px;
      border: 0.5px solid #d9d9d9;
      border-radius: 2px;
      padding: 6px 12px 6px 12px;
      margin-right: 10px;

      h3 {
        margin: 0;
        font-weight: 700;
        font-size: 16px;
        img {
          margin-top: -4px;
        }
      }
      p {
        margin: 0;
        font-weight: 400;
        font-size: 12px;
        color: $breeze-light-gray;
      }
    }
  }
}
.profile-edit {
  .not-edit-mode {
    input {
      border: none;
    }
    input:focus {
      outline: 0;
      box-shadow: none;
    }
  }
}

.modal-change-profile-pic {
  .ant-modal-header {
    border-bottom: 0;
    padding-bottom: 12px;
  }
  .ant-modal-body {
    padding: 0 24px 0 24px;
  }
  .ant-modal-footer {
    display: none;
  }
}
