.club-links-page{
    display: flex;
    padding: 24px;
    margin: 24px;
    align-items: center;
    flex-direction: column;
    background-color: #fff;

    .header{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        @media (max-width: 992px) {
            flex-direction: column;
        }
        .search-bar{
            max-width: 50%;
            @media (max-width: 992px) {
                max-width: 100%;
            }
            .ant-input{
                height: 32px;
            }
        }
    }

    .table-container{
        width: 100%;
        padding: 1px;
        margin-top: 16px;
        background-color: #FFF;

        .ant-table-thead{
            .ant-table-cell{
                min-width: 150px;
                background-color: #D9D9D9;
            }
        }
        .ant-table-tbody{
            .ant-table-row{
                .ant-table-cell{
                    padding: 10px 16px;
                    p{
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
}

