.modal-streak{
    width: 259px !important;    

    .ant-modal-content {
        height: 259px;    
        width: 259px;    
        padding: 24px;
        border-radius: 10px;
    }
    .ant-modal-body {
        height: 100%;
        padding: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        img{
            height: 80px;
            margin-bottom:10px;
        }
        p{
            color: #8c8c8c;
            text-align: center;
            margin-bottom:10px;
        }
        h4{
            font-size: 24px;
            font-weight: 700;
            text-align: center;
            margin-bottom:0px;
        }
        .orange{
            color:#f35d38;
        }
        .blue{
            color:#0249AC;
        }
    }
    .ant-modal-header {
        display: none !important;
    }
    .ant-modal-footer {
        display: none !important;
    }
}