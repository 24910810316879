@import "../../..//assets/scss/colors.scss";
.upload-label::before {
  content: "*";
  color: red;
  position: relative;
  top: 2px;
  padding-right: 3px;
  font-size: 14px;
}
.upload-container {
  display: flex;
}
.note-container {
  margin-top: 5px;

  p {
    display: inline;
    font-style: italic;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
  }
}

.sample-file-template {
  margin-top: 8px;
  border: 1px dashed #d9d9d9;
  display: inline-block;
  padding: 0 8px;
  div {
    display: inline-block;
  }
  a {
    color: black;
    color: rgba(0, 0, 0, 0.85);
  }
  a:hover {
    color: $breeze-color-blue-1;
  }
}

.button-container {
  display: flex;
  margin-top: 20px;
  // button {
  //   padding: 8px 16px 8px 16px;
  //   font-size: 16px;
  //   height: 40px;
  //   line-height: 16px;
  // }
}

.breeze-form-1 {
  .text-head {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  .text-desc {
    font-size: 12px;
    line-height: 20px;
  }
  input {
    height: 40px;
  }

  small {
    font-weight: 400;
    font-size: 14px;
    color: $breeze-light-gray;
  }

  min-height: 40px !important;

  .ant-select-multiple {
    .ant-select-selector {
      min-height: 40px !important;
    }
  }
  .ant-select-single {
    .ant-select-selector {
      min-height: 40px;
      height: 40px;
      input {
        min-height: 40px;
        height: 40px;
      }
    }
    .ant-select-selection-placeholder {
      line-height: 40px;
    }
    .ant-select-selection-item {
      line-height: 40px;
    }
  }

  .ant-picker {
    height: 40px !important;
    width: 100%;
  }

  .select-search {
    position: absolute;
    right: 40px;
    padding-top: 10px;
    padding-left: 10px;
  }

  .form-ul {
    padding: 0;
    li {
      list-style: none;
      line-height: 24px;
      padding: 8px 12px 8px 12px;
      position: relative;

      span {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 6px;
      }
    }
  }
}

.table-controls {
  select {
    border: none;
    outline: none;
  }
  select:focus-visible {
    border: none;
    outline: none;
  }

  .btn-w-icon {
    img {
      margin-top: -2px;
    }
  }
}

.sidebar-container {
  height: 100vh;
  width: 100%;
}
