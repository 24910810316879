.modal-action-success {
  h4 {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    margin-top: 15px;
  }
  p {
    font-size: 16px;
    line-height: 24px;
  }
  .ant-modal-footer {
    display: none;
  }
  button {
    display: none;
  }
}
