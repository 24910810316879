@import "../colors.scss";

section.event-wizard {
  background: unset;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 600.98px) {
      padding: 0 !important;
    }
  }

  main {
    margin-top: unset;
    background: unset;
    position: absolute;
    top: 64px;
    left: 0;
    right: 0;
    bottom: 80px;
    overflow-y: auto;


  }

  footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 24px 0;
    background: #fff;
    box-shadow: inset 0px 1px 0px #f0f0f0;

    button {
      margin: auto;
      display: block;
    }
  }
}

.point-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
  gap: 10px;
  .point-entry {
    display: flex;
    flex-flow: column;

    .point-row {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      @media screen and (max-width: 430px) {
        flex-direction: column;
      }

      .point-col {
        @media screen and (max-width: 430px) {
          width: 100% !important;

          .ant-form-item {
            width: calc(100% - 22px - 17px) !important;
          }

        }
        .input-group {
          display: flex;
          align-items: center;
          gap: 8px;

          justify-content: start;

          .point-icon {
            display: flex;
            justify-content: center;

            @media screen and (max-width: 430px) {
              width: 24px;
            }
          }
        }
      }

      .point-selection {
        width: calc(100% - 122px - 17px);
        max-width: 400px;
        
        .ant-form-item {
          margin: 0;
          width: 100%;

          .ant-col {
            max-width: unset;
          }
        }
      }

      .point-percentage {
        @media screen and (max-width: 430px) {
          .ant-input-number {
            width: 100% !important;
          }
        }
      }
    }

  }
}

.wizard-page-container {
  max-width: 608px;
  margin: auto;
  padding: 0 12px;
  display: block !important;

  h2 {
    margin-bottom: 48px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 40px;
    color: rgba(0, 0, 0, 0.85);
  }

  .ant-form-item-label label,
  .form-label {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
  }

  .calendar-container {
    border: 1px solid #f0f0f0;
  }

  .select-item-row {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .ant-radio-button-wrapper:not(:first-child)::before {
      background-color: unset;
    }

    label {
      height: 44px;
      line-height: 42px;
      background-color: #fafafa;
      border-color: #fafafa;

      &.ant-radio-button-wrapper-checked {
        background-color: $breeze-color-main !important;
        border-color: $breeze-color-main;
      }
    }
  }

  .select-time-options {
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    width: 100%;

    .react-horizontal-scrolling-menu--scroll-container {
      gap: 6px;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .ant-radio-button-wrapper:not(:first-child)::before {
      background-color: unset;
    }

    label {
      border-radius: 5px;
      border-left-width: 1px;
      white-space: nowrap;
    }
  }

  .select-duration-option {
    display: flex;
    gap: 8px;

    .ant-radio-button-wrapper:not(:first-child)::before {
      background-color: unset;
    }

    label {
      flex-grow: 1;
      text-align: center;
      border-radius: 5px;
      border-left-width: 1px;
    }
  }

  .form-item-small {
    .ant-form-item-label {
      padding: 0;

      > label {
        height: unset;
      }
    }
  }

  .sel-interest {
    background: #fafafa;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 10px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.85);
    padding: 16px;
    margin-bottom: 24px;
  }

  .ant-select-selection-overflow {
    div {
      display: none;
    }

    :last-child {
      display: block;
    }
  }

  .form-softskill {
    background: #fafafa;
    border-radius: 10px;
    padding: 16px;
    margin: 16px 0 0;
    gap: 16px;

    .ant-row {

    }

    .ant-form-item-label {
      text-align: left;
    }

    .ant-form-item-control-input-content {
      text-align: right;
    }

    .ant-select-selector {
      text-align: left;
    }

    .div-total {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: right;
      color: rgba(0, 0, 0, 0.85);
      border-top: 1px solid rgba(0, 0, 0, 0.03);
      padding-top: 16px;
    }
  }
}