.notif-pop {
  position: relative;
  list-style: none;

  .overlay-button {
    // border: 1px solid black;
    visibility: hidden;
    transition: visibility 0s, opacity 0.1s linear;
    opacity: 0;
    .overlay-item {
      height: 32px;
      width: 32px;
      position: absolute;
      right: 10px;
      top: 15px;
      border-radius: 50%;
      background-color: white;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
      cursor: pointer;
      span {
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
      }
    }
  }
  .notif-main {
    display: flex;
    // justify-content: space-between;
    // padding: 16px;

    .no-avatar {
      background-color: #eb2f96;
      border-radius: 50%;
      height: 42px;
      width: 42px;
      position: relative;
      span {
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
        color: white;
        font-size: 24px;
      }
    }

    .error-avatar {
      background-color: red;
      border-radius: 50%;
      height: 42px;
      width: 42px;
      position: relative;
      span {
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
        color: white;
        font-size: 24px;
      }
    }

    .success-avatar {
      background-color: green;
      border-radius: 50%;
      height: 42px;
      width: 42px;
      position: relative;
      span {
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
        color: white;
        font-size: 24px;
      }
    }

    .user-avatar {
      border-radius: 50%;
      height: 42px;
      width: 42px;
      img {
        border-radius: 50%;
        object-fit: contain;
      }
    }

    .notif-content {
      padding-left: 10px;
      padding-right: 10px;
      h4 {
        margin: 0;
        font-weight: 500;
        font-size: 18px;
      }
      p {
        margin: 0;
        margin-bottom: 6px;
      }

      small {
        font-weight: 400;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.45);
      }
      .notif-invite-status{
        .red{
          color:#f00;
        }
      }
    }
    .notif-status {
      height: 12px;
      width: 12px;
      border-radius: 50%;
      background-color: $breeze-color-primary;
    }
    .notif-controls {
      margin-top: 12px;
      display: flex;
      // justify-content: center;
      flex-wrap: no-wrap;
      button {
        width: 100%;
      }
    }

    .notif-form {
      p {
        font-weight: bold;
        margin: 0;
        margin-bottom: 10px;
      }
      margin-top: 20px;
    }
  }
}
.notif-pop:hover {
  .overlay-button {
    visibility: visible;
    opacity: 1;
  }
}
