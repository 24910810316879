.public-event-page{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../images/public-event-bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;

    .event-container{
        width: 624px;
        height: 720px;
        padding: 12px;
        border-radius: 8px;
        position: relative;
        background-color: #FFF;
        
        @media (max-width: 992px) {
            height: 100%;
            width: 100%;
            overflow-y: auto;
            min-width: 275px;
        }

        .skeletons{
          height: 100%;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: space-evenly;
        }

        .close-btn{
          top:12px;
          right:12px;
          position: absolute;
        }
    
        .main-data{
            display: flex;
            align-items: center;
            flex-direction: row;
            margin-bottom: 10px;
            .img-placeholder{
            width: 70px;
            height: 70px;
            font-size: 50px;
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50px;
            color: #d9d9d9;
            border: 1px solid #d9d9d9;
            }
            .data{
            width: calc(100% - 90px);
            display: flex;
            flex-direction: column;
            .title{
                color: #595959;
                margin-bottom: 0px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .organizer{
                color: #595959;
                margin-bottom: 0px;
            }
            .interest{
                color: #fff;
                padding: 3px 10px;
                margin-bottom: 0px;
                width: max-content;
                border-radius: 50px;
                background-color: #02B8F6;
            }
            }
        }
        .image-container{
            position: relative;
            .image-banner {
            width: 100%;
            height: 250px;
            background-size: cover;
            border-radius: 10px;
            }
            
            .image-placeholder {
            position: relative;
            width: 100%;
            height: 300px;
            background-image: url("../../images/breeze-default-card-banner.png");
            background-size: cover;
            border-radius: 10px;
            @media (max-width: 992px) {
                width: 100%;
                height: auto;
                min-height: calc(50vw);
                // background-size: contain;
                background-repeat: no-repeat;
            }
            }
    
            .img-btns{
            width: 94px;
            display: flex;
            position: absolute;
            flex-direction: row;
            justify-content: space-between;
            top:12px;
            right: 12px;
            .circle-btn{
                width: 42px;
                height: 42px;
                border-radius: 50px;
                border: 1px solid #EBFAFF;
                background-color: #EBFAFF;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            }
        }

        .content{
            padding-top: 10px;
            display: flex;
            flex-direction: column;
      
            @media (max-width: 992px) {
              height: max-content;
              position: relative;
              padding-bottom: 50px;
            }
      
            .prompt{
              margin-top: 20px;
              margin-bottom: 15px;
            }
            .prompt-b{
              color: #02B8F6;
              margin-top: 10px;
              margin-bottom: 0px;
            }
      
            .info-point{
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-bottom: 10px;
              b{
                text-wrap: wrap;
              }
              p{
                margin-bottom: 0px;
              }
              .text-group{
                margin-bottom: 0px;
              }
            }
      
            .description{
              min-height: 70px;
              max-height: 150px;
              // white-space: nowrap;
              overflow: auto;
              // text-overflow: ellipsis;
              margin-bottom: 10px !important;
              @media (max-width: 992px) {
                max-height: calc(30vh);
              }
              @media (max-width: 500px) {
                max-height: calc(50vh);
              }
            }
      
            .grey-text{
              text-wrap:wrap;
              font-size: 14px;
              color: #8C8C8C;
              margin-bottom: 0px;
            }
      
            .names{
              font-size: small;
              margin-left: 10px;
              margin-bottom: 0px;
            }
      
            .tag-date{
              display: flex;
              flex-direction: row;
              justify-content: space-between;
            }
        }
        .btn-group{
            display: flex;
            flex-direction: row;
            position: absolute;
            bottom:12px;
            @media (max-width: 992px) {
                position: relative;
            }
            @media (max-width: 500px) {
                position: absolute;
                bottom:12px;
            }
        
          .added-btn{
            color: #02B8F6;
            background-color: #EBFAFF !important;
            border: 1px solid #EBFAFF;
            border-radius: 5px !important;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            @media (max-width: 500px) {
              width: 47vw;
            }
            .loading-spin{
              height: 100%;
              color: inherit;
              margin-left: 5px;
            }
          }
          .add-btn{
            color: #FFF;
            background-color: #02B8F6 !important;
            border: 1px solid #02B8F6;
            border-radius: 5px !important;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            @media (max-width: 500px) {
              width: 47vw;
            }
            .loading-spin{
              height: 100%;
              color: inherit;
              margin-left: 5px;
            }
          }
          .attendance-btn{
            border-radius: 5px !important;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            @media (max-width: 500px) {
              width: 46vw;
            }
            .loading-spin{
              height: 100%;
              color: inherit;
              margin-left: 5px;
            }
          }
        }
    }
}