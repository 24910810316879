.softskill-info {
  position: relative;
  left: 15px;
  top: 10px;
}
.close-icon {
  margin-top: 7px;
}
.normal-size {
  margin-top: 3px;
  input {
    height: 32px;
  }
}

.ant-slider-handle {
  border: solid 2px $breeze-color-blue-1;
}
