.modal-notify {
  width: 364px !important;

  .ant-modal-content {
    border-radius: 8px;
  }
  .ant-modal-body {
    padding: 20px 23px 2px 23px;
  }

  .mod-title {
    font-size: 16px;
    font-weight: 600;
  }

  .ant-modal-close-x {
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: 14px;
  }

  .modal-message {
    font-size: 14px;
  }

  .ant-modal-footer {
    border: 0;
    padding-top: 0;
    padding-bottom: 15px;
  }
  .modal-ul {
    padding-left: 20px;
  }
}
.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.25);
}


.modal-event-item {
  width: 624px !important;
  height: 700px !important;
  @media (max-width: 992px) {
    height: 100% !important;
    width: 100% !important;
  }
  @media (max-width: 992px) {
    height: auto !important;
    overflow: hidden;
  }
  .ant-modal-content {
    border-radius: 10px;
    @media (max-width: 992px) {
      height: 98vh !important;
      position: relative;
    }
  }
  .ant-modal-body {
    display: flex;
    justify-content: center;
    background-color: #FFF !important;
    overflow-x: auto;
    height: 700px !important;
    padding: 12px;
    @media (max-width: 992px) {
      width: 100% !important;
      height: 90% !important;
      // max-height: min-content!important;
      margin-bottom: 60px;
    }
  }
  .ant-modal-header {
    height: 50px!important;
    background-color: #FFF;
    border-radius:8px 8px 0px 0px;
    padding-left: 12px;
    .ant-modal-title{
      font-size: 14px;
    }
    @media (max-width: 992px) {
      border: none;
    }
  }
  
  .mod-title {
    font-size: 16px;
    font-weight: 600;
  }
  
  .ant-modal-close-x {
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: 14px;
  }
  
  .modal-message {
    font-size: 14px;
  }
  
  .ant-modal-footer {
    // border: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #FFF !important;
    border-radius:0px 0px 8px 8px;
    display: flex;
    justify-content: flex-start;
    border: none;
    @media (max-width: 992px) {
      display: none;
    }
    @media (max-width: 500px) {
      bottom: 0px;
      width: 100%;
      height: 60px;
      position: absolute;
      display: flex;
      justify-content: center;
    }

    .attendance-btn{
      border-radius: 5px !important;
      @media (max-width: 500px) {
        width: 100%;
      }
    }
    .added-btn{
      color: #02B8F6;
      background-color: #EBFAFF !important;
      border: 1px solid #EBFAFF;
      border-radius: 5px !important;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      @media (max-width: 500px) {
        width: 100%;
      }
    }
  }
  .modal-ul {
    padding-left: 20px;
  }
  .added-btn{
    color: #02B8F6;
    background-color: #EBFAFF !important;
    border: 1px solid #EBFAFF;
    border-radius: 5px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    .loading-spin{
      height: 100%;
      color: inherit;
      margin-left: 5px;
    }
  }
  .add-btn{
    color: #FFF;
    background-color: #02B8F6 !important;
    border: 1px solid #02B8F6;
    border-radius: 5px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    .loading-spin{
      height: 100%;
      color: inherit;
      margin-left: 5px;
    }
  }

  .event-view{
    position: relative;
    display: inline-block;
    width: 100%;
    height: max-content;
    word-wrap: break-word;

    @media (max-width: 992px) {
      height: 100%;
      width: 100%;
      border-radius: 10px 10px 0px 0px;
      overflow-y: auto;
    }
    .promoted{
      top:60px;
      right: 10px;
      height: 20px;
      width: 85px;
      display: flex;
      position: absolute;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0px 0px 0px 8px;
      border-radius: 50px;
      box-shadow: 0px 2px 8px 0px #00000026;
      background-color: #fff;
      color: #FFA940;
      font-size: 12px;

      img{
        width: 20px;
        height: 20px;
        padding: 2px;
        margin-right: 0px;
        border-radius: 50px;
        background-color: #FFA940;
      }
    }
    .highlighted{
      top:60px;
      right: 10px;
      height: 20px;
      width: 20px;
      display: flex;
      position: absolute;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0px;
      border-radius: 50px;
      box-shadow: 0px 2px 8px 0px #00000026;
      background-color: #fff;
      color: #02B8F6;
      font-size: 12px;
      p{
        display: none;
      }
      img{
        width: 20px;
        height: 20px;
        padding: 2px;
        margin-right: 0px;
        border-radius: 50px;
        background-color: #EBFAFF;
      }
    }
    .highlighted:hover{
      top:60px;
      right: 10px;
      height: 20px;
      width: 90px;
      display: flex;
      position: absolute;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0px 0px 0px 8px;
      border-radius: 50px;
      box-shadow: 0px 2px 8px 0px #00000026;
      background-color: #fff;
      color: #02B8F6;
      font-size: 12px;
      p{
        display: flex;
        margin-bottom: 0px;
      }
      img{
        width: 20px;
        height: 20px;
        padding: 2px;
        margin-right: 0px;
        border-radius: 50px;
        background-color: #EBFAFF;
      }
    }
    .main-data{
      display: flex;
      align-items: center;
      flex-direction: row;
      margin-bottom: 10px;
      
      .img-placeholder{
        width: 70px;
        height: 70px;
        font-size: 50px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        color: #d9d9d9;
        border: 1px solid #d9d9d9;
      }
      .data{
        width: calc(100% - 90px);
        display: flex;
        flex-direction: column;
        .title{
          color: #595959;
          margin-bottom: 0px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .organizer{
          color: #595959;
          margin-bottom: 0px;
        }
        .interest{
          color: #fff;
          padding: 3px 10px;
          margin-bottom: 0px;
          width: max-content;
          border-radius: 50px;
          background-color: #02B8F6;
        }
      }
    }
    .image-container{
      position: relative;
      .image-banner {
        width: 100%;
        height: 250px;
        background-size: cover;
        border-radius: 10px;
      }
      
      .image-placeholder {
        position: relative;
        width: 100%;
        height: 300px;
        background-image: url("../images/breeze-default-card-banner.png");
        background-size: cover;
        border-radius: 10px;
        @media (max-width: 992px) {
          width: 100%;
          height: auto;
          min-height: calc(50vw);
          // background-size: contain;
          background-repeat: no-repeat;
        }
      }

      .img-btns{
        width: 94px;
        display: flex;
        position: absolute;
        flex-direction: row;
        justify-content: space-between;
        top:12px;
        right: 12px;
        .circle-btn{
          width: 42px;
          height: 42px;
          border-radius: 50px;
          border: 1px solid #EBFAFF;
          background-color: #EBFAFF;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .primetime-label{
      color:#FFF;
      top: 80px;
      right: 40px;
      padding: 4px 12px;
      position: absolute;
      border-radius: 50px;
      background-color: #FAAD14;
    }
   
    .content{
      padding-top: 10px;
      display: flex;
      flex-direction: column;

      @media (max-width: 992px) {
        height: max-content;
        position: relative;
        padding-bottom: 50px;
      }

      .prompt{
        margin-top: 20px;
        margin-bottom: 15px;
      }
      .prompt-b{
        color: #02B8F6;
        margin-top: 10px;
        margin-bottom: 0px;
      }

      .info-point{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;
        b{
          text-wrap: wrap;
        }
        p{
          margin-bottom: 0px;
        }
        .text-group{
          margin-bottom: 0px;
        }
      }

      .description{
        min-height: 70px;
        max-height: 200px;
        // white-space: nowrap;
        overflow: auto;
        // text-overflow: ellipsis;
        margin-bottom: 10px !important;
      }
      .btn-group{
        display: none;
        @media (max-width: 992px) and (min-width:501px) {
          display: flex;
          flex-direction: row;
        }
        .added-btn{
          color: #02B8F6;
          background-color: #EBFAFF !important;
          border: 1px solid #EBFAFF;
          border-radius: 5px !important;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
        }
        .add-btn{
          color: #FFF;
          background-color: #02B8F6 !important;
          border: 1px solid #02B8F6;
          border-radius: 5px !important;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
        }
      }

      .grey-text{
        text-wrap:wrap;
        font-size: 14px;
        color: #8C8C8C;
        margin-bottom: 0px;
      }

      .names{
        font-size: small;
        margin-left: 10px;
        margin-bottom: 0px;
      }

      .tag-date{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .tag-area{
        width: 50%;
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
        max-height: 60px;
        overflow-y: auto;

        .tag{
          height: 24px;
          color: #fff;
          padding: 0px 8px;
          margin-right: 8px;
          position: relative;
          border-radius: 4px;
          background-color: #389E0D;
        }
      }
    }
  }
}

.modal-event-list {
  width: 1000px !important;
  
  .ant-modal-content {
    @media (max-width: 992px) {
      height: 98vh;
      overflow: hidden;
    }
    background-color: #F3F6F9;
    border-radius: 8px;
  }
  .ant-modal-body {
    padding: 20px 23px 2px 23px;
    @media (max-width: 992px) {
      height: 100%;
      padding: 20px 0px 2px 0px;
      overflow: auto;
      padding-bottom: 70px;
    }
  }
  .ant-modal-header {
    background-color: #F3F6F9;
    border-radius:8px 8px 0px 0px;
  }

  .mod-title {
    font-size: 16px;
    font-weight: 600;
  }

  .ant-modal-close-x {
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: 14px;
  }

  .modal-message {
    font-size: 14px;
  }

  .ant-modal-footer {
    // border: 0;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .modal-ul {
    padding-left: 20px;
  }
}

.modal-attending-contacts {
  width: 500px !important;
  height: 700px !important;
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-header {
    height: 50px!important;
    background-color: #F3F6F9;
    border-radius:8px 8px 0px 0px;
  }
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    border-radius:8px 8px 0px 0px;
    background-color: #F3F6F9 !important;
    overflow-x: auto;
    height: 700px !important;

    Button{
      width: max-content;
      margin-bottom: 20px;
    }
    
    .contact-list{
      height: 100%;
      display: flex;
      flex-direction: column;
      // background-color: #02B8F6;
      overflow-y: auto;

      .contact{
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 8px;
        padding: 8px 24px;
        background-color: #FFF;
        margin-bottom: 10px;

        .text-group{
          margin-left: 20px;
          h3{
            margin-bottom: 0px;
          }
          .mutuals{
            display: flex;
            flex-direction: row;
            p{
              color: #8c8c8c;
              margin-left: 10px;
              margin-bottom: 0px;
            }
          }
        }
        
      }
    }
  }
}

.modal-import-contacts {
  width: 500px !important;
  // height: 850px !important;

  .search{
    .ant-input-group{
      display: flex;
      flex-direction: row-reverse;
      .ant-input-affix-wrapper{
        margin-left: 35px;
        border-top-right-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
      }
      .ant-input-search-button{
        width: 40px !important;
        border-top-left-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
      }
    }
  }

  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-header {
    height: 50px!important;
    background-color: #F3F6F9;
    border-radius:8px 8px 0px 0px;
  }
  .ant-modal-body {
    display: flex;
    padding: 24px;
    flex-direction: column;
    border-radius:8px 8px 0px 0px;
    background-color: #F3F6F9 !important;
    overflow-x: auto;
    // height: 850px !important;

    .header-options{
      // height: 80px;
      display: flex;
      flex-direction: column;
      // margin-bottom: 20px;

      .second-row{
        width: 100%;
        display: flex;
        margin-top: 10px;
        padding: 0px 15px;
        align-items: center;
        flex-direction: row;
        justify-content: flex-end;
      }

      p{
        color: #8c8c8c;
        margin-bottom: 0px;
        margin-right: 5px;
      }

      .import{
        height: 30px;
        width: max-content;
        margin: 0px 15px;
        border-radius: 4px;
      }
    }

    .show-more-btn{
      margin-top: 10px;
      width: max-content;
      align-self: center;
      border-radius: 5px;
    }
    
    .contact-list{
      // height: 100%;
      max-height: 350px;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      margin-top: 20px;
      
      .contact{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 15px;
        margin-bottom: 10px;
        border-radius: 8px;
        background-color: #FFF;
        justify-content: space-between;

        p{
          color: #8c8c8c;
          margin-bottom: 0px;
        }
        .text-group{
          margin-left: 20px;
          h3{
            margin-bottom: 0px;
          }
          .mutuals{
            display: flex;
            flex-direction: row;
            p{
              color: #8c8c8c;
              margin-left: 10px;
              margin-bottom: 0px;
            }
          }
        }
      }
    }
  }
}

.modal-interest-tags{
  width: 1000px !important;

  .ant-modal-content {
    background-color: #FFF;
    border-radius: 8px;
  }
  .ant-modal-body {
    padding: 0px 23px 2px 23px;
  }
  .ant-modal-header {
    background-color: #FFF;
    border-radius:8px 8px 0px 0px;
    border-bottom: none;
  }

  .mod-title {
    font-size: 16px;
    font-weight: 600;
  }

  .ant-modal-close-x {
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: 14px;
  }

  .modal-message {
    font-size: 14px;
  }

  .ant-modal-footer {
    // border: 0;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .modal-ul {
    padding-left: 20px;
  }

  .search-interest{
    margin-top: 0px;
    margin-bottom: 20px;

    .ant-input-affix-wrapper{
      height: 40px !important;
      border-top-left-radius: 5px !important;
      border-bottom-left-radius: 5px !important;
    }
    .ant-btn{
      width: 50px;
      height: 40px !important;
      border-top-right-radius: 5px !important;
      border-bottom-right-radius: 5px !important;
    }
  }
  .update-btn-mobile{
    top:12px;
    right: 20px;
    position: absolute;
  }

  .tag-grid::-webkit-scrollbar {
    height: 10px !important;
  }
  /* Track */
  .tag-grid::-webkit-scrollbar-track {
    border-radius: 50px;
    background: #fafafa;
  }
  /* Handle */
  .tag-grid::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background: #f0f0f0;
  }

  .tag-grid{
    overflow-x: auto;
    display: grid;
    gap: 20px 20px;
    padding-bottom: 15px;
    grid-template-columns: 175px 175px 175px 175px 175px 175px 175px;

    @media (max-width: 1050px) {
      gap: 15px 15px;
      grid-template-columns: auto auto auto auto;
    }
 
    // @media (max-width: 992px) {
    @media (max-width: 750px) {
      overflow-y: auto;
      gap: 10px 10px;
      grid-template-columns: auto auto auto;
    }
    @media (max-width: 600px) {
      overflow-y: auto;
      gap: 10px 10px;
      grid-template-columns: auto auto;
    }

    .interest-tag-selected{
      width: 100%;
      min-height: 130px;
      padding: 10px;
      border-radius: 10px;
      background-color: #FFF;
      border: 2px solid#02B8F6;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      .name{
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 0px;
      }
      
      .count{
        width: max-content;
        color: #FFF;
        padding: 2px 10px;
        border-radius: 50px;
        margin-bottom: 0px;
        background-color: #02B8F6;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    
    .interest-tag{
      width: 100%;
      min-height: 130px;
      padding: 10px;
      border-radius: 10px;
      border:2px solid #F3F6F9;
      background-color: #FFF;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      .name{
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 0px;
      }
      
      .count{
        width: max-content;
        color: #FFF;
        padding: 2px 10px;
        border-radius: 50px;
        margin-bottom: 0px;
        background-color: #02B8F6;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .interest-tag-disabled{
      width: 100%;
      padding: 16px;
      border-radius: 10px;
      border:1px solid #d9d9d9;
      background-color: #d9d9d9;
      pointer-events: none;
      
      .tag{
        width: max-content;
        max-width: 140px;
        color: #FFF;
        padding: 2px 10px;
        border-radius: 4px;
        background-color: #8c8c8c;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .tag-info{
      font-size: 14px;
      color: #595959;
      margin-bottom: 0px;
      display: flex;
      align-items: center;

      img{
        margin-right: 5px;
      }
    }
  }

  .interest-page-btns{
    display: flex;
    width: 150px;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    justify-content: space-between;

    p{
      margin-bottom: 0px;
    }

    Button{
      border-radius: 2px;
      width: 40px;
      height: 40px;
      padding:10px
    }
  }
}

.modal-time-slots{
  width: 615px!important;
  .ant-modal-content {
    background-color: #FFF;
    border-radius: 8px;
  }
  .ant-modal-body {
    padding: 20px 23px 2px 23px;
  }
  .ant-modal-header {
    background-color: #FFF;
    border-radius:8px 8px 0px 0px;
  }

  .mod-title {
    font-size: 16px;
    font-weight: 600;
  }

  .ant-modal-close-x {
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: 14px;
  }

  .modal-message {
    font-size: 14px;
  }

  .ant-modal-footer {
    // border: 0;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .modal-ul {
    padding-left: 20px;
  }

  .tag-grid{
    display: grid;
    gap: 20px 20px;
    grid-template-columns: 175px 175px 175px;

    @media (max-width: 992px) {
      grid-template-columns: auto;
    }

    .interest-tag-b{
      width: 100%;
      padding: 16px;
      border-radius: 10px;
      border:1px solid #0077CD;
      background-color: #F3F6F9;
      cursor: pointer;

      .tag-b{
        width: max-content;
        max-width: 140px;
        color: #FFF;
        padding: 2px 10px;
        border-radius: 4px;
        background-color: #0077CD;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .interest-tag{
      width: 100%;
      padding: 16px;
      border-radius: 10px;
      border:1px solid #F3F6F9;
      background-color: #F3F6F9;
      cursor: pointer;
      
      .tag{
        width: max-content;
        max-width: 140px;
        color: #FFF;
        padding: 2px 10px;
        border-radius: 4px;
        background-color: #389E0D;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
 
    }
    .tag-info{
      font-size: 14px;
      color: #595959;
      margin-bottom: 0px;
      display: flex;
      align-items: center;

      img{
        margin-right: 5px;
      }
    }
  }
}