.fadein {
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.fadeout {
  animation: fadeOutAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.fadein-fast {
  animation: fadeInAnimation ease 0.3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.fadeout-fast {
  animation: fadeOutAnimation ease 0.3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOutAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.anim-slide.close {
  height: 0px;
  min-height: 0px !important;
  opacity: 0;
  padding: 0 !important;
  margin: 0 !important;
  -webkit-transition: height, 0.1s linear;
  -moz-transition: height, 0.1s linear;
  -ms-transition: height, 0.1s linear;
  -o-transition: height, 0.1s linear;
  transition: height, 0.1s linear;
}
.anim-slide.open {
  // min-height: 268px;
  // height: auto;
  -webkit-transition: height, 0.1s linear;
  -moz-transition: height, 0.1s linear;
  -ms-transition: height, 0.1s linear;
  -o-transition: height, 0.1s linear;
  transition: height, 0.1s linear;
}


