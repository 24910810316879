.modal-invite-club-member {
  .ant-modal-body {
    padding: 16px;
  }
  ul {
    height: 320px;
    max-height: 320px;
    overflow-y: auto;
    padding: 0;

    li {
      display: flex;
      align-items: center;
      padding: 8px;
      list-style: none;
      border-bottom: 1px solid #f0f0f0;

      .no-avatar {
        width: 54px;
        height: 54px;
        margin-right: 16px;
      }

      h5 {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin: 0;
        margin-right: auto;
      }
    }
  }
}
