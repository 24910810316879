// width max reference:
// mobile 430px
// tablet 820px
// tag app class for content specific changes
// Global

.dashboard-upcoming-events {
  .ant-btn {
    .view-all-content {
      display: none;
    }
  }
}

.flutter-sidebar-nav {
  .ant-drawer-content-wrapper {
    width: unset !important;

    @media screen and (max-width: 430px) {
      width: 100% !important;
      max-width: unset;
    }

    .ant-drawer-body {
      overflow: hidden;
      padding: 0;
    }
  }
}

// Disable Flutter Components
.menu-wrapper.app {
  display: none !important;
}

// Flutter Components
.AppMainLayout.flutter {
  
  .app-main-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    padding: 0 16px !important;
    color: black !important;

    .logo {
      width: 120px !important;
      height: 31px !important;
      margin: 16px 24px 16px 0 !important;
      background-size: contain !important;
      background-repeat: no-repeat !important;
      background-color: white !important;
    }
    
    .action-buttons {
      display: flex;
      align-items: center;

      .notif-container {
        color: black !important;
      }

      .sidebar-nav-button {
        background: transparent;
        border: 0;
      }
      
      .app-main-header-nav {
        background: transparent;
      }
    }
  }

  .empty-state {
    img {
      height: 320px;
      width: 100%;
      text-align: center;
    }

    h1 {
      font-size: 42px;
    }

    p {
      font-size: 24px;
    }

    button {
      height: 57px !important;
      width: 150px !important;

      span {
        font-size: 21px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;

      }
    }

    @media screen and (max-width: 430px) {
      img {
        height: 230px;
      }
      h1 {
        font-size: 24px;
      }
  
      p {
        font-size: 14px;
      }
  
      button {
        height: 54px !important;
        width: 115px !important;

        span {
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }

  .empty-state.state-no-events {
    display: none;
  }
  .empty-state.state-no-events.app {
    display: flex;
    .text-content {
      width: 100%;
    }
  }

  .empty-state.state-no-result {
    flex-direction: column-reverse;
  }

  .coming-soon-state {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;

    h1 {
      text-align: center;
      font-size: 42px;
      font-weight: 500;
      line-height: 63px;
      letter-spacing: 0em;

      @media screen and (max-width: 430px) {
        font-size: 24px;
      }
    }
    p {
      font-size: 24px;
      font-weight: 400;
      line-height: 36px;
      letter-spacing: 0em;
      text-align: center;

      @media screen and (max-width: 430px) {
        font-size: 14px;
      }
    }

    img {
      width: 100%;
      height: 320px;


      @media screen and (max-width: 430px) {
        height: 230px;
      }
    }
    
    button {
      width: 178px;
      height: 57px;
      border-radius: 10px;

      @media screen and (max-width: 430px) {
        height: 54px;
        width: 134px !important;
      }

      span {
        font-size: 21px;
        font-weight: 400;
        letter-spacing: 0em;
        text-align: left;

        @media screen and (max-width: 430px) {
          font-size: 14px;
        }
      }
    }
  }

  .page-header-container {
    .controls-container {
      align-self: flex-start;
    }
  }

  .profile-page-single-content {
    
    
    .profile-summary {
      background: transparent;
      padding: 0;
    }
    .profile-summary > h3 {
      display: none;
    }

    .profile-club-interest {
      .interest-container {
        flex-wrap: wrap;
        gap: 12px;
        .interest-item {
          width: 45% !important;
          margin: 0 !important;
        }

        a {
          margin: 0 !important;
          width: 50% !important;
          .interest-item {
            margin: 0 !important;
            width: 100% !important;
          }
        }
      }
    }

    .profile-softskill {
      canvas {
        width: 100% !important;
        height: 100% !important;
      }
    }

    .profile-upcoming-event {
      .event-details {
        flex-direction: column;
        justify-content: start;
        gap: 12px;
        color: rgba(0, 0, 0, 0.45);
      }
    }
  }

  
  

  .col-calendar {
    max-width: 100%;
    @media screen and (max-width: 430px) {
      flex: 0 0 100%;
    }

    .content-right-main.profile-calendar {
      height: initial;
  
      .profile-calendar-container {
        display: block !important;
        margin-top: 24px;
        position: unset;

        @media screen and (max-width: 430px) {
          width: 100% !important;
        }
      }
    }
  }

  .profile-club-joined {
    ul {
      display: flex;
      flex-wrap: wrap;
      li {

        width: 50%;
      }
    }
  }

  .app-main-content {

    .dashboard-upcoming-events {
      .navigation {
        .navigation-unary {
          align-items: flex-start;
          gap: 16px;
          align-self: stretch;
          display: none;
    
          @media screen and (max-width: 430px) {
            display: flex;
          }
          
          .arrow {
            height: 40px;
            width: 46px;
            border-radius: 5px;
            border: 1px solid #D9D9D9;
            background: #FFF;
        
            box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
          }
        
          .label {
            width: 100%;
            border-radius: 4px;
            background: #02B8F6;
            padding: 8px 16px;
        
            p {
              font-size: 16px;
              text-align: center;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
              color: #FFF;
              margin: auto;
            }
          }
        }
    
        .navigation-menu {
          @media screen and (max-width: 430px) {
            display: none;
          }
        }

        .ant-menu-item-selected {
          color: #fff;
        }
      }
    
      .events {
        justify-content: flex-start;
        
        @media screen and (max-width: 430px) {
          flex-wrap: wrap;
        }

        .event-cards {
          display: none;
          flex-direction: column;
          width: 100%;
          gap: 24px;

          @media screen and (max-width: 430px) {
            display: flex;
          }

          .card {
            display: flex;
            padding: 10px;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
            align-self: stretch;

            border-radius: 4px;
            border: 1px solid #BFBFBF;
            background: #fff;

            .row:nth-child(odd) {
              background: #F5F5F5;
            }

            .row {
              display: flex;
              justify-content: flex-start;
              width: 100%;

              .title {
                width: 50%;
                color: rgba(0, 0, 0, 0.85);
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 22px;
              }

              .content {
                width: 50%;
                color: rgba(0, 0, 0, 0.85);
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
              }
            }
          }

        }

        .event-table {

          @media screen and (max-width: 430px) {
            display: none;
          }

          .ant-table-thead {
            background: #F5F5F5;

            .ant-table-cell:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
              position: absolute;
              top: 0;
              right: 0;
              width: 0;
              height: 0;
              background-color: transparent;
              content: '';
          }


            .ant-table-cell {
              padding: 8px;
              border: 0;
            }
          }
          .dark {
            background: #F5F5F5;
          }
        }
    
        .event {
          padding: 0;
          width: 329px;
    
          @media screen and (max-width: 430px) {
            width: 100%;
          }
    
          .event-header {
            width: 329px;
    
            @media screen and (max-width: 430px) {
              width: 100%;
            }
    
            .dropdown {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 36px;
              height: 32px;
            }
          }
    
          .image-banner, .image-placeholder {
            height: 200px;
            width: 329px;
            
            @media screen and (max-width: 430px) {
              width: 100%;
            }
          }
    
          .event-information {
            margin: 16px 0;
    
            .event-title, .event-details {
              margin: 0;
            }
    
            .event-club {
              margin: 10px 0 0;
            }
          }
        }
      }
    }

    .dashboard-students-boards.app {
      .header {
        display: flex;

        @media screen and (max-width: 430px) {
          flex-direction: column;
          margin-bottom: 32px;
        }
      }

      .students {
        .point-labels {
          display: flex;
          position: relative;
          width: 100%;

          @media screen and (max-width: 430px) {
            display: none;
          }

          .start-group {
            width: calc(100% - 128px);
            height: 100%;
          }

          .end-group {
            display: flex;
            flex-direction: row;
            width: 128px;

            p {
              overflow: hidden;
              text-wrap: nowrap;
              text-overflow: ellipsis;

            }
          }
        }
        .student-data {
          .student {
            @media screen and (max-width: 430px) {
              display: none;
            }

            .start-group {
            width: calc(100% - 128px);

              .invite-image {
                margin: 0;
              }

              .text {
                padding: 0 12px;
                width: calc(100% - 64px);

                h3 {
                  height: 24px;
                  font-size: 16px;
                  text-wrap: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }

                p {
                  height: 18px;
                  font-size: 14px;
                  text-wrap: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }
            .end-group {
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              width: 128px;

              h1 {
                width: 50%;
                text-align: end;
              }
            }
          }

          .student.mobile {
            display: none;

            @media screen and (max-width: 430px) {
              display: flex;
            }
            
            justify-content: center;
            align-items: center;
            height: 84px;
            border-radius: 4px;
            background: #F3F6F9;
            padding: 10px;

            .start-group {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 64px;

              .invite-image {
                margin: 0;
              }
            }
            .end-group {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: center;
              width: calc(100% - 64px);
              color: black;

              .student-name {
                background: #D9D9D9;
                padding: 0 10px;
                width: 100%;
                text-overflow: ellipsis;
                text-wrap: nowrap;
                margin-bottom: 2px;
              }

              p {
                border-radius: 4px;
                margin: 0;
              }

              .student-info {
                margin: 1px 0;
                span {
                  color: rgba(0, 0, 0, 0.85) !important;
                }
              }
            }
          }
        }
      }
    }
  
    .section-club-event {
      .dashboard-top-clubs {
        .clubs {
          height: 425.5px;
          
          .point-label {
            position: relative;
            justify-content: right;
          }
          .club-top-content {
            height: 455.5px;
            overflow-y: scroll;
          }
        }
      }
    }

    .dashboard-pending-approval {
      .header {
        display: flex;
        height: 64px;
        justify-content: space-between;

        .menu-wrapper {
          display: flex;
          justify-content: flex-end;
          height: 42px;
          min-width: 340px;
          @media screen and (max-width: 430px) {
            min-width: unset;
          }
        }
      }

      .pending {
        .invitation {
          .start-group {
            width: calc(100% - 96px);

            .invite-image {
              margin: 0;
            }

            .text {
              padding: 0 12px;
              width: calc(100% - 64px);

              h3 {
                height: 24px;
                font-size: 16px;
                text-wrap: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              p {
                height: 18px;
                font-size: 14px;
                text-wrap: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
          .end-group {
            display: flex;
            justify-content: flex-end;

            @media screen and (max-width: 430px) {
              gap: 12px;
              width: 25%;

              button {
                margin: 0;
                padding: 0 0;
                height: 32px;
                width: 40%;
              }
            }
          }
        }
      }

      @media screen and (max-width: 430px) {
        .header {
          display: flex;
          flex-direction: column;
        }
      }
    }
  
    .dashboard-distribution {
      .header {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;

        .menu-header {
          width: 100%;
          margin-bottom: 24px;

          .menus {
            width: 100%;

            .ant-menu-item {
              width: 50%;

              .ant-menu-title-content {
                width: 100%;
                text-align: center;
                
                p {
                  overflow: hidden;
                  text-overflow: ellipsis;
                }

              }
            }
          }
        }
      }
    }

    .dashboard-faculty {
      .header {
        display: flex;
        height: 64px;
        justify-content: space-between;

        .menu-wrapper {
          display: flex;
          justify-content: flex-end;
          height: 42px;
          min-width: 400px;
          @media screen and (max-width: 430px) {
            min-width: unset;
          }
        }
      }

      .point-labels {
        display: flex;
          position: relative;
          width: 100%;

          @media screen and (max-width: 430px) {
            display: none;
          }

          .start-group {
            width: calc(100% - 300px);
            height: 100%;
          }

          .end-group {
            display: flex;
            flex-direction: row;
            width: 300px;

            p {
              overflow: hidden;
              text-wrap: nowrap;
              text-overflow: ellipsis;

            }
          }
      }

      .faculty {
        @media screen and (max-width: 430px) {
          display: none;
        }

        
        
        .start-group {
          width: calc(100% - 300px);

          .invite-image {
            margin: 0;
          }

          .text {
            padding: 0 12px;
            width: calc(100% - 64px);

            h3 {
              height: 24px;
              font-size: 16px;
              text-wrap: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            p {
              height: 18px;
              font-size: 14px;
              text-wrap: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        .end-group {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          width: 300px;

          h1 {
            width: 33.33%;
            text-align: end;
          }
        }
      }

      .faculty.mobile {
        display: none;

        @media screen and (max-width: 430px) {
          display: flex;
        }
        
        justify-content: center;
        align-items: center;
        height: 84px;
        border-radius: 4px;
        background: #F3F6F9;
        padding: 10px;

        .start-group {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 64px;

          .invite-image {
            margin: 0;
          }
        }
        .end-group {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          width: calc(100% - 64px);
          color: black;
          gap: 0 !important;

          .faculty-name {
            background: #D9D9D9;
            padding: 0 10px;
            width: 100%;
            text-overflow: ellipsis;
            text-wrap: nowrap;
            margin-bottom: 2px;
          }

          p {
            border-radius: 4px;
            margin: 0 !important;
          }

          .faculty-info {
            margin: 1px 0 !important;
            span {
              color: rgba(0, 0, 0, 0.85) !important;
            }
          }
        }
      }

      @media screen and (max-width: 430px) {
        .header {
          display: flex;
          flex-direction: column;
        }
      }
    }
  
    .menu-wrapper {
      display: block;
      margin: 0;
    }
    
    .menu-wrapper.app {
      display: none;
    }

    .view-all {
      .view-all-content {
        display: none;
      }
    }
  
    @media screen and (max-width: 430px) {
      .view-all {
        width: 100% !important;
        height: 40px !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;

        .view-all-content {
          display: block;
        }
        .show-more-content {
          display: none;
        }
      }
  
      .menu-wrapper {
        display: none !important;
        width: 100% !important;
      }
  
      .menu-wrapper.app {
        display: flex !important;
  
        .menus::after {
          content: unset;
        }
  
        .menus::before {
          content: unset;
        }
  
        .menus {
          padding: 0;
          width: 100%;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
  
          .ant-menu-item:hover {
            color: #FFF;
          }
  
          .ant-menu-item {
            margin: 0;
            padding: 0;
            width: 48%;
  
            .ant-menu-title-content {
              width: 100%;
              text-align: center;
            }
          }
        }
      }
    }
    
  }

  .ant-layout-footer {
    @media screen and (max-width: 430px) {
      padding-left: 5%;
      padding-right: 5%;
    }
    padding-left: 50px;
    padding-right: 50px;
    .footer-wrapper {
      width: 100%;
      .footer-links {
        display: block;
      }
    }
  }
}

