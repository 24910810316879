.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}
.login-success-page {
  background-color: $breeze-color-main;
  height: 100vh;

  // @media (max-height: 420px) {
  //   padding: 30px 0 30px 0;
  //   height: auto;
  // }

  .anim-container {
    visibility: visible;
    opacity: 1;
    background-color: $breeze-color-main;
    height: 100%;
    background-color: $breeze-color-blue-2;
    // background-size: 500px;
    background-repeat: no-repeat;
    background-position: right 15px bottom 13px;

    .main-container {
      padding-left: 8%;
      padding-right: 8%;
      padding-top: 8%;
    }
    .text-head {
      font-weight: 700;
      font-size: 64px;
      color: #ffffff;
      margin-top: 20px;
      margin-bottom: 0;
    }
    .text-desc {
      font-weight: 500;
      font-size: 20px;
      color: #ffffff;
    }
    ul {
      padding: 0;
      li {
        list-style: none;
        color: #ffffff;
        margin-bottom: 5px;
        font-size: 16px;
      }
    }

    button {
      height: 50px;
      img {
        margin-right: 10px;
      }
      border-radius: 8px;
    }
    @media (max-width: 575.98px) {
    }

    // Small devices (landscape phones, less than 768px)
    @media (max-width: 767.98px) {
      .text-head {
        font-size: 30px;
        line-height: 55px;
        margin-bottom: 30px;
      }
      .text-desc {
        font-size: 16px;
      }
    }

    // Medium devices (tablets, less than 992px)
    @media (max-width: 991.98px) {
      .text-head {
        font-size: 44px;
      }
      .text-desc {
        font-size: 16px;
      }
    }

    // Large devices (desktops, less than 1200px)
    @media (max-width: 1199.98px) {
      .text-head {
        font-size: 44px;
      }
      .text-desc {
        font-size: 18px;
      }
    }

    // X-Large devices (large desktops, less than 1400px)
    @media (max-width: 1399.98px) {
      background-size: 60%;
    }
  }
}
