.promotions-page{
    display: flex;
    padding: 24px;
    margin: 24px;
    align-items: center;
    flex-direction: column;
    background-color: #fff;

    .header{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .tab-group{
            .tab{
                margin: 0px;
                border-radius: 0px;
                border: none;
                background-color: #fff;
            }
            .selected-tab{
                margin: 0px;
                border: none;
                color: #02b8f6;
                border-radius: 0px;
                background-color: #fff;
                border-bottom: 2px solid #02b8f6;
            }
        }
    }

    .table-container{
        width: 100%;
        padding: 1px;
        margin-top: 20px;
        background-color: #D9D9D9;

        .ant-table-thead{
            .ant-table-cell{
                min-width: 150px;
                background-color: #D9D9D9;
            }
        }
        .ant-table-tbody{
            .ant-table-row{
                .ant-table-cell{
                    padding: 10px 16px;
                    p{
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
}

.create-promotions-page{
    display: flex;
    padding: 24px;
    flex-direction: column;
    background-color: #fff;

    .title{
        font-size: 28px;
    }

    .tab{
        margin: 0px;
        width: 200px; 
        border-radius: 0px;
        border: 1px solid #d3d3d3;
    }
    .promo-select{
        width: 100% !important;
        .ant-select-selection-item{
            display:none;
        }
    }
    .selected-tab{
        margin: 0px;
        width: 200px; 
        color: #02b8f6;
        border-radius: 0px;
        border: 1px solid #02b8f6;
        background-color: #EBFAFF;
    }

    .table-heading{
        width: 100%;
        padding: 5px;
        margin-top: 16px;
        background-color: #D9D9D9;
    }

    .ie-inner{
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 600px;
        padding: 24px;
        border-radius: 5px;
        background-color: #fff;
        border: 1px dashed #d3d3d3;

        .ie-img{
            border-radius: 5px;
            max-height: 300px !important;
            margin-bottom: 10px;
            overflow: hidden;
        }

    }
    .ant-upload-list-text-container{
        width: 600px;
    }

    .create-promo-table{
        .ant-table-thead{
            .ant-table-cell{
                min-width: 150px;
                background-color: #D9D9D9;
            }
        }
        .ant-table-tbody{
            .ant-table-row{
                p{
                    margin-bottom: 0px;
                }
                .event-name{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    p{
                        margin-bottom: 0px;
                    }
                    
                    .sort-btns{
                        display: flex;
                        margin-right: 10px;
                        flex-direction: column;
                        Button{
                            width: 25px;
                            height: 25px;
                            padding: 0px;
                            height: 20px;
                            border: none;
                            font-size: 10px;
                            background-color: transparent;
                        }
                    }
                }
            }
        }
    }
    .view-promo-event{
        
    }
}