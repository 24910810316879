.club-event-list {
  .list-controls {
    display: flex;
    // flex-wrap: wrap;
    
    justify-content: space-between;
    padding-bottom: 20px;



    .ant-input-search-button {
      height: 40px;
    }
    .ant-input-affix-wrapper {
      height: 40px;
    }
    .list-filters {
      button {
        margin-left: 5px;
        height: 40px;
        width: 40px;
      }
      button:last-child {
        width: auto;
      }
    }

    @media screen and (max-width: 430px) {
      flex-flow: column;
      flex-direction: column-reverse;
      align-items: end;
      gap: 10px;
      .search-container {
        width: 100%;
        .ant-input-search {
          width: 100% !important;
        }
      }
      .list-filters {
        button {
          margin-left: 0px;
          height: 40px;
          width: 40px;
        }
        
      }
    }
  }
  .event-item-grid-view {
    height: 100%;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 5px;
    background-color: white;
    display: flex;
    flex-direction: column;

    .custom-skeleton {
      .ant-skeleton-content {
        padding: 24px;
      }
    }

    .event-item-image {
      width: 100%;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      .ant-skeleton-image {
        width: 100%;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        object-fit: cover;
        height: 170px;
      }

      img {
        width: 100%;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
    }

    .event-item-content {
      //   height: 100%;
      position: relative;
      padding: 24px;
      padding-bottom: 0;
      //   display: flex;
      //   flex-direction: column;
      //   height: 100%;

      .event-item-details {
        display: flex;
        color: black;

        span {
          margin-top: 4px;
        }
        p {
          margin: 0;
          font-size: 15px;
        }
      }

      .event-item-title {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        margin: 0;
      }
      .event-item-description {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.45);
        margin: 0;
        word-break: break-all;
      }
      .event-item-interest {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.45);
        margin: 0;
      }
    }
    .event-item-controls {
      padding: 18px 24px 24px 24px;
      display: flex;
      justify-content: space-between;
      height: 100%;
      align-items: flex-end;
      button:first-child {
        margin-right: 4px;
      }
      button:last-child {
        margin-left: 4px;
      }
      .event-members-avatar {
        display: flex;
        div {
          border: 2px solid white;
          margin-left: -6px;
        }
        div:first-child {
          margin-left: 0;
        }
      }
    }
  }
  .event-item-list-view {
    // height: 100%;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 5px;
    background-color: white;
    display: flex;

    .custom-skeleton {
      .ant-skeleton-content {
        padding: 15px;
      }
    }

    .event-item-image {
      width: 257px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      .ant-skeleton-image {
        width: 257px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        object-fit: cover;
        height: 170px;
      }
      img {
        width: 257px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        object-fit: cover;
      }
    }
    .event-item-content {
      width: 100%;
      padding: 15px;
      padding-left: 25px;

      .event-item-details {
        display: flex;
        color: black;

        span {
          margin-top: 4px;
        }
        p {
          margin: 0;
          font-size: 15px;
        }
      }

      .event-content-wrapper {
        display: flex;
        flex-direction: column;
      }

      .event-item-title {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        margin: 0;
      }
      .event-item-description {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.45);
        margin: 0;
        word-break: break-all;
      }

      .right-controls {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
        align-items: center;

        .event-item-interest {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: rgba(0, 0, 0, 0.45);
          margin: 0;
        }
      }

      .event-members-avatar {
        display: flex;
        margin-top: auto;
        div {
          border: 2px solid white;
          margin-left: -6px;
        }
        div:first-child {
          margin-left: 0;
        }
      }
    }
  }
}

.attendance-remarks-solo{
  flex:2.5;
  display: flex;
  
  @media (max-width: 600.98px) {
    flex:0;
    display: flex;
    align-items: center;
    flex-direction: column !important;
  }
}

.attendance-remarks-full{
  min-width: 780px;
  @media (max-width: 600.98px) {
    overflow-x: scroll;
  }
}

.attendance-layout{
  @media (max-width: 900px) {
    overflow-x: scroll;
  }
}
.attendance-layout::-webkit-scrollbar {
  display: none;
}