html {
  scroll-behavior: smooth;
}
/* GENERIC CLASSES */
.text-white {
  color: white;
}

.text-semibold {
  font-weight: 600;
}

.page-wrapper {
  max-width: 1400px;
  margin: 0 auto;
}

//##display class
.d-flex {
  display: flex;
}
.d-flex-column {
  display: flex;
  flex-direction: column;
}
.position-absolute {
  position: absolute;
}
.position-relative {
  position: relative;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-end {
  justify-content: end;
}
.justify-content-space-between {
  justify-content: space-between;
}
.align-items-center {
  align-items: center;
}

.d-flex-i {
  display: flex !important;
}

.d-block {
  display: block;
}

.d-none {
  display: none;
}

.d-none-important {
  display: none !important;
}

.d-inline-block {
  display: inline-block;
}
.d-sm-none {
  @media (max-width: 576px) {
    display: none;
  }
}
.d-md-none {
  @media (min-width: 577px) {
    display: none;
  }
}

// ##Spacing
$spacing-base: 0.3rem;
.mt-auto {
  margin-top: auto !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: $spacing-base !important;
}

.mt-2 {
  margin-top: $spacing-base * 2 !important;
}
.mt-3 {
  margin-top: $spacing-base * 3 !important;
}
.mt-4 {
  margin-top: $spacing-base * 4 !important;
}
.mt-5 {
  margin-top: $spacing-base * 5 !important;
}
.mt-6 {
  margin-top: $spacing-base * 6 !important;
}
.mt-7 {
  margin-top: $spacing-base * 7 !important;
}
.mt-8 {
  margin-top: $spacing-base * 8 !important;
}
.mt-20px {
  margin-top: 20px;
}
.mt-24px {
  margin-top: 24px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-1 {
  margin-bottom: $spacing-base;
}

.mb-1-i {
  margin-bottom: $spacing-base !important;
}

.mb-2 {
  margin-bottom: $spacing-base * 2;
}
.mb-3 {
  margin-bottom: $spacing-base * 3;
}
.mb-4 {
  margin-bottom: $spacing-base * 4;
}
.mb-5 {
  margin-bottom: $spacing-base * 5;
}
.mb-6 {
  margin-bottom: $spacing-base * 6;
}
.mb-20px {
  margin-bottom: 20px;
}

.pt-0 {
  padding-top: 0;
}
.pt-1 {
  padding-top: $spacing-base;
}

.pt-2 {
  padding-top: $spacing-base * 2;
}

.pt-3 {
  padding-top: $spacing-base * 3 !important;
}

.pt-4 {
  padding-top: $spacing-base * 4;
}

.pt-5 {
  padding-top: $spacing-base * 5;
}

.pl-1 {
  padding-left: $spacing-base;
}

.pl-2 {
  padding-left: $spacing-base * 2;
}

.pl-3 {
  padding-left: $spacing-base * 3 !important;
}

.pl-4 {
  padding-left: $spacing-base * 4;
}

.pl-5 {
  padding-left: $spacing-base * 5;
}

.pl-6 {
  padding-left: $spacing-base * 6;
}

.pl-7 {
  padding-left: $spacing-base * 7;
}
.pl-8 {
  padding-left: $spacing-base * 8;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: $spacing-base;
}

.pb-2 {
  padding-bottom: $spacing-base * 2;
}

.pb-3 {
  padding-bottom: $spacing-base * 3;
}

.pb-4 {
  padding-bottom: $spacing-base * 4;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: $spacing-base;
  padding-right: $spacing-base;
}

.px-2 {
  padding-left: $spacing-base * 2;
  padding-right: $spacing-base * 2;
}

.px-3 {
  padding-left: $spacing-base * 3 !important;
  padding-right: $spacing-base * 3;
}

.px-4 {
  padding-left: $spacing-base * 4;
  padding-right: $spacing-base * 4;
}

.px-5 {
  padding-left: $spacing-base * 5;
  padding-right: $spacing-base * 5;
}

.px-6 {
  padding-left: $spacing-base * 6;
  padding-right: $spacing-base * 6;
}

.px-7 {
  padding-left: $spacing-base * 7;
  padding-right: $spacing-base * 7;
}

.px-8 {
  padding-left: $spacing-base * 8;
  padding-right: $spacing-base * 8;
}

.px-9 {
  padding-left: $spacing-base * 9;
  padding-right: $spacing-base * 9;
}
.px-10 {
  padding-left: $spacing-base * 10;
  padding-right: $spacing-base * 10;
}

.no-padding {
  padding: 0 !important;
}

.ml-auto {
  margin-left: auto;
}

.ml-1 {
  margin-left: $spacing-base;
}

.ml-2 {
  margin-left: $spacing-base * 2;
}

.ml-3 {
  margin-left: $spacing-base * 3;
}

.ml-4 {
  margin-left: $spacing-base * 4;
}

.ml-5 {
  margin-left: $spacing-base * 5;
}

.mr-0 {
  margin-right: 0 !important;
}
.mr-auto {
  margin-right: auto;
}

.mr-1 {
  margin-right: $spacing-base;
}

.mr-2 {
  margin-right: $spacing-base * 2;
}

.mr-3 {
  margin-right: $spacing-base * 3;
}

.mr-4 {
  margin-right: $spacing-base * 4;
}

.mr-5 {
  margin-right: $spacing-base * 5;
}

.mr-6 {
  margin-right: $spacing-base * 6;
}
.mr-7 {
  margin-right: $spacing-base * 7;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.text-center {
  text-align: center;
}
.text-error {
  color: red;
}
.text-bold {
  font-weight: bold;
}

.h-100 {
  height: 100%;
}
.h-100-vh {
  height: 100vh;
}
.h-100-i {
  height: 100% !important;
}
.w-100 {
  width: 100%;
}
.w-100-i {
  width: 100% !important;
}

.p-relative {
  position: relative;
}

.w-auto {
  width: auto !important;
}

.pos-left {
  left: 0;
}
.pos-right {
  right: 0;
}

.blur-effect {
  filter: blur(4px);
}

.text-red {
  color: #f5222d;
}

//icon

.icon-rotate {
  .anticon {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

.button-bordered {
  border: 1px solid #d9d9d9;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 2px;
}

.button-bordered:hover {
  border: 1px solid #d9d9d9;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 2px;
}

.breeze-slider {
  position: relative;

  .ant-slider {
    z-index: 2;
  }
  .ant-form-item-control {
    max-width: 100%;
  }
}

.top-9 {
  position: absolute;
  top: 9px;
}

.text-black {
  color: black;
}

.text-white {
  color: white;
}

.custom-tooltip-container {
  position: relative;
  visibility: visible;
  opacity: 1;

  .arrow-left {
    position: absolute;
    left: -6px;
    bottom: 6px;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid white;
  }
}
.custom-tooltip-container:hover {
  .custom-tooltip-content {
    visibility: visible;
    opacity: 1;
  }
}

//global ant inpuit & label
.ant-input {
  border-radius: 5px;
  padding: 8px 11px;
}

.ant-select-multiple {
  .ant-select-selector {
    min-height: 40px !important;
  }
}
.ant-select-single {
  .ant-select-selector {
    min-height: 40px;
    height: 40px;
    border-radius: 5px !important;
    input {
      min-height: 40px;
      height: 40px;
    }
  }
  .ant-select-selection-placeholder {
    line-height: 40px !important;
  }
  .ant-select-selection-item {
    line-height: 40px !important;
  }
}
//end global ant inpuit & label

.breeze-form-horizontal {
  .text-head {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  .text-desc {
    font-size: 12px;
    line-height: 20px;
  }
  .ant-input {
    border-radius: 5px;
    padding: 8px 11px;
  }
  input {
    height: 40px;
  }

  small {
    font-weight: 400;
    font-size: 14px;
    color: $breeze-light-gray;
  }

  min-height: 40px !important;

  .ant-select-multiple {
    .ant-select-selector {
      min-height: 40px !important;
    }
  }
  .ant-select-single {
    .ant-select-selector {
      min-height: 40px;
      height: 40px;
      border-radius: 5px;
      input {
        min-height: 40px;
        height: 40px;
      }
    }
    .ant-select-selection-placeholder {
      line-height: 40px;
    }
    .ant-select-selection-item {
      line-height: 40px;
    }
  }

  .ant-picker {
    height: 40px !important;
    width: 100%;
  }

  .select-search {
    position: absolute;
    right: 40px;
    padding-top: 10px;
    padding-left: 10px;
  }

  .ant-form-item-label > label {
    height: 40px;
  }

  .sticky-form-footer {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid #d9d9d9;
  }
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ant-notification {
  z-index: 1051;
}

.no-avatar {
  background-color: #eb2f96;
  border-radius: 50%;
  height: 42px;
  width: 42px;
  position: relative;
  span {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    color: white;
    font-size: 24px;
  }
}

.user-avatar {
  border-radius: 50%;
  height: 42px;
  width: 42px;
  img {
    border-radius: 50%;
    object-fit: contain;
  }
}

.avatar-sm {
  height: 32px;
  width: 32px;
  span {
    font-weight: normal;
    font-size: 16px;
  }
}

.avatar-md {
  height: 66px;
  width: 66px;
  span {
    font-weight: normal;
    font-size: 23px;
  }
}

.avatar-lg {
  height: 76px;
  width: 76px;
  span {
    font-weight: normal;
    font-size: 23px;
  }
}

.avatar-xl {
  height: 96px;
  width: 96px;
  span {
    font-weight: normal;
    font-size: 42px;
  }
}

.avatar-total-member {
  background-color: #ffd8bf;
  span {
    color: #fa541c;
    font-size: 12px;
  }
}

.no-bg {
  background-color: transparent !important;
}

.bg-white {
  background-color: white;
}

.search-global {
  @media (max-width: 576px) {
    width: auto;
  }
}

.custom-password {
  input {
    border-right: 0;
  }
  .ant-input-group-addon {
    border-left: 0;
    background-color: transparent;
  }
}

.earnedpoint-container {
  display: flex;
  // justify-content: space-between;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  .filter-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    margin: 0;
    padding-right: 10px;
  }
  .filter-collapse {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #02b8f6;
    margin: 0;
  }
}

.my-club-collapse {
  .my-club-container:first-child {
    @media screen and (max-width: 600.98px) {
      margin-top: 48px;
    }
  }
}

.my-club-container {
  width: 100%;
  display: flex;

  
  // justify-content: space-between;
  align-items: center;
  .filter-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    margin: 0;
    padding-right: 10px;
  }
  .filter-collapse {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #02b8f6;
    margin: 0;
  }
}
.badge-count {
  width: 24px;
  height: 24px;
  border-radius: 99px;
  color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  background: rgba(0, 0, 0, 0.85);
}

//for listing clubs and events with sidebar filtering
.listing-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 24px;
  transition: all 0.5s ease;
  .listing-sidebar {
    position: absolute;
    top: 0;
    height: calc(100vh - 223px);
    overflow-y: scroll;
    width: 100%;
  }
  .sidebar-placeholder {
    // width: 20%;
    position: relative;
    transition: all 0.5s ease;
    height: calc(100vh - 223px);
  }
  .listing-content-view {
    transition: all 0.5s ease;
    width: 100%;
    position: relative;
  }

  //responsive design and animation for Event Filter
  @keyframes sidebar-transition {
    from {
      width: 1280.98px;
    }
    to {
      width: 1281px;
    }
  }

  .hide-sidebar {
    width: 0;
  }

  .close-filters-sm {
    padding: 16px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    display: none;
  }

  .sidebar-date-filter {
    padding: 12px 16px;
    display: none;
  }

  @media screen and (min-width: 1281px) {
    .show-sidebar {
      width: 35%;
    }
  }

  // Medium devices
  @media screen and (max-width: 1280.98px) {
    .show-sidebar {
      position: fixed;
      z-index: 100;
      overflow-y: scroll;
      top: 0;
      right: 0;
    }

    .sidebar-backdrop {
      position: fixed;
      height: 100vh;
      width: 100vw;
      overflow-y: scroll;
      top: 0;
      right: 0;
    }

    .listing-sidebar {
      position: fixed;
      height: 100vh;
      z-index: 100;
      top: 0;
      right: 0;
      background-color: white;
    }
    .listing-header {
      display: flex;
      justify-content: space-between;
      padding: 16px;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }

    gap: 0px !important;

    .close-filters-sm {
      display: flex;
      justify-content: space-between;
    }
    .sidebar-date-filter {
      display: block;
    }
  }

  @media screen and (min-width: 601px) and (max-width: 1280.98px) {
    @-webkit-keyframes sidebarSlideIn {
      0% {
        transform: translateX(35vw);
      }
      100% {
        transform: translateX(0);
      }
    }
    @-moz-keyframes sidebarSlideIn {
      0% {
        transform: translateX(35vw);
      }
      100% {
        transform: translateX(0);
      }
    }
    @keyframes sidebarSlideIn {
      0% {
        transform: translateX(35vw);
      }
      100% {
        transform: translateX(0);
      }
    }

    .listing-sidebar {
      -webkit-animation: sidebarSlideIn 0.2s;
      -moz-animation: sidebarSlideIn 0.2s;
      animation: sidebarSlideIn 0.2s;
      width: 35vw;
    }
  }
  // Small Devices standard is 767.98
  @media screen and (max-width: 600.98px) {
    @-webkit-keyframes sidebarSlideIn {
      0% {
        transform: translateX(100vw);
      }
      100% {
        transform: translateX(0);
      }
    }
    @-moz-keyframes sidebarSlideIn {
      0% {
        transform: translateX(100vw);
      }
      100% {
        transform: translateX(0);
      }
    }
    @keyframes sidebarSlideIn {
      0% {
        transform: translateX(100vw);
      }
      100% {
        transform: translateX(0);
      }
    }

    .listing-sidebar {
      -webkit-animation: sidebarSlideIn 0.2s;
      -moz-animation: sidebarSlideIn 0.2s;
      animation: sidebarSlideIn 0.2s;
      width: 100vw;
    }

    .sidebar-date-picker {
      margin-left: 12px !important;
    }
  }
}

.view-more-btn {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 25px;
  margin-bottom: 10px;
  cursor: pointer;
  font-weight: bold;
  color: $breeze-color-primary;
  align-items: center;
  span {
    margin-left: 5px;
  }
}

.floating-wrapper {
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 12px;
  justify-content: end;
  bottom: 70px;
  padding: 0 50px;
  z-index: 50;

  .floating-container {
    max-width: 1400px;
    position: absolute;
    transition: all 0.2s;
    -webkit-transition: all 0.5s;

    .floating-backdrop.hidden {
      opacity: 0;
      pointer-events: none;
      transition: all 0.2s;
      -webkit-transition: all 0.5s;
    }

    .floating-backdrop {
      position: fixed;
      height: 100vh;
      width: 100vw;
      opacity: 1;
      background: rgba(0, 0, 0, 0.3);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      transition: all 0.2s;
      -webkit-transition: all 0.5s;
    }

    .floating-scroll-to-top {
      float: right;

      padding: 12px;
      gap: 10px;
      width: 56px;
      height: 56px;
      background: #02b8f6;
      border: 1px solid #02b8f6;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
      border-radius: 100px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .floating-qr {
      display: flex;
      align-items: end;
      flex-direction: column;

      .button-close {
        position: absolute;
        top: -5px;
        z-index: 100;
        left: -12px;
        width: 12px;
        display: none;
      }

      .button-close.expanded {
        display: block;
      }

      .floating-qr-list {
        width: 0;
        max-height: 0;
        overflow-y: scroll;
        overflow-x: hidden;
        transition: all 0.5s;
        -webkit-transition: all 0.5s;
        margin: 12px 0;
        padding: 0;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);

        .event-item {
          padding: 12px 24px;
          cursor: pointer;
        }

        .event-item:hover {
          background-color: #d9d9d9;
        }
      }
  
      .floating-qr-list.expanded {
        width: 300px;
        max-height: 246px;
        background-color: white;
        overflow-y: scroll;
        overflow-x: hidden;
      }
  
      .floating-qr-scan {
        padding: 12px;
        gap: 10px;
        width: 56px;
        height: 56px;
        background: #02b8f6;
        border: 1px solid #02b8f6;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
        border-radius: 16px;
        color: white;
        display: flex;
        white-space: nowrap;
        overflow: hidden;
        align-items: center;
        justify-content: center;
        transition: width 0.5s;
        -webkit-transition: width 0.5s;
        cursor: pointer;
  
        .floating-label {
          display: none;
          font-family: Roboto;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0em;
          text-align: left;
        }
      }
  
      .floating-qr-scan.expanded {
        width: 183px;
  
        .floating-label {
          display: block;
        }
      }
    }
  }
  @media (max-width: 767px) {
    padding: 0 16px;
    bottom: 40px;
  }
}

.for-fix-widget {
  height: calc(100vh - 110px);
  // height: calc(100vh - 370px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 20px;
  scroll-behavior: smooth;
  @media (max-width: 991.98px) {
    height: auto;
    overflow-y: auto;
  }
}

.site-wide-announcement {
  height: 72px;
  width: 100%;
  background-color: #ffc53d;
  display: flex;
  // top: 0;
  z-index: 11;
  position: fixed;
  top: 0;
  .announcement-container {
    width: 1400px;
    margin: 0 auto;
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: space-between;
    align-items: center;
    p {
      margin: 0;
      font-weight: 400;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
    }
    .announcement-close{
      border: none;
      background-color: transparent;
    }
  }
}

.site-wide-announcement-contact {
  height: 52px;
  width: 100%;
  background-color: #EBFAFF;
  border: 1px solid #B6ECFF;
  display: flex;
  // top: 0;
  z-index: 100;
  // z-index: 11;
  position: fixed;
  top: 0;

  .announcement-container {
    width: 1400px;
    margin: 0 auto;
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: space-between;
    align-items: center;

    .banner-btns{
      @media (min-width: 701px) {
        display: flex;
      }
      @media (max-width: 700px) {
        display: none;
      }
    }
    .banner-btns-2{
      @media (min-width: 701px) {
        display: none;
      }
      @media (max-width: 700px) {
        display: flex;
      }
    }

    p {
      margin: 0;
      font-weight: 400;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
    }
    .announcement-close{
      border: none;
      background-color: transparent;
    }
  }
}

.qr {
  background-color: transparent;
  width: 50vh;
  height: 50vh;
  perspective: 1000px;
}

.qr-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;

  .qr-back {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      @media screen and (max-height: 900px) {
        font-size: 16px;
      }

      @media screen and (max-width: 430px) {
        font-size: 12px;
      }

      @media screen and (max-width: 260px) {
        font-size: 10px;
      }
    }

    .avatar-row {
      width: 100%;
      height: 100%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      
      .circle-avatar {
        @media screen and (max-height: 900px) {
          transform: scale(.5,.5);
        }
  
        @media screen and (max-width: 430px) {
          transform: scale(.5,.5);
        }
  
        @media screen and (max-width: 260px) {
          transform: scale(.25,.25);
        }
      }
    }

    
  }

  .qr-front {
    display: flex;
    flex-direction: column;
    justify-content: center;

    
    .qr-row.qr {
      width: 100%;
      height: 100%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      .qr-container {
        width: 32px;
        height: 32px;

        @media screen and (max-height: 900px) {
          transform: scale(.5,.5);
        }

        @media screen and (max-width: 430px) {
          transform: scale(.5,.5);
        }

        @media screen and (max-width: 260px) {
          transform: scale(.25,.25);
        }

        svg {
          position: absolute;
          top: 50%;
          right: 50%;
          transform: translate(50%, -50%);
        }

        .ant-spin {
          position: absolute;
          top: 50%;
          right: 50%;
          transform: translate(50%, -50%);
        }
      }
    }
  }

  
}

.qr-front, .qr-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.qr-back {
  transform: rotateY(180deg);
}

.scanner-wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1000;
  display: flex;
  visibility: hidden;

  .scanner-container {
    position: relative;
    width: 400px;
    padding: 0;
    background: white;
    opacity: 0;
    visibility: hidden;
    text-align: center;
    transition: opacity 0.5s;
    z-index: 1500;

    h1 {
      padding: 0;
      margin: 0;
    }

    .scanner-button-container {
      position: absolute;
      pointer-events: auto;
      bottom: -48px;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
    }

    .scanner {
      width: 380px;

      @media (max-width: 767px) {
        width: 180px;
      }

      @media (max-width: 1280.98px) {
        width: 280px;
      }
    }
  }

  .scanner-container.expanded {
    position: relative;
    padding: 12px;
    background: white;
    pointer-events: all;
    text-align: center;
    opacity: 1;
    visibility: visible;

    @media (max-width: 767px) {
      width: 200px;
    }

    @media (max-width: 1280.98px) {
      width: 300px;
    }

    
  }
}

.scanner-wrapper.expanded {
  visibility: visible;
}