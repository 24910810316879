.modal-user-agreement {
    .ant-modal-content {
        border-radius: 8px !important;
    }
    .ant-modal-close-x{
        display: none;
    }

    .logo{
        height: 40px !important;
    }

    .inner-container{
        margin: 20px 0px;
        padding: 20px;
        box-shadow: 0px 2px 8px 0px #00000026;

        h2{
            color: #595959;
            display: flex;
            align-items: center;
            margin-bottom: 0px;
            .icon{
                margin-right: 10px;
            }
        }

        .last-updated{
            font-size: 14px;
            color: #8c8c8c;
            margin-left: 40px;
        }

        .agreement{
            font-size: 16px;
            line-height: 32px;
            color: #8c8c8c;

            a{
                text-decoration: underline;
                color: #0098EE;
                cursor: pointer;
            }
        }
    }

    .checkbox{
        font-size: 16px;

        .link{
            text-decoration: underline;
            color: #0098EE;
            cursor: pointer;
        }
    }
    .ant-modal-footer {
      display: none;
    }
    .button-row {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        button{
            width: 49%;
            height: 40px;
            font-size: 16px;
            border-radius: 4px;
        }
    }
  }
  