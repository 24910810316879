$wrapper-max-width: 1400px;
@import "./dashboard.scss";
@import "./school-admin.scss";
@import "./profile.scss";
@import "./interest.scss";
@import "./event.scss";
@import "./participant-widget.scss";
@import "./softskill-distribution-widget.scss";
@import "./pivot.scss";
@import "./promotions.scss";
@import "./club-links.scss";
@import "../member_pages/groups.scss";
@import "../member_pages/layout.scss";

.AppMainLayout {
  height: auto;

  .app-main-header {
    background-color: #FFF;
    .app-main-header-nav {
      background-color: #FFF;

      .ant-menu-item::after {
        content: none;
      }
      .ant-menu-item {
        border: none !important;
      }

      .ant-menu-item-selected {
        border: none;
      }
      .ant-menu-item-selected::after {
        border: none;
      }
      .ant-menu-item:hover::after {
        border: none;
      }

      .user-container {
        display: inline-block;
        img {
          border-radius: 50%;
          object-fit: contain;
        }
        h5 {
          display: inline-block;
          font-size: 14px;
          // color: #ffffff;
          font-weight: 400;
          margin: 0;
          line-height: 14px;
        }
      }
    }
  }

  .ant-layout-sider {
    background: #ffffff;
  }
  .app-main-menu {
    background: #ffffff;
    display: flex;
    flex-direction: column;
    height: calc(100vh - (64px + 63px));

    ul {
      li {
        color: #000000d9;
      }
    }
    .ant-menu-item-selected{
      background-color: #FFF;
      // border: 0px !important;
      color: #02B8F6 !important;
      a{
        color: #02B8F6 !important;
      }
    }
    .ant-menu-item-selected::after{
      border: 0px !important;
    }
    .ant-menu-item:hover a{
      color: #02B8F6 !important;
    }
    .ant-menu-item::selection{
      color: #000 !important;
      // border-right: 1px solid #000!important;
      a{
        color: #8c8c8c !important;
      }
    }
  }
  .ant-menu-inline.ant-menu-sub {
    background: #ffffff;
    .ant-menu-item-selected {
      background: #e6f7ff;
      color: $breeze-color-blue-1;
    }
  }
  .ant-menu-submenu-open {
    color: $breeze-color-blue-1 !important;
  }
  .ant-menu-item-active {
    // color: $breeze-color-blue-1;
    color: #02B8F6;
  }
  .ant-menu-submenu-title {
    color: #000000d9;
  }
  .ant-menu-submenu-title:hover {
    color: $breeze-color-blue-1;
  }
  .ant-menu-item:hover {
    color: #02B8F6;
  }
  .ant-menu-submenu-selected {
    .ant-menu-submenu-title {
      color: $breeze-color-blue-1;
    }
  }
  .new-menu-item{
    background-color: #fff;
  }

  // content related
  .content-header {
    padding: 16px;
    background: #ffffff;
  }

  .app-main-content-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    .app-main-content {
      width: 100%;
      padding: 0px 24px;
      flex-grow: 1;
      margin: 25px 0;
      overflow-y: scroll;

      position: relative;
      .app-main-content-inner {
        height: auto;
        background: white;
        padding: 20px 24px;
        .for-fix-widget {
          height: calc(100vh - 230px);
          overflow-y: scroll;
          overflow-x: hidden;
          padding-right: 20px;
          scroll-behavior: smooth;
        }
        .content-container {
          .content-title-container {
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .content-title {
              font-size: 16px;
              font-weight: 500;
              margin: 0;
            }
            .title-button {
              cursor: pointer;
              display: flex;
              font-size: 14px;
              align-items: center;
              justify-content: center;

              .cancel-icon {
                margin-top: 2px;
              }
            }
          }
          .tab-filter {
            flex-wrap: wrap;
            margin-bottom: 10px;
            .tab-item {
              border-radius: 4px;
              padding-left: 10px;
              padding-right: 10px;
              text-align: center;
              background-color: #FAFAFA;
              height: 24px;
              font-weight: 400;
              font-size: 14px;
              line-height: 24px;
              cursor: pointer;
              padding-bottom: 15px;
              margin-right: 16px;
              @media (max-width: 576px) {
                margin-bottom: 10px;
              }
            }
            .tab-active {
              color: #FFF;
              border-radius: 4px;
              padding-left: 10px;
              padding-right: 10px;
              background-color: $breeze-color-primary;
            }
            .disabled{
              color:#d9d9d9;
              background-color: #f5f5f5;
              pointer-events: none;
            }
          }
          }
      }
    }
  }
}

#component-layout-sidebar-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#component-layout-sidebar-custom-trigger .trigger:hover {
  color: $breeze-color-blue-1;
}

#component-layout-sidebar-custom-trigger .logo {
  height: 63px;
  margin: 0;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #ededed;
  // background-color: #123;
  // background: $breeze-color-blue-1;

  img {
    width: auto;
    object-fit: cover;
  }
}

.sidebar-footer {
  position: absolute;
  bottom: 0;
  left: 0;

  @media (max-width: 575.98px) {
    text-align: center;
    width: 100%;
  }
}

//sitewide
.section-title {
  font-weight: 700;
  font-size: 20px;
  margin: 0;
}

.page-title {
  font-weight: 500;
  font-size: 24px;
}

.page-header-2 {
  width: 100%;
}

.links-dropdown-list {
  div {
    padding: 5px 12px;
  }
  a {
    color: black;
  }
}

.admin-notification {
  .content-wrapper {
    background: #f0f2f5;
  }
  .page-header-wrapper {
    position: relative;
    top: 0;
  }
}

.modal-char-limit {
  justify-content: end;
  display: flex;
  margin-top: 5px;
}

//user container
.user-container {
  display: inline-block;
  img {
    border-radius: 50%;
    object-fit: contain;
  }
}

@import "./flutter.scss";