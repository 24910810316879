.not-found{
    width: 100%;
    height: 100%;

    .page-header{
        height: 60px;
        width: 100%;
        background-color: #FFF;
        border: none;
        .icon{
            height: 45px;
            cursor: pointer;
        }
    }
    .content{
        width: 100%;
        height: calc(100% - 168px);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    
        img{
            max-width: 500px;
            width: calc(100vw - 100px);
        }
    
        h1{
            color: #d9d9d9;
            font-size: 50px;
            @media (max-width: 992px) {
                font-size: 35px;
            }
        }
    }
    .not-found-footer{
        height: 48px;
        padding: 10px 50px;
        bottom: 0;
        width: 100%;
        background-color: #d9d9d9 !important;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        
            .footer-text {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            margin: 0;
            color: rgba(0, 0, 0, 0.45);
            }
            .footer-links {
                a {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 22px;
                    color: rgba(0, 0, 0, 0.45);
                }
                a::after {
                    content: "|";
                    margin-left: 3px;
                    margin-right: 3px;
                }
                a:last-child:after {
                    content: "";
                }
            }
        }
}

.not-found-member-route{
    width: 100%;
    height: 100%;

    .content{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    
        img{
            max-width: 500px;
            width: calc(100vw - 100px);
        }
    
        h1{
            color: #d9d9d9;
            font-size: 50px;
            @media (max-width: 992px) {
                font-size: 35px;
            }
        }
    }
}