@import "../colors.scss";

.club-propose-wizard {
  background: unset;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0 !important;
  }

  main {
    margin-top: unset;
    background: unset;
    position: absolute;
    top: 64px;
    left: 0;
    right: 0;
    bottom: 80px;
    overflow-y: auto;
    .club-carousel {
      .wizard-page-container {
        height: calc(100vh - (72px + 80px));
        max-width: 608px;
        margin: auto;
        padding: 0 12px;
        display: block !important;

        .form-item-mb-14 {
          margin-bottom: 14px;
        }
        h2 {
          margin-bottom: 48px;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 500;
          font-size: 30px;
          line-height: 40px;
          color: rgba(0, 0, 0, 0.85);
        }

        .ant-form-item-label label,
        .form-label {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: rgba(0, 0, 0, 0.85);
        }

        .calendar-container {
          border: 1px solid #f0f0f0;
        }

        .select-item-row {
          display: flex;
          flex-direction: column;
          gap: 16px;

          .ant-radio-button-wrapper:not(:first-child)::before {
            background-color: unset;
          }

          label {
            height: 44px;
            line-height: 42px;
            background-color: #fafafa;
            border-color: #fafafa;

            &.ant-radio-button-wrapper-checked {
              background-color: $breeze-color-main !important;
              border-color: $breeze-color-main;
            }
          }
        }

        .select-time-options {
          overflow-y: hidden;
          -webkit-overflow-scrolling: touch;
          width: 100%;

          .react-horizontal-scrolling-menu--scroll-container {
            gap: 6px;

            &::-webkit-scrollbar {
              display: none;
            }
          }

          .ant-radio-button-wrapper:not(:first-child)::before {
            background-color: unset;
          }

          label {
            border-radius: 5px;
            border-left-width: 1px;
            white-space: nowrap;
          }
        }

        .select-duration-option {
          display: flex;
          gap: 8px;

          .ant-radio-button-wrapper:not(:first-child)::before {
            background-color: unset;
          }

          label {
            flex-grow: 1;
            text-align: center;
            border-radius: 5px;
            border-left-width: 1px;
          }
        }

        .form-item-small {
          .ant-form-item-label {
            padding: 0;

            > label {
              height: unset;
            }
          }
        }

        .sel-interest {
          background: #fafafa;
          box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
          border-radius: 10px;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 28px;
          color: rgba(0, 0, 0, 0.85);
          padding: 16px;
          margin-bottom: 24px;
        }

        .ant-select-selection-overflow {
          div {
            display: none;
          }

          :last-child {
            display: block;
          }
        }

        .form-softskill {
          background: #fafafa;
          border-radius: 10px;
          padding: 16px;
          margin: 16px 0 0;
          gap: 16px;

          .ant-form-item-label {
            text-align: left;
          }

          .ant-form-item-control-input-content {
            text-align: right;
          }

          .ant-select-selector {
            text-align: left;
          }

          .div-total {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            text-align: right;
            color: rgba(0, 0, 0, 0.85);
            border-top: 1px solid rgba(0, 0, 0, 0.03);
            padding-top: 16px;
          }
        }
        .proposal-success-frame {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          h1 {
            font-style: normal;
            font-weight: 500;
            font-size: 38px;
            line-height: 46px;
            margin-top: 20px;
            color: #169f5b;
          }
          h4 {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            color: rgba(0, 0, 0, 0.45);
          }
        }
      }

      @media (max-width: 576px) {
        .wizard-page-container {
          .proposal-success-frame {
            img {
              height: 250px;
            }
            h1 {
              text-align: center;
            }
          }
        }
      }
    }
    .expanded {
      .wizard-page-container {
        max-width: 780px;
        height: 80vh;
      }
    }
  }

  .form-horizontal-container {
    height: 68vh;
    overflow-y: scroll;
  }

  .select-item-selected-container-designed {
    margin-top: 35px;
    max-height: 420px;
    overflow-y: auto;
    display: flex;
    gap: 16px;
    flex-direction: column;

    .selected-item-container {
      background-color: white;
      border: 1px solid #d9d9d9;
      border-radius: 5px;
      padding: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .selected-item-container:hover {
      border-color: #1890ff;
    }
  }

  footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 24px 0;
    background: #fff;
    box-shadow: inset 0px 1px 0px #f0f0f0;

    button {
      margin: auto;
      display: block;
    }
  }
}
