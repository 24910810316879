.btn-breeze {
  background-color: $breeze-color-main;
  color: $breeze-btn-text;
  border-radius: 15px;
}

.btn-breeze-box {
  // background-color: $breeze-color-main;
  color: $breeze-btn-text;
}

.btn-breeze-2 {
  background-color: $breeze-color-main;
  color: $breeze-btn-text;
  border-radius: 15px;
  padding: 4px 30px;
}

.btn-breeze-box-radius {
  background-color: $breeze-color-blue-2;
  color: $breeze-btn-text-white;
  border-radius: 5px;
  height: 40px;
}

.btn-breeze-box-radius-white {
  border-radius: 5px;
  height: 40px;
}

.btn-breeze-no-bg {
  border: none;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
  color: #0ec1fe;
  box-shadow: none;
}

.btn-breeze-primary {
  height: 40px;
  border-radius: 4px;
}

.btn-breeze-grayed {
  // border-radius: 4px;
  background-color: #F3F6F9;
}

.btn-breeze-inactive {
  background-color: #f3f6f9;
  height: 40px;
  color: #595959;
  border-radius: 4px;
}
.btn-outlined {
  border-color: #02b8f6;
  background-color: white;
  color: #02B8F6;
  border-radius: 8px;

}

.btn-active {
  border: 1px solid $breeze-color-blue-2 !important;
  color: $breeze-color-blue-2 !important;
}

.focus-none:focus {
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.85);
}

.no-border {
  background-color: $breeze-color-main;
  color: $breeze-btn-text;
  border-radius: 15px;
}

.btn-no-radius {
  border-radius: 0;
}

.breeze-color-blue-1 {
  color: $breeze-color-blue-2;
}

.btn-regular {
  height: 40px;
  padding: 8px 24px;
}

// override buttons
.btn-breeze-rad {
  border-radius: 5px;
  height: 40px;
}

.ant-btn-primary {
  border-color: $breeze-color-primary;
  background: $breeze-color-primary;
}

.btn-radius {
  border-radius: 20px;
}

.ant-btn-icon-only {
  width: 40px;
  vertical-align: -4px;
}
.btn-joined {
  color: #52c41a;
}
.color-warning {
  color: #faad14;
}
.color-success {
  color: #52c41a;
}
.color-error {
  color: #ff4d4f;
}

.google-login-btn {
  text-align: center;
  cursor: pointer;
}

// .ant-btn:hover, .ant-btn:focus {

// }
