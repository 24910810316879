.onboarding-wrapper {
  padding: $margin-sides-space;
  padding-top: 80px;
  padding-bottom: 80px;
  @media (max-width: 767px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .title {
    font-weight: 500;
    font-size: 30px;
    line-height: 40px;
    text-align: center;
  }
  .sub-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
  .onboarding-footer {
    padding-top: 0px;
    margin: 0 auto;
    // background-color: aqua;

    .text-desc {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
    .text-action {
      cursor: pointer;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #02b8f6;
    }
    button {
      display: block;
      font-size: 20px;
      height: 40px;
      width: 140px;
      margin: 30px auto 0 auto;
    }
  }
}

//setting it as global to be reused to other components
.interest-selection {
  margin-top: 20px;
  max-width: 940px;
  max-height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 10px;

  // @media (max-width: 767px) {
  //   height: 100%;
  //   // overflow: initial;
  // }

  .ant-col {
    margin-bottom: 16px;
  }
  .interest-item {
    height: 100%;
    cursor: pointer;
    width: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    display: flex;
    // flex-direction: column;
    padding: 40px 60px;
    align-items: center;

    h5 {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin: 0;
      text-align: center;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }
    .checkmark-icon {
      position: absolute;
      right: 25px;
      top: 15px;
    }
  }
  .interest-active {
    border: 1px solid $breeze-color-main;
  }

  // #skeleton
  .ant-skeleton-title {
    width: 100% !important;
    margin: 0;
  }
  .ant-skeleton-paragraph {
    display: none;
  }
  .ant-skeleton-image {
    height: 64px;
    width: 64px;
    margin-bottom: 10px;
  }
}
