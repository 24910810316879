.club-list {
  .list-controls {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    .ant-input-search-button {
      height: 40px;
    }
    .ant-input-affix-wrapper {
      height: 40px;
    }
    .ant-input-affix-wrapper {
      border-top-left-radius: 5px !important;
      border-bottom-left-radius: 5px !important;
    }
    .ant-input-group-addon {
      .ant-btn {
        border-top-right-radius: 5px !important;
        border-bottom-right-radius: 5px !important;
      }
    }
    .list-filters {
      display: flex;
      .ant-icon {
        color: #595959;
      }
      button {
        margin-left: 5px;
        height: 40px;
      }

      .filter-desktop {
        display: block;
      }
      .filter-mobile {
        display: none;
      }
    }
  }
  .my-club-collapse {
    background-color: transparent;
    .ant-collapse-item {
      border: none;
      padding-bottom: 16px;
    }
    .ant-collapse-header {
      padding: 0;
    }
    .ant-collapse-header-text {
      width: 100%;
    }
    .ant-collapse-content-box {
      padding: 0;
      margin-top: 16px;
    }
    .ant-collapse-item-active {
      padding-bottom: 0;
    }
    .collapse-trigger {
      display: flex;
      align-items: center;
      span {
        margin-top: -2px;
        margin-left: 8px;
        font-size: 16px;
        color: #02b8f6;
      }
    }
  }

  .club-item-grid-view {
    height: 100%;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 5px;
    background-color: white;
    display: flex;
    flex-direction: column;
    position: relative;

    .custom-skeleton {
      .ant-skeleton-content {
        padding: 24px;
      }
    }

    .club-item-image {
      width: 100%;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      position: relative;

      .badge-status {
        position: absolute;
        right: 16px;
        top: 16px;
        span {
          padding: 4px 10px;
          border-radius: 15px;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
        }
        .color-warning {
          background: #e6f7ff;
          color: rgba(0, 0, 0, 0.85);
        }
        .color-success {
          background: #d9f7be;
        }
        .color-error {
          background: #ff4d4f;
          color: white;
        }
      }

      .ant-skeleton-image {
        width: 100%;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        object-fit: cover;
        height: 170px;
      }

      img {
        width: 100%;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        object-fit: cover;
      }
    }

    .club-item-content {
      //   height: 100%;
      position: relative;
      padding: 16px;
      padding-bottom: 0;
      //   display: flex;
      //   flex-direction: column;
      //   height: 100%;

      .club-item-controls {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 16px;
      }

      .club-item-title {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        margin: 0;
      }
      .item-menu {
        padding: 10px;
        // margin-top: -5px;
        margin-right: -10px;
      }
      .club-item-description {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.45);
        margin: 0;
        word-break: break-all;
      }
      .club-item-interest {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.45);
        margin: 0;
      }
    }
    .club-item-controls {
      padding: 0 16px 16px 16px;
      display: flex;
      justify-content: space-between;
      height: 100%;
      align-items: flex-end;
      .club-members-avatar {
        display: flex;
        div {
          border: 2px solid white;
          margin-left: -6px;
        }
        div:first-child {
          margin-left: 0;
        }
      }
    }
  }
  .view-active {
    border: none;
    color: white;
    background: #02b8f6;
  }
  .club-item-list-view {
    // height: 100%;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 5px;
    background-color: white;
    display: flex;

    @media (max-width: 576px) {
      flex-direction: column;
      .club-item-image {
        width: 100% !important;

        img {
          width: 100% !important;
        }
      }
    }

    .custom-skeleton {
      .ant-skeleton-content {
        padding: 16px;
      }
    }

    .club-item-image {
      width: 257px;
      position: relative;
      // border-top-left-radius: 5px;
      // border-top-right-radius: 5px;

      .badge-status {
        position: absolute;
        left: 16px;
        top: 16px;
        span {
          padding: 4px 10px;
          border-radius: 15px;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
        }
        .color-warning {
          background: #e6f7ff;
          color: rgba(0, 0, 0, 0.85);
        }
        .color-success {
          background: #d9f7be;
        }
        .color-error {
          background: #ff4d4f;
          color: white;
        }
      }

      .ant-skeleton-image {
        width: 257px;
        border-radius: 0;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        object-fit: cover;
        height: 170px;
      }
      img {
        width: 257px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        object-fit: cover;
      }
    }
    .club-item-content {
      justify-content: space-between;
      display: flex;
      width: 100%;
      padding: 16px;

      .club-content-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-between;

        .ant-btn-icon-only {
          height: 40px;
        }
      }

      .club-item-title {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        margin: 0;
      }
      .item-menu {
        padding: 10px;
        // margin-top: -5px;
        // margin-right: -10px;
      }
      .club-item-description {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.45);
        margin: 0;
        word-break: break-all;
      }
      .club-item-interest {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.45);
        margin: 0;
      }

      .right-controls {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
        align-items: center;

        .club-item-interest {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: rgba(0, 0, 0, 0.45);
          margin: 0;
        }
        .ant-btn-icon-only {
          height: 40px;
          // border: 1px solid #d9d9d9;
          // box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
          // border-radius: 100px;
        }
      }

      .club-members-avatar {
        display: flex;
        margin-top: auto;
        div {
          border: 2px solid white;
          margin-left: -6px;
        }
        div:first-child {
          margin-left: 0;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .list-controls {
      .list-filters {
        .filter-desktop {
          display: none;
        }
        .filter-mobile {
          display: block;
        }
      }
    }
  }
}

.group-page-single-content-wrapper {
  margin-top: 25px;
  padding: 0 50px;
  .admin-group-page-single-content-override {
    max-width: 100% !important;
  }
  .group-page-single-content {
    max-width: $wrapper-max-width;
    margin: 0 auto;
    display: block;
    margin-top: 30px;

    .content-left {
      height: 100%;
      padding: 24px;
      background: #ffffff;
      border-radius: 5px;

      .about {
        h4 {
          font-weight: 500;
          font-size: 20px;
          line-height: 28px;
          margin-bottom: 20px;
        }

        h5 {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        }

        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 50px;
        }
      }
    }
    .content-right {
      padding: 24px;
      background: #ffffff;
      border-radius: 5px;
      height: 100%;
      .normal-widget {
        h4 {
          margin-bottom: 10px;
          font-size: 20px;
        }

        img {
          margin: 0 10px 0 0;
        }
        h5 {
          margin: 0;
          font-size: 16px;
        }
      }
      .ant-divider-horizontal {
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .club-members-avatar {
        display: flex;
        margin-top: auto;
        div {
          border: 2px solid white;
          margin-left: -6px;
        }
        div:first-child {
          margin-left: 0;
        }
      }
    }
  }
  .group-page-edit-content {
    display: flex;
    justify-content: center;
    max-width: $wrapper-max-width;
    margin: 0 auto;
    background-color: white;
    border-radius: 10px;
    padding-top: 25px;
    padding-bottom: 25px;

    .banner-img-preview {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 301px;
      border-radius: 8px;
      background-repeat: no-repeat;
      background-size: cover;
      margin-top: 5px;
      .ant-upload-list {
        display: none;
      }
    }
  }

  @media (max-width: 576px) {
    padding: 0 16px;
    .list-controls {
      flex-wrap: nowrap;
    }
  }
}
