@import "../colors.scss";

.event-calendar-title {
  margin: 0;
  background: white;
  padding: 24px 24px 0;
}
.rbc-calendar {
  // height: 100vh;
  height: calc(100vh - 190px);
  background: white;
  padding: 24px;
  padding-bottom: 10px;
  @media (max-width: 992px) {
    height: calc(100vh - 165px);
    background-color: #FFF !important;
  }
  @media (max-width: 992px) {
    height: calc(100vh - 165px);
    background-color: #FFF !important;
  }
  @media (max-width: 500px) {
    height: calc(100vh - 105px);
    background-color: #FFF !important;
  }

  .rbc-row.rbc-time-header-cell {
    padding-right: 0px;

    .rbc-header.rbc-today{
      h1{
        color: #0098EE !important;
      }
      h4{
        color: #0098EE !important;
      }
    }
  }

  .rbc-time-content {
    padding-right: 1px;

    .rbc-time-column {
      .rbc-timeslot-group{
        height: 100px !important;
        flex: none !important;
    }
    }
  }

  @media screen and (max-width: 962px) {
    .rbc-time-view {
      overflow: scroll;
  
      .rbc-time-header {
        min-width: 962px;
  
        .rbc-row.rbc-time-header-cell {
          padding-right: 4px;
        }
        .rbc-row-bg {
          height: 1300px !important;
          padding-right: 4px;
        }
      }
  
      .rbc-time-content {
        min-width: 962px;
      }
    }

    
  }

  @media (max-width: 992px) {
    .rbc-time-view {
      overflow: hidden;
  
      .rbc-time-header {
        // min-width: 962px;
        min-width: 100%;
  
        .rbc-row.rbc-time-header-cell {
          padding-right: 5px;
        }

        .rbc-row-bg {
          padding-right: 5px;
          @media screen and (max-width: 576px) {
            padding-right: 0px;
          }
        }
      }
  
      .rbc-time-content {
        min-width: 100%;
        // min-width: 962px;
        padding-right: 1px;
      }
    }
  } 
  
  @media (max-width: 992px) {
    padding: 0px;
    background: white;
  }
}
.rbc-label {
  width: 70px;
}

.rbc-event {
  background-color: $breeze-color-blue-1;
  @media (max-width: 992px) {
    padding: 0px 2px !important;
  }
}
.rbc-events-container {
  margin-right: 0px !important;
}

.rbc-toolbar {

  .rbc-toolbar-label {

    @media (max-width: 614px) {
      margin-bottom: 12px;
    }

    margin-left: 10px;
    text-align: left;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);

  }
}

.rbc-btn-group {

  @media (max-width: 614px) {
    margin-bottom: 12px;
  }

  button {
    background: transparent !important;
    box-shadow: none !important;
    border: 1px solid #d9d9d9;
    margin: 0;
  }
  .rbc-active {
    color: #02b8f6 !important;
    border: 1px solid #02b8f6 !important;
  }
}
.rbc-month-view {
  border: 0 !important;
  background-color: #FFF;
  @media (max-width: 992px) {
    padding: 0px 15px;
  }

  .rbc-header {
    padding: 14px;
    text-align: right;
    font-weight: bold;
    border: 0 !important;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    @media (max-width: 992px) {
      font-size: 12px;
    }
  }

  .rbc-month-row {
    border: 0 !important;
    @media (max-width: 992px) {
      background-color: #f0f2f5;
      // border: 1px solid #f0f2f5;
    }
  }

  .rbc-row-segment {
    margin-bottom: 4px;
    margin-left: 1px;

    @media (max-width: 992px) {
      margin: 0px;
    }
  }

  .rbc-off-range-bg {
    background: transparent;
  }

  .rbc-day-bg {
    border-left: 0 !important;
    box-shadow: inset 0px 2px 0px #f0f0f0;
    margin-left: 5px;
    margin-left: 5px;
    @media (max-width: 992px) {
      background-color: #FFF;
      margin: 1px;
    }
  }

  .rbc-today {
    box-shadow: inset 0px 2px 0px #1890ff;
    @media (max-width: 992px) {
      box-shadow: none;
      border: 1px solid #02B8F6 !important;
      background-color: #EBFAFF;
    }
  }

  .rbc-row-content {
    pointer-events: none;
    .rbc-row {
      pointer-events: none;
      .rbc-now{
        @media (max-width: 992px) {
          color: #02B8F6;
        }
      }
      .rbc-button-link.rbc-show-more{
        pointer-events: all;
      }
      .rbc-event-content{
        pointer-events: all;
      }
      .rbc-date-cell {
        // pointer-events: none;
        padding-top: 5px;

        button{
          pointer-events: all;
        }
        
      }
    }
  }
}

.rbc-current-time-indicator {
  position: absolute;
  z-index: 1;
  left: 0;
  height: 2px;

  background-color: #0098EE;
  pointer-events: none;

  &::before {
    display: block;

    position: absolute;
    left: -3px;
    top: -3px;

    content: " ";
    background-color: #0098EE;

    border-radius: 50%;
    width: 8px;
    height: 8px;
  }

  .rbc-rtl &::before {
    left: 0;
    right: -3px;
  }
}

.mobile-day{
  @media (max-width: 992px) {
    position: absolute;
    top: 0px;
    width: 100%;
    align-items: center;
    padding: 10px 15px;
    margin-bottom: 0px;
  }
  @media (max-width: 500px) {
    height: 60px;
    position: absolute;
    top: 0px;
    width: 100%;
    align-items: center;
    padding: 10px 15px;
    margin-bottom: 0px;
  }
}

.calendarToolbar{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;
  @media (max-width: 992px) {
    align-items: center;
    background-color: #FFF;
    padding: 10px 15px;
    margin-bottom: 0px;
  }
  .top-additional{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
    h1{
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 18px;
      color: #595959;
      margin-bottom: 0px;
      .circle-btn{
        width: 40px;
        height: 40px;
        border-radius: 50px;
        border: 1px solid #EBFAFF;
        background-color: #EBFAFF;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        img{
          height: 24px;
          margin-right: 0px;
        }
      }
    }
    img{
      height: 24px;
      margin-right: 5px;
    }
    .sync-btn{
      color: #02B8F6;
      border-radius: 4px;
      border: 1px solid #02B8F6;
    }
    
  }
  
  .main{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
    @media (max-width: 992px) {
      width: 100%;
      align-items: center;
      background-color: #FFF;
      padding: 10px 15px;
      margin-bottom: 0px;
    }
  .left-side{
    width: 100%;
    display: flex;
    flex-direction: row;
    
    @media (max-width: 992px) {
      flex-direction: column;
    }

    .nav-btn{
      display: flex;
      flex-direction: row;
      @media (max-width: 992px) {
        display: none;
      }

      Button{
        padding: 10px;
        display: flex;
        font-size: 12px;
        margin-right: 16px;
        border-radius: 2px;
        align-items: center;
        justify-content: center;
      }
    }

    .nav-btn2{
      display: flex;
      flex-direction: row;
      Button{
        display: none;
        @media (max-width: 992px) {
          display: flex;
        }
        padding: 10px;
        font-size: 12px;
        border-radius: 2px;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
      }
    }

    .date-small-screen{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .mini-calendar-btn{
      border: none;
      padding: 10px;
      display: flex;
      font-size: 12px;
      margin-right: 16px;
      align-items: center;
      box-shadow: none;
      justify-content: center;
      @media (max-width: 992px) {
        display:none;
      }
      
      h2{
        padding-top: 3px;
        font-size: 16px;
        @media (max-width: 992px) {
          font-size: 14px;
        }

        .ant-spin-nested-loading {
          display: inline-block
        }
      }

      @media (max-width: 992px) {
        margin-top: 10px;
        padding: 0px;
      }
    }

    .date-full{
      display: none;
      @media (max-width: 992px) {
        width:100%;
        display:flex;
        justify-content: center;
        align-items: center;
        text-align:center;
        margin-bottom: 0px;
        margin-right: 10px;
        .spin{
          margin-left: 10px;
        }
      }
    }
  }

  .right-side{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 24px;

    @media (max-width: 992px) {
      gap: 12px;
      // flex-direction: column;
    }

    .recommend-btn {
      display: flex;
      padding: 5px 16px;
      color: white;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 4px;
      border: 1px solid var(--primary-6, #02B8F6);
      background: var(--primary-6, #02B8F6);
      box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);


      @media (max-width: 992px) {
        padding: 5px 8px;
        .label {
          display: none;
        }
      }
      
    }
    .allday-toggle{
      display: flex;
      flex-direction: row;
      align-items: center;
      @media (max-width: 992px) {
        display: none;
      }
      p{
        width: 90px;
        font-size: 13px;
        color: #8C8C8C;
        margin-bottom: 0px;
      }
    }
  }

  .dropdown{
    padding: 10px;
    display: flex;
    font-size: 12px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
  }
}

}

.mini-calendar{
  width: 300px;

  .ant-picker-cell-selected .ant-picker-cell-inner{
    background-color: #02B8F6;
  }

  .today-btn{
    width: 100%;
    color: #02B8F6;
    border: none;
    box-shadow: 0 -5px 1px -5px #888;
  }
}

.mini-cal-header{
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}

.currDate-cell{
  width: 100%;
  border-top: 2px solid #1890FF;
  margin-left: 5px;
  margin-right: 5px;
  background-color: #EBFAFF;

}

.date-cell{
  width: 100%;
  border-top: 2px solid #f0f0f0;
  margin-left: 5px;
  margin-right: 5px;

  .overlap-tooltip{
    padding: 4px;
    pointer-events: all;
    z-index: 90;
  }
}


.overlap-alert{
  font-size: 14px;
  color: #FAAD14;
}

.ant-tooltip-inner{
  width: 300px !important;
  border-radius: 5px !important;
}

.custom-tooltip{
  padding: 5px 0px;
  background-color: #F3F6F9;

  .conflict-time{
    // font-size: 14px;
    font-weight: 600;
    color: #595959;
    text-align: left;
    padding: 10px;
  }

  .conflict-list{
    max-height: 300px;
    overflow-y: auto;

    img{
      height: 18px;
      margin-right: 5px;
    }
  }

  .event-details{
    position: relative;
    padding: 8px;
    border-radius: 8px;
    border:1px solid #FFF;
    background-color: #FFF;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .data{
      h4{
        width: 230px;
        max-width: max-content;
        color:#595959;
        margin-bottom: 0px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        
        img{
          height: 15px;
        }
      }
      
      .time{
        display: flex;
        margin-left: 15px;
        flex-direction: row;
        p{
          margin-bottom: 0px;
          color:#595959;
        }
      }
      
      .mutuals{
        display: flex;
        margin-left: 15px;
        flex-direction: row;
        margin-bottom: 0px;
        color:#595959;
        padding-left: 10px;
      }

      .promoted{
        position: absolute;
        right: 15px;
        height: 20px;
        width: 85px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0px 0px 0px 8px;
        border-radius: 50px;
        box-shadow: 0px 2px 8px 0px #00000026;
        background-color: #fff;
        color: #FFA940;

        img{
          width: 20px;
          height: 20px;
          padding: 2px;
          margin-right: 0px;
          border-radius: 50px;
          background-color: #FFA940;
        }
      }
      .highlighted{
        position: absolute;
        right: 5px;
        height: 20px;
        width: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 2px;
        border-radius: 50px;
        box-shadow: 0px 2px 8px 0px #00000026;
        background-color: #EBFAFF;
      }

    }
      
    .add-event-spin{
      position: absolute;
      bottom: 10px;
      right: 10px;
      height: 25px;
      width: 25px;
      padding: 0px;
      border: none;
      cursor: pointer;
    }
    .add-event-btn{
      position: relative;
      height: 25px;
      width: 25px;
      padding: 0px;
      font-size: 25px;
      background-color: transparent;
      border: none;
      color: #02B8F6;
    }
  }

  h3{
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: 1px solid #F0F0F0;
  }

  .conflict-h3{
    color:#FA8C16;
  }

  p{
    padding: 5px 10px;
    color: #000;
  }
}

.day-header-cell{
  padding: 5px;
  height: 100px;
  min-width: 1300px;
  text-align: left;

  h1{
    font-weight: 500;
    font-size: 38px;
  }
  h4{
    font-weight: 700;
    font-size: 16px;
  }
  
  @media (max-width: 992px) {
    padding: 5px;
    height: 50px;
    text-align: left;

    h1{
      font-weight: 500;
      font-size: 20px;
    }
    h4{
      font-weight: 700;
      font-size: 12px;
    }
  }
}

.week-header-cell{
  padding: 5px;
  height: 100px;
  min-width: 1300px;
  text-align: left;
  
  h1{
    font-weight: 500;
    font-size: 38px;
  }
  h4{
    font-weight: 700;
    font-size: 16px;
  }
  
  @media (max-width: 576px) {
    padding: 5px;
    height: 50px;
    text-align: left;

    h1{
      font-weight: 500;
      font-size: 16px;
    }
    h4{
      font-weight: 700;
      font-size: 8px;
    }
  }
}

.rbc-time-gutter{
  @media (max-width: 992px) {
    width: 50px;
  }
  .rbc-time-slot.slot {
    @media (max-width: 992px) {
      margin: 0px;
      padding: 0px;
      width: 50px !important;
    }
  }
}

.rbc-label.rbc-time-header-gutter {
  width: 100px !important;
  min-width: 100px !important;
  @media (max-width: 992px) {
    padding: 0px;
    width: 50px !important;
    min-width: 50px !important;
  }
}

.rbc-time-header-cell-single-day{
  width: 100%;
  height: 100px;
  display: flex;
  pointer-events: none;
  @media (max-width: 992px) {
    display: none;
  }
}

.rbc-allday-cell{
  // height: auto !important;
  height: 85px !important;
  overflow-y: hidden;
  width: 100% !important;
  @media (max-width: 992px) {
    height: 85px !important;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    width: 100% !important;
    position: relative;
  }
}
.expand{
  .rbc-allday-cell{
    min-height: 85px !important;
    max-height: calc(100vh - 400px) !important;
    height: auto !important;
    overflow-y: auto;
    width: 100% !important;
    @media (max-width: 992px) {
      // min-height: 80px !important;
      // height: 80px !important;
      height: auto !important;
      background-color: #F3F6F9;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      width: 100% !important;
      position: relative;
    }
  }
}
.allday-none{
  .rbc-allday-cell{
    display: none;
  }
  .time-gutter-header{
      display: none;
  }
}
.allday-one{
  .rbc-allday-cell{
    height: 42px !important;
  }
}
.conflict-label{
  width: 100px;
  color:#fa8c16;
  padding: 2px 5px;
  border-radius: 5px;
  background-color: #fff7e6;
  border: 1px solid #ffd591;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.event-component{
  padding: 10px 5px;
  height: 100%;
  min-height: 70px;
  display: flex;
  flex-direction:column;
  justify-content: space-between;

  .upper{
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    img{
      height: 15px;
      margin-right: 5px;
    }
    .promoIcon{
      width: 16px;
      height: 16px;
      padding: 2px;
      border-radius: 50px;
      background-color: #FFA940;
      position: absolute;
      right: -5px;
    }
    
    .details{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .title{
        width: 130px;
        // max-width: min-content;
        padding: 0px;
        margin: 0px;
        margin-bottom: 10px;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .time{
        font-size: smaller;
        margin-bottom: 0px;
      }
    }
  }

  .lower{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;
    .more{
      color:#02B8F6;
      padding: 0px;
      margin: 0px;
      font-weight: 500;
      font-size: smaller;
    }
    .more-w{
      color:#FFF;
      padding: 0px;
      margin: 0px;
      font-weight: 500;
      font-size: smaller;
    }
    .addIcon{
      z-index: 99;
      font-size: 20px;
      color: #02B8F6;
      cursor: pointer;
    }
  }
}

.multi{
  height: 38px !important;
  display: flex;
  flex-direction:row !important;
  align-items: center !important;

  @media (max-width: 992px) {
    height: 40px !important;
    margin: 2px 0px;
  }

  .upper{
    width: 70%;
    display: flex;
    align-items: center !important;
    .title{
      width: 55%;
      padding: 0px;
      margin: 0px;
      margin-bottom: 0px !important;
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @media (max-width: 992px) {
        width: 100%;
      }
    }
  }
  .lower{
    width: 30%;
  }
}

.event-component-day{
  padding: 10px 5px;
  height: 100%;
  display: flex;
  flex-direction:column;
  justify-content: space-between;

  .upper{
    width: 100%;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    img{
      height: 15px;
      margin-right: 5px;
    }
    .promoIcon{
      width: 16px;
      height: 16px;
      padding: 2px;
      border-radius: 50px;
      background-color: #FFA940;
      position: absolute;
      top: 5px;
      right: 2px;
    }
    .shifted-promo{
      width: 16px;
      height: 16px;
      padding: 2px;
      border-radius: 50px;
      background-color: #FFA940;
      position: absolute;
      top: 2px;
      right: 5px;
    }

    .details{
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      .title{
        width: 65%;
        padding: 0px;
        margin: 0px;
        margin-bottom: 10px;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .time{
        font-size: smaller;
      }
    }
    .multiday{
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      .title{
        width: min-content;
        min-width: 100px;
        padding: 0px;
        margin: 0px;
        margin-bottom: 10px;
        margin-right: 20px;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .time{
        min-width: 175px;
        font-size: smaller;
        margin-bottom: 0px;
      }
    }
  }

  .lower{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;
    .more{
      color:#02B8F6;
      padding: 0px;
      margin: 0px;
      font-weight: 500;
      font-size: smaller;
    }
    .more-w{
      color:#FFF;
      padding: 0px;
      margin: 0px;
      font-weight: 500;
      font-size: smaller;
    }
    .addIcon{
      z-index: 99;
      font-size: 20px;
      color: #02B8F6;
      cursor: pointer;
    }
  }
  .multiday-lower{
    width: min-content;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;
    .more{
      color:#02B8F6;
      padding: 0px;
      margin: 0px;
      font-weight: 500;
      font-size: smaller;
    }
    .more-w{
      color:#FFF;
      padding: 0px;
      margin: 0px;
      font-weight: 500;
      font-size: smaller;
    }
    .addIcon{
      z-index: 99;
      font-size: 20px;
      color: #02B8F6;
      cursor: pointer;
    }
  }
}

.event-component-month{
  padding: 2px 10px;
  display: flex;
  align-items: center;
  position: relative;
  p{
    padding: 0px;
    margin: 0px;
    font-weight: 500;
    width: 100%;
    @media (max-width: 992px) {
      text-align: center;
    }
  }
  @media (max-width: 992px) {
    padding: 2px;
    font-size: 10px;
  }

  img{
    height: 18px;
    margin-right: 5px;
    @media (max-width: 992px) {
      height: 10px;
      margin-right: 2px;
    }
  }
  .promoIcon{
    width: 16px;
    height: 16px;
    padding: 2px;
    border-radius: 50px;
    background-color: #FFA940;
    position: absolute;
    right: 2px;
    @media (max-width: 992px) {
      width: 8px;
      height: 8px;
      padding: 4px;
      top:3px;
      right: 1px;
    }
  }
}

.event-list{
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  margin-bottom: 10px;
  @media (max-width: 992px) {
    // overflow-x: hidden;
    overflow-y: auto;
    // display: unset;
    display: grid;
    gap: 20px 20px;
    padding-bottom: 15px;
    grid-template-columns: 47% 47%;
  }
  @media (max-width: 500px) {
    grid-template-columns: 95%;
  }

  .list-event-item{
    position: relative;
    display: inline-block;
    width: 328px;
    height: 455px;
    padding: 10px;
    margin: 0px 12px;
    background-color: #FFF;
    border-radius: 10px;
    word-wrap: break-word;
    overflow: hidden;
    overflow-y: auto;

    @media (max-width: 992px) {
      width: 100%;
    }

    .time{
      font-size: 12px;
      color: #595959;
      margin-bottom: 10px;
    }

    .promoted{
      top:8px;
      right: 5px;
      height: 20px;
      width: 85px;
      display: flex;
      position: absolute;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0px 0px 0px 8px;
      border-radius: 50px;
      box-shadow: 0px 2px 8px 0px #00000026;
      background-color: #fff;
      color: #FFA940;
      font-size: 12px;

      img{
        width: 20px;
        height: 20px;
        padding: 2px;
        margin-right: 0px;
        border-radius: 50px;
        background-color: #FFA940;
      }
    }
    .highlighted{
      top:8px;
      right: 5px;
      height: 20px;
      width: 20px;
      display: flex;
      position: absolute;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0px;
      border-radius: 50px;
      box-shadow: 0px 2px 8px 0px #00000026;
      background-color: #fff;
      color: #02B8F6;
      font-size: 12px;
      p{
        display: none;
      }
      img{
        width: 20px;
        height: 20px;
        padding: 2px;
        margin-right: 0px;
        border-radius: 50px;
        background-color: #EBFAFF;
      }
    }
    .highlighted:hover{
      top:8px;
      right: 5px;
      height: 20px;
      width: 90px;
      display: flex;
      position: absolute;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0px 0px 0px 8px;
      border-radius: 50px;
      box-shadow: 0px 2px 8px 0px #00000026;
      background-color: #fff;
      color: #02B8F6;
      font-size: 12px;
      p{
        display: flex;
        margin-bottom: 0px;
      }
      img{
        width: 20px;
        height: 20px;
        padding: 2px;
        margin-right: 0px;
        border-radius: 50px;
        background-color: #EBFAFF;
      }
    }

    .main-data{
      display: flex;
      align-items: center;
      flex-direction: row;
      margin-bottom: 10px;
      .img-placeholder{
        width: 40px;
        height: 40px;
        font-size: 20px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        color: #d9d9d9;
        border: 1px solid #d9d9d9;
      }
      .data{
        width: calc(100% - 90px);
        display: flex;
        flex-direction: column;
        .title{
          color: #595959;
          font-size: 16px;
          margin-bottom: 0px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .organizer{
          color: #595959;
          margin-bottom: 0px;
        }
      }
    }

    .image-container{
      position: relative;
      .image-banner {
        width: 100%;
        height: 150px;
        border-radius: 8px;
        background-size: cover;

        // @media (max-width: 992px) {
        //   margin:10px;
        //   width: 80px;
        //   height: 80px;
        //   border-radius: 5px;
        // }
      }
    
      .image-placeholder {
        width: 100%;
        // width: 270px;
        height: 150px;
        border-radius: 8px;
        background-size: cover;
        background-image: url("../../images/breeze-default-card-banner.png");

        // @media (max-width: 992px) {
        //   margin:10px;
        //   min-width: 80px;
        //   width: 80px;
        //   height: 80px;
        //   border-radius: 5px;
        //   background-image: url("../../images/breeze-default-mobile-placeholder.png");
        //   background-size: cover;
        // }
      }

      .img-btns{
        position: absolute;
        width: 30%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        top:12px;
        right: 12px;
        .circle-btn{
          width: 40px;
          height: 40px;
          border-radius: 50px;
          border: 1px solid #EBFAFF;
          background-color: #EBFAFF;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .content{
      height: 195px;
      display: flex;
      padding-top: 12px;
      flex-direction: column;
      justify-content: space-between;
  
      .prompt{
        margin-top: 10px;
        margin-bottom: 0px;
      }
      .prompt-b{
        color: #02B8F6;
        margin-top: 10px;
        margin-bottom: 0px;
      }

      .ant-divider{
        margin-top: 5px;
        margin-bottom: 15px;
      }

      .info-point{
        display: flex;
        flex-direction: row;
        align-items: center;
        b{
          text-wrap: wrap;
        }
        p{
          margin-bottom: 0px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .description{
        overflow: hidden;
        white-space: nowrap;
        height: 50px !important;
        text-overflow: ellipsis;
      }
      .read-more{
        width: 50%;
        padding: 0px;
        height: 20px;
        color: #02B8F6;
        text-align: start;
      }

      .grey-text{
        text-wrap:wrap;
        font-size: 14px;
        color: #8C8C8C;
        margin-bottom: 0px;
      }

      .button-group{
        display: flex;
        flex-direction:row-reverse;
        justify-content: space-between;
        .added-btn{
          height: 40px;
          color: #02B8F6;
          background-color: #EBFAFF !important;
          border: 1px solid #EBFAFF;
          border-radius: 5px !important;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          .loading-spin{
            height: 100%;
            color: inherit;
            margin-left: 5px;
          }
        }
        .event-dropdown{
          display: flex;
          @media (max-width: 992px) {
            display: none !important;
          }
        }
        .event-dropdown-mobile{
          display: none !important;
          @media (max-width: 992px) {
            display: flex !important;
          }
        }
        .add-btn{
          height: 40px;
          color: #FFF;
          background-color: #02B8F6 !important;
          border: 1px solid #02B8F6;
          border-radius: 5px !important;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
          .loading-spin{
            height: 100%;
            color: inherit;
            margin-left: 5px;
          }
        }
        .attendance-btn{
          height: 40px;
          background-color: #FFF !important;
          border-radius: 5px !important;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
        }
      }
    }
  }
}

.event-list::-webkit-scrollbar {
  width: 16px;
  height: 10px !important;
  background-color: transparent;
}

/* background of the scrollbar except button or resizer */
.event-list::-webkit-scrollbar-track {
  background-color: #FFF;
  border-radius: 50px;
}

/* scrollbar itself */
.event-list::-webkit-scrollbar-thumb {
  background-color: #F0F0F0;
  border-radius: 16px;
}

/* set button(top and bottom of the scrollbar) */
.event-list::-webkit-scrollbar-button {
  display:none;
}

.tag-filter{
  display: flex;
  flex-direction: row;
  padding: 0px;
  
  label{
    display: flex;
    align-items: center;
    text-align: right;
    margin-right: 10px;
    cursor: pointer;
    @media (max-width: 992px) {
      display: none;
    }

    h3{
      color: #595959;
      font-size: 16px;
      margin-bottom: 0px!important;
    }
    p{
      font-size: 14px;
      margin-bottom: 0px!important;
    }
  }

  @media (max-width: 992px) {
    width:100% !important;
    // padding-right: 20px;
  }

  .tag-input{
    height: 40px;
    width: 350px;
    display: flex;
    border-radius: 5px;
    align-items: center;
    border: 1px solid #D9D9D9;
    justify-content: space-between;
    overflow: hidden;
    @media (max-width: 1200px) {
      width: 100%;
      min-width: 150px;
      max-width: 350px;
      height: 40px !important;
    }

    @media (max-width: 992px) {
      width:100% !important;
      max-width: 100%;
    }

    .left-area{
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;
      overflow-x: hidden;

      .placeholder{
        color:#8C8C8C;
        padding-left: 15px;
        margin-bottom: 0px;
        @media (max-width: 1200px) {
          padding-left: 0px;
          padding-right: 5px;
        }
      }
    }

    .closebtn{
      padding: 0px 10px;
      text-align: center;
      align-items: center;
      border: none;
      font-size: 16px;
      color:#8C8C8C;
      margin: 0px !important;
    }
    .searchbtn{
      height: 40px !important;
      text-align: center;
      align-items: center;
      font-size: 16px;
      color:#8C8C8C;
      border-left: 0px;
      margin-left: 0px !important;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    .ant-tag{
      color: #FFF;
      padding:4px 8px;
      border-radius: 8px;
      background-color: #0077CD;
    }
  }
}

.time-filter{
  display: flex;
  margin-left: 50px;
  flex-direction: row;
  padding: 0px;

  label{
    text-align: right;
    margin-right: 10px;
    cursor: pointer;

    h3{
      font-size: 16px;
      margin-bottom: 0px!important;
    }
    p{
      font-size: 14px;
      margin-bottom: 0px!important;
    }
  }

  .tag-input{
    height: 40px;
    // width: 140px;
    display: flex;
    border-radius: 5px;
    align-items: center;
    border: 1px solid #D9D9D9;
    justify-content: end;

    .placeholder{
      color:#8C8C8C;
      padding-left: 5px;
      padding-right: 10px;
      margin-bottom: 0px;
      @media (max-width: 1200px) {
        padding-left: 0px;
        padding-right: 5px;
      }
    }

    .closebtn{
      height: 38px !important;
      text-align: center;
      align-items: center;
      border: none;
      font-size: 16px;
      color:#8C8C8C;
      margin: 0px !important;
      border-radius: 5px;
    }
    .searchbtn{
      height: 40px !important;
      text-align: center;
      align-items: center;
      font-size: 16px;
      color:#8C8C8C;
      border-left: 0px;
      margin-left: 0px !important;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    
    .ant-tag{
      color: #FFF;
      padding:4px 8px;
      border-radius: 8px;
      background-color: #0077CD;
    }
  }
}

.ant-tag-checkable-checked{
  max-width: 120px;
  padding:4px 8px;
  border-radius: 8px;
  background-color: #0077CD;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-self: center;
  margin-top: 7px;
  @media (min-width: 992px) {
    max-width: 100px;
  }
}
.ant-tag-checkable{
  padding:4px 8px;
  border-radius: 8px;
  border: 1px solid #D9D9D9;
}

.club-view-selector{
  @media (max-width: 992px) {
    padding-top: 115px !important;
  }
  @media (max-width: 500px) {
    padding-top:110px !important;
  }
}

.week-day-selector{
  display: none;
  @media (max-width: 992px) {
    position: relative;
    padding: 0px 15px;
    padding-top: 75px;
    padding-bottom: 16px;
    display: flex;
    overflow: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    background-color: #FFF;
    // background-color: #F3F6F9;
    gap: 10px;
  }
  @media (max-width: 500px) {
    padding-top:70px;
  }

  .day-btn{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 55px;
    height: 50px;
    padding: 1px;
    border-radius: 4px;
    background-color: #F3F6F9;
    border-color: #F3F6F9;
    @media (min-width: 500px) {
      min-width: 80px;
    }
 
    h3{
      font-weight: 400;
      margin-bottom: 0px;
    }
    h4{
      font-weight: 700;
      margin-bottom: 0px;
    }
    @media (max-width: 500px) {
      h3{
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 0px;
      }
      h4{
        font-size: 12px;
        font-weight: 700;
        margin-bottom: 0px;
      }
    }
  }
 
  .day-arrow{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-width: 45px;
    height: 50px;
    padding: 5px 10px;
    border-radius: 4px;
    font-weight: 700;
    font-size: larger;
    margin-bottom: 0px;
    justify-content: center;
    @media (max-width: 992px) {
      display: none;
    }
  }

  .selected{
    background-color: #02B8F6;
    border: 1px solid #02B8F6;
    h3{
      color: #FFF !important;
    }
    h4{
      color: #FFF !important;
    }
  }
}

.time-gutter-header{
  padding: 10px 2px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  @media (max-width: 992px) {
    width: 50px;
    z-index: 99;
    justify-content: center;
  }
  .label{
    font-size: 10px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0px;
  }
  .arrow-btn{
    width: 30px;
    height: 30px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    background-color: #02B8F6;
    border: 1px solid #02B8F6;
  }
}

.mobile-week{
  @media (max-width: 992px) {
      height: calc(100vh - 285px);
  }
  @media (max-width: 500px) {
      height: calc(100vh - 230px);
  }
}

.modal-mobile-event-options {
  width: 624px !important;
  height: 98vh !important;
  background-color: transparent !important;
  
  .ant-modal-content {
    position: absolute;
    bottom: 20px;
    width: 100%;    
    border-radius: 10px;
    background-color: transparent !important;
  }
  .ant-modal-body {
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: transparent !important;
    // height: auto !important;
    Button{
      height: 40px;
      color: #02B8F6;
    }
    .top{
      border-radius: 10px 10px 0px 0px;
    }
    .bottom{
      border-radius: 0px 0px 10px 10px;
    }
    .solo{
      border-radius: 10px;
    }
    .cancel{
      margin-top: 10px;
      border-radius: 10px;
      font-weight: 500 !important;
    }
  }
  .ant-modal-header {
    display: none !important;
  }
}