.dashboard-page-single-content-wrapper {
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 0 50px;

  .dashboard-page-single-content {
    max-width: $wrapper-max-width;
    margin: 0 auto;
    display: block;
    margin-top: 24px;

    .hi-user {
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;
      margin-bottom: 24px;
    }

    .menus {
      background-color: transparent;

      p {
        margin: 0px;
        font-size: 14px;
        line-height: 22px;
      }

      li::after {
        border-bottom: none !important;
      }
      li:hover {
        color: $breeze-color-blue-2;
      }
      .ant-menu-item {
        display: flex;
        flex-direction: row;
        padding-right: 5px;
        padding-left: 5px;
        height: 38px !important;
      }
      .ant-menu-title-content {
        background-color: #fafafa;
        padding: 8px 16px;
        border-radius: 5px;
        a:hover {
          color: $breeze-color-blue-2;
        }
      }
      .ant-menu-item-selected {
        color: #fff;
        .ant-menu-title-content {
          background-color: $breeze-color-blue-2;
          padding: 8px 16px;
          border-radius: 5px;

          a {
            color: $breeze-color-blue-2;
          }
        }
      }
    }

    .dashboard-common-container {
      padding: 24px;
      background: #ffffff;
      border-radius: 8px;
    }
    .dashboard-upcoming-events {
      padding: 24px;
      background: #ffffff;
      border-radius: 8px;
      //   margin: 20px 0px;

      .events {
        display: flex;
        margin: 15px 0px;
        flex-direction: row;
        position: relative;
        overflow: auto;

        .no-events-container {
          width: 100%;
          height: 406px;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;

          h4 {
            font-size: 16px;
            margin-top: 20px;
            font-weight: 400;
          }
        }

        .spin-overlay {
          z-index: 99;
          width: 100%;
          height: 406px;
          display: flex;
          border-radius: 4px;
          position: absolute;
          align-items: center;
          justify-content: center;
          background-color: rgba($color: #fafafa, $alpha: 0.45);
        }
      }

      .event {
        width: 386px;
        // height: 406px;
        display: flex;
        padding: 10px;
        margin: 0px 5px;
        position: relative;
        flex-direction: column;

        .event-header {
          width: 386px;
          display: flex;
          padding: 0px 10px;
          position: absolute;
          flex-direction: row;
          margin-top: 10px;
          justify-content: end;

          p {
            padding: 4px 8px;
            color: #fff;
            border-radius: 4px;
            background-color: #1264bb;
          }

          .dropdown {
            padding: 4px 8px;
            color: #fff;
            border-radius: 4px;
            background-color: #1264bb;
          }
        }

        .image-placeholder {
          width: 386px;
          height: 280px;
          border-radius: 4px;
          background-image: url("../../images/breeze-placeholder-bg.png");
          background-size: cover;
        }

        .image-banner {
          width: 386px;
          height: 280px;
          border-radius: 4px;
          background-color: #fafafa;
        }

        h3 {
          margin-top: 10px;
          line-height: 28px;
        }

        p {
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          color: rgba($color: #000000, $alpha: 0.45);
        }
      }

      Button {
        margin-left: 5px;
        border-radius: 5px;
      }
    }

    .dashboard-pending-invites {
      padding: 24px;
      background: #ffffff;
      border-radius: 8px;

      .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .pending {
        display: flex;
        margin: 15px 0px;
        height: 402px;
        flex-direction: column;
        position: relative;
        overflow: auto;

        .no-events-container {
          width: 100%;
          height: 406px;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;

          h4 {
            font-size: 16px;
            margin-top: 20px;
            font-weight: 400;
          }
        }

        .invitation {
          height: 64px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin: 5px 0px;

          .invite-image {
            width: 64px;
            height: 64px;
            border-radius: 4px;
            margin-right: 20px;
            background-color: #e2e2e2;
          }

          .start-group {
            width: 80%;
            height: 64px;
            display: flex;
            flex-direction: row;

            .text {
              display: flex;
              flex-direction: column;
              justify-content: center;

              h3 {
                font-size: 20px;
                font-weight: 500;
                line-height: 28px;
                margin-top: 10px;
                margin-bottom: 0px;
              }

              p {
                font-size: 14px;
                font-weight: 500;
                line-height: 22px;
                color: rgba($color: #000000, $alpha: 0.45);
              }
            }
          }

          .end-group {
            width: 20%;
            height: 64px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: end;

            .invite-option {
              width: 46px;
              height: 40px;
              margin-left: 10px;
              border-radius: 4px;
              color: rgba(0, 0, 0, 0.85);
            }
          }
        }

        .spin-overlay {
          z-index: 99;
          width: 100%;
          height: 100%;
          display: flex;
          border-radius: 4px;
          position: absolute;
          align-items: center;
          justify-content: center;
          background-color: rgba($color: #fafafa, $alpha: 0.45);
        }
      }
    }
    
    .dashboard-top-clubs {
      padding: 24px;
      background: #ffffff;
      border-radius: 8px;

      .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .clubs {
        display: flex;
        margin: 15px 0px;
        height: 370px;
        flex-direction: column;
        position: relative;
        overflow: auto;

        .point-label{
          right: 0px;
          text-align: end;
          display: flex;
          font-size: 14px;
          font-weight: 400;
          position: absolute;
          color:rgba(0, 0, 0, 0.45);
        }

        .no-clubs-container {
          width: 100%;
          height: 370px;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;

          h4 {
            font-size: 16px;
            margin-top: 20px;
            font-weight: 400;
          }
        }

        .club {
          height: 64px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin: 5px 0px;

          .club-image {
            width: 64px;
            height: 64px;
            border-radius: 4px;
            margin-right: 20px;
            background-color: #e2e2e2;
          }

          .start-group {
            width: 80%;
            height: 64px;
            display: flex;
            flex-direction: row;

            .text {
              display: flex;
              flex-direction: column;
              justify-content: center;

              h3 {
                font-size: 20px;
                font-weight: 500;
                line-height: 28px;
              }
            }
          }

          .end-group {
            width: 20%;
            height: 64px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: end;
          }
        }

      }
    }
  }
  @media (max-width: 576px) {
    padding: 0 5%;
  }
}

.doughnut-wrapper {
  padding-top: 10px;
  .doughnut-container {
    height: 100%;
    position: relative;
    width: 100%;
    .total-percentage {
      width: 100%;
      height: 40px;
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -15px;
      line-height: 19px;
      text-align: center;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
    }
  }
  .doughnut-legend {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    .point-label{
      width: 100%;
      font-size: 16px;
      text-align: end;
      font-weight: 500;
      margin-bottom: 5px;
    }
    ul {
      flex-direction: column;
      display: flex;
      justify-content: center;
      margin-bottom: 0;
      width: 100%;
      list-style: none;
      padding-left: 0;
      li {
        margin-bottom: 5px;
        list-style: none;
        display: flex;
        .color-identifier {
          height: 16px;
          width: 16px;
          border-radius: 50%;
          background-color: #1890ff;
          margin-top: 3px;
        }
        h5 {
          margin-left: 8px;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
        }
        .legend-percentage {
          margin-left: auto;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}

.skill-progress-container {
  .d-flex{
    flex-wrap: wrap;
  }
  .container-title {
    margin: 0;
  }
  .date-label {
    font-weight: 500;
    font-size: 17px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.45);
  }
  .week-label {
    font-weight: 500;
    font-size: 33px;
    line-height: 46px;
    span {
      color: rgba(0, 0, 0, 0.45);
    }
  }
  .skill-progress-item {
    padding: 24px;
    border-radius: 4px;
    border: 0.5px solid #bfbfbf;
    min-height: 430px;
  }
}
