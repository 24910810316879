.form-horizontal-container {
  height: 68vh;
  overflow-y: scroll;
}

//viewing of event page
.event-view-form {
  .event-title-input {
    input {
      border: 0;
      border-radius: 0;
      border-bottom: 1px solid $breeze-border-color;
      outline: none;
      box-shadow: none;
      padding-left: 0;
      padding-right: 30px;
      font-weight: 500;
      font-size: 20px;
    }

    .ant-input-group-addon {
      background: transparent;
      border: none;
      position: absolute;
      right: 12px;
      top: 10px;
      z-index: 2;
      cursor: pointer;
    }
  }

  .event-series-selection {
    width: 170px !important;
  }

  //to fix alignment
  .ant-btn-icon-only {
    vertical-align: -6px;
  }
}

.select-item-selected-container {
  max-height: 320px;
  overflow-y: auto;
  display: flex;
  gap: 16px;
  flex-direction: column;

  div {
    border: 1px solid #f0f0f0;
    border-radius: 5px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.select-item-selected-container-designed {
  max-height: 420px;
  overflow-y: auto;
  display: flex;
  gap: 16px;
  flex-direction: column;

  .selected-item-container {
    background-color: white;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .selected-item-container:hover {
    border-color: #1890ff;
  }
}

.more-dropdown {
  margin-top: 5px;
  color: black;
  font-weight: 600;
  text-align: center;
  justify-content: center;
  display: flex;

  span {
    margin-top: 3px;
    margin-left: 5px;
  }
}

.with-widget-filters {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 24px;

  .content-container {
    transition: all 0.5s ease;
  }

  .sidebar-placeholder {
    position: relative;
    right: 0;
    transition: all 0.5s ease;
    height: calc(100vh - 223px);

    .listing-sidebar {
      // position: absolute;
      top: 0;
      height: calc(100vh - 223px);
      overflow-y: scroll;
    }
  }

  .listing-content-view {
    transition: all 0.5s ease;
    width: 100%;
    position: relative;
  }

  .content-squeeze {
    width: 75%;
    transition: all 0.5s ease;

    .content-container {
      width: 100%;
    }
  }

  .content-expand {
    width: 100%;
    transition: all 0.5s ease;

    .content-container {
      width: 100%;
    }
  }

  .hide-sidebar {
    display: none;
    width: 0;
  }

  .close-filters-sm {
    padding: 16px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    display: none;
  }

  @media screen and (min-width: 1281px) {
    .show-sidebar {
      width: 25%;
    }
  }

  @media screen and (max-width: 1280.98px) {
    .show-sidebar {
      position: fixed;
      z-index: 100;
      overflow-y: scroll;
      top: 0;
      right: 0;
    }

    .sidebar-backdrop {
      position: fixed;
      height: 100vh;
      width: 100vw;
      overflow-y: scroll;
      top: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.2);
    }

    .listing-sidebar {
      position: fixed;
      height: 100vh !important;
      z-index: 100;
      top: 0;
      right: 0;
      background-color: white;
    }

    .listing-header {
      display: flex;
      justify-content: space-between;
      padding: 16px;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }

    gap: 0px !important;

    .close-filters-sm {
      display: flex;
      justify-content: space-between;
    }

    .sidebar-date-filter {
      display: block;
    }

    .content-squeeze {
      width: 100%;

      .content-container {
        width: 100%;
      }
    }
  }

  @media screen and (min-width: 601px) and (max-width: 1280.98px) {
    @-webkit-keyframes sidebarSlideIn {
      0% {
        transform: translateX(35vw);
      }

      100% {
        transform: translateX(0);
      }
    }

    @-moz-keyframes sidebarSlideIn {
      0% {
        transform: translateX(35vw);
      }

      100% {
        transform: translateX(0);
      }
    }

    @keyframes sidebarSlideIn {
      0% {
        transform: translateX(35vw);
      }

      100% {
        transform: translateX(0);
      }
    }

    .listing-sidebar {
      -webkit-animation: sidebarSlideIn 0.2s;
      -moz-animation: sidebarSlideIn 0.2s;
      animation: sidebarSlideIn 0.2s;
      width: 35vw;
    }
  }

  // Small Devices standard is 767.98
  @media screen and (max-width: 600.98px) {
    @-webkit-keyframes sidebarSlideIn {
      0% {
        transform: translateX(100vw);
      }

      100% {
        transform: translateX(0);
      }
    }

    @-moz-keyframes sidebarSlideIn {
      0% {
        transform: translateX(100vw);
      }

      100% {
        transform: translateX(0);
      }
    }

    @keyframes sidebarSlideIn {
      0% {
        transform: translateX(100vw);
      }

      100% {
        transform: translateX(0);
      }
    }

    .listing-sidebar {
      -webkit-animation: sidebarSlideIn 0.2s;
      -moz-animation: sidebarSlideIn 0.2s;
      animation: sidebarSlideIn 0.2s;
      width: 100vw;
    }

    .sidebar-date-picker {
      margin-left: 12px !important;
    }
  }
}

.modal-profile-popup {
  width: 330px !important;

  .ant-modal-content {
    border-radius: 5px;

    .ant-modal-body {
      h5 {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.85);
      }

      label {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.45);
      }
    }

    .ant-modal-footer {
      display: none;
    }
  }
}

.import-event-wrapper {
  max-width: 496px;
  width: 496px;

  h3,
  p {
    color: #595959;
  }

  .ant-upload.ant-upload-drag {
    border: 0 !important;
    background: #FFF !important;
  }

  .ant-upload-list {
    padding-left: 48px;
    padding-right: 48px;
  }

  .import-event-container {
    background: #FFF;
    padding-bottom: 48px;

  }

  .ant-upload-list-item-done {
    color: #479e5a;
  }

  .ant-upload {
    padding: 0 !important;
  }

  .ie-inner {

    h3 {
      font-size: 18px;

    }

    p {
      font-size: 16px;
      font-weight: 500;
      margin: 0;
    }

    .ie-img {
      padding: 48px 48px 32px 48px;
    }
  }

}