.required-label::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
.custom-tooltip-content {
  visibility: hidden;
  width: 236px;
  position: absolute;
  bottom: 0;
  left: 25px;
  z-index: 5;
  background: $breeze-white;

  font-size: 12px;
  color: #000000d9;
  box-shadow: -1px 0px 11px 3px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: -1px 0px 11px 3px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: -1px 0px 11px 3px rgba(0, 0, 0, 0.12);
  .tip-title {
    box-shadow: inset 0px -1px 0px #f0f0f0;
    padding: 10px 16px;
  }
  ul {
    padding-left: 20px;
    border-top: 1;
    margin: 10px 16px;

    li {
      // list-style: circle;
      font-size: 12px;
      line-height: 20px;
      padding: 2px 0;
    }
    li::marker {
      content: "￮   ";
      font-size: 14px;
    }
  }
}
