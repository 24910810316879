.overlay-item-controls {
  margin-right: 2px;
  margin-top: 4px;
}

.notification-widget-full {
  min-height: 650px;
  height: auto !important;
}

.notification-widget {
  margin-top: 23px;
  margin-right: -20px;
  width: 400px;
  box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.5);
  height: 92vh;
  padding: 5px;
  background-color: white;
  position: relative;
  @media (max-width: 576px) {
    margin-right: 10px;
  }

  .notification-widget-header {
    padding: 16px;
    display: flex;
    justify-content: space-between;

    h5 {
      font-size: 16px;
      font-weight: normal;
      margin: 0;
    }

    a {
      font-size: 16px;
      color: $breeze-color-primary;
      margin: 0;
    }
  }

  .notif-list {
    padding-left: 0;
    max-height: 80vh;
    padding-bottom: 40px;
    overflow-y: scroll;

    li {
      background: white;
      position: relative;
      list-style: none;

      .overlay-button {
        // border: 1px solid black;
        visibility: hidden;
        transition: visibility 0s, opacity 0.1s linear;
        opacity: 0;
        .overlay-item {
          height: 32px;
          width: 32px;
          position: absolute;
          right: 10px;
          top: 15px;
          border-radius: 50%;
          background-color: white;
          box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
          cursor: pointer;
          span {
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%);
          }
        }
      }
      .notif-main {
        display: flex;
        padding: 16px;
        position: relative;

        .no-avatar {
          background-color: #eb2f96;
          border-radius: 50%;
          height: 42px;
          width: 42px;
          position: relative;
          span {
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%);
            color: white;
            font-size: 24px;
          }
        }

        .user-avatar {
          border-radius: 50%;
          height: 42px;
          width: 42px;
          object-fit: cover;
          margin-right: 5px;
          img {
            border-radius: 50%;
            object-fit: cover;
            height: 42px;
            width: 42px;
          }
        }

        .notif-format{
          display: flex;
          width: 100%;
          flex-direction: row;
          justify-content: space-between;
          padding-right: 15px;
          
          @media (max-width: 600.98px) {
            flex-direction: column;
          }
        }
        
        .notif-content {
          padding-left: 10px;
          padding-right: 10px;
          padding-right: 15px;
          h4 {
            margin: 0;
            font-weight: 500;
            font-size: 18px;
          }
          p {
            margin: 0;
            margin-bottom: 6px;
          }

          small {
            font-weight: 400;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.45);
          }

          .notif-invite-status{
            .red{
              color:#f00;
            }
          }
        }
        .notif-status {
          position: absolute;
          right: 15px;
          height: 12px;
          width: 12px;
          border-radius: 50%;
          background-color: $breeze-color-primary;
        }
        .notif-controls {
          margin-top: 12px;
          display: flex;
          // justify-content: center;
          flex-wrap: no-wrap;
          button {
            width: 100%;
          }
        }

        .notif-form {
          p {
            font-weight: bold;
            margin: 0;
            margin-bottom: 10px;
          }
          margin-top: 20px;
        }
      }
    }
    li:hover {
      .overlay-button {
        visibility: visible;
        opacity: 1;
      }
      background-color: #fafafa;
      cursor: pointer;
    }
    .notif-active {
      background-color: #fafafa;
    }
  }

  .no-scroll-auto-height {
    overflow-y: unset;
    height: auto;
    max-height: none;
  }

  .notification-footer {
    background-color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 15px;

    a {
      font-weight: 500;
      font-size: 14px;
    }
  }
}

.no-shadow {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.flex-grow-1 {
  flex-grow: 1;
}
.notif-container {
  position: fixed;
}
