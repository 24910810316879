.ant-layout-fixed-footer {
  padding-bottom: 100px;
}
.ant-layout-header {
  background: white;
  padding: 0 50px;
  border-bottom: 1px solid #f0f0f0;
}

.ant-menu-horizontal {
  border: 0;
}

.justified-row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & :first-child {
    flex-grow: 1;
  }
}

.menu-wrapper {
  display: flex;
  max-width: $wrapper-max-width;
  margin: 0 auto;
  height: 65px;
  .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .firstMenus {
    width: 65%;
    background-color: transparent;

    li::after {
      border-bottom: none !important;
    }
    li:hover {
      color: $breeze-color-blue-2;
    }
    .ant-menu-item {
      padding-right: 5px;
      padding-left: 5px;
    }
    .ant-menu-title-content {
      padding: 8px 16px;
      border-radius: 5px;
      font-weight: 500;
      a:hover {
        color: $breeze-color-blue-2;
      }
    }
    .ant-menu-item-selected {
      color: $breeze-color-blue-2;
      .ant-menu-title-content {
        background-color: $breeze-color-blue-2;
        // background-color: #fafafa;
        padding: 8px 16px;
        border-radius: 5px;
        a {
          color: #FFF;
          // color: $breeze-color-blue-2;
        }
      }
    }
  }

  .secondMenus {
    background-color: transparent;

    margin-left: auto;
    width: 35%;
    justify-content: end;

    li::after {
      border-bottom: none !important;
    }
    li:hover {
      color: $breeze-color-blue-2;
    }
    .ant-menu-item {
      padding-right: 5px;
      padding-left: 5px;
    }
    .ant-menu-title-content {
      padding: 8px 16px;
      border-radius: 5px;
    }
    .ant-menu-title-content:last-child {
      padding-right: 0;
    }
    .ant-menu-item-selected {
      color: $breeze-color-blue-2;
      .ant-menu-title-content {
        padding: 8px 16px;
        border-radius: 5px;
      }
    }

    .user-container {
      display: inline-block;
      img {
        border-radius: 50%;
        object-fit: contain;
      }
      h5 {
        max-width: 110px;
        display: inline-block;
        font-size: 14px;
        font-weight: 400;
        margin: 0;
        line-height: 14px;
        text-overflow: ellipsis;
      }
    }
    .badge{
      height: 25px;
    }
  }
}

.mobile-header {
  position: fixed;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0px 16px;
  height: 55px;
  z-index: 50;
  background: #ffffff;
  box-shadow: inset 0px -1px 0px #f0f0f0;
  align-items: center;

  .left{
    display: flex;
    align-items: center;
    flex-direction: row;
    .logo {
      float: left;
      width: 120px;
      height: 25px;
      // height: 31px;
      margin: 16px 24px 16px 0;
      background-size: contain;
      background-repeat: no-repeat;
    }
    .nav-item {
      font-size: 16px;
      padding: 0px 20px 0px 8px;
    }
  }
  .nav-items {
    display: flex;
    align-items: center;
    justify-content: start;
    @media (max-width: 500px) {
      display: none;
    }
    .badge{
      height: 25px;
    }
    .nav-item {
      font-size: 16px;
      padding: 0px 16px;
      img {
        border-radius: 50%;
        width: 24px;
        height: 24px;
      }
    }
  }
}
.mobile-flyout-backdrop {
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  z-index: 99;
  display: none;
  .mobile-flyout {
    position: fixed;
    height: 100%;
    width: 300px;
    background: #F3F6F9;
    // padding: 16px;
    left: 0px;
    top: 0px;
    bottom: 0px;
    @media (max-width: 500px) {
      width: 100%;
    }

    .menu-list {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0;
      li {
        list-style: none;
        margin: 10px 16px;
        padding: 8px 16px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.85);
        cursor: "pointer";

        a {
          color: rgba(0, 0, 0, 0.85);
        }
      }

      .active {
        color: #fff;
        // color: #02b8f6;
        padding: 8px 16px;
        gap: 8px;
        
        height: 40px;
        background-color: #02b8f6;
        // background: #f3f6f9;
        border-radius: 5px;

        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }

      .divider {
        border: 1px solid rgba(0, 0, 0, 0.03);
      }
      .flyout-profile{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .user-container {
          display: inline-block;
          img {
            margin-right: 5px;
            border-radius: 50%;
            object-fit: contain;
          }
          h5 {
            display: inline-block;
            font-size: 14px;
            font-weight: 500;
            margin: 0;
            line-height: 14px;
          }
        }
      }
      .privacy-policy{
        text-align: center;
        font-size: 14px;
        color: #02B8F6 !important;
      }
    }
  }
}

.dropdown-divider{
  min-width: 50px !important;
  margin:5px 10px 10px 10px !important;
}
// @media (min-width: 768px) {
@media (min-width: 992px) {
  .desktop-header {
    display: block;
  }
  .mobile-header {
    display: none;
  }
  .mobile-flyout-backdrop:not(.app) {
    display: none !important;
  }
}
@media (max-width: 992px) {
  .desktop-header {
    display: none;
  }
}

.admin-page-header-wrapper-override {
  .page-header-wrapper {
    padding: 24px 24px 0 24px;
    top: 0;
  }
  .page-header-container {
    padding-top: 0 !important;
    max-width: 100% !important;
  }
  .page-title {
    font-size: 28px !important;
    line-height: 28px !important;
    margin: 0;
  }
}

.layoutExpanded {
  .page-header-wrapper {
    top: 136px !important;
  }
  .mini-page-header {
    top: 136px !important;
  }
}
.page-header-wrapper {
  box-shadow: inset 0px -1px 0px #f0f0f0;
  background-color: white;
  position: sticky;
  padding: 0 50px;
  z-index: 20;
  top: 64px;

  .page-header-container {
    display: flex;
    flex-direction: column;
    height: 120px;
    max-width: $wrapper-max-width;
    margin: 0 auto;
    height: auto;
    padding-top: 24px;

    .page-title {
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 46px;
      letter-spacing: 0.02em;
      color: rgba(0, 0, 0, 0.85);
    }
    .filter-info {
      display: flex;
      flex-wrap: wrap;
      padding-top: 15px;
      padding-bottom: 20px;
      border-bottom: 1px solid #f0f0f0;
    }

    .controls-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 100%;
      gap: 6px;
    }

    .header-foot-controls {
      margin-top: auto;

      .tab-filter {
        flex-wrap: wrap;
        margin-bottom: 10px;
        .tab-item {
          border-radius: 4px;
          padding-left: 10px;
          padding-right: 10px;
          text-align: center;
          background-color: #FAFAFA;
          height: 24px;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          cursor: pointer;
          padding-bottom: 15px;
          margin-right: 16px;
          @media (max-width: 576px) {
            margin-bottom: 10px;
          }
        }
        .tab-active {
          color: #FFF;
          border-radius: 4px;
          padding-left: 10px;
          padding-right: 10px;
          background-color: $breeze-color-primary;
        }
      }
    }
  }
  @media (max-width: 992px) {
    padding: 0 16px;

    .page-header-container {
      .page-title {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }
}

.mini-page-header {
  position: fixed;
  width: 100%;
  padding: 12px 225px;
  z-index: 20;
  top: 0px;
  pointer-events: none;
  @media (min-width: 1500px) {
    display: flex;
    justify-content: center;
  }
  @media (max-width: 992px) {
    top: 55px;
    padding: 10px 10px !important;
    background-color: #FFF;
  }
  @media (max-width: 500px) {
    top: 0px;
    z-index: 60;
    padding: 0px !important;
  }

  .header-wide{
    width: 100% !important;
    display: flex;
    justify-content: space-between;
    pointer-events: auto;
    @media (min-width: 1500px) {
      width: 1050px !important;
      // width: 1150px !important;
    }
    @media (max-width: 992px) {
      display: none;
    }
    .add-contact-btn{
      width: 40px;
      height: 40px;
      padding: 0px;
      background-color: #FFF;
      margin-right: 20px;
    }
  }

  .header-compressed{
    display: none;
    @media (max-width: 992px) {
      width: 100%;
      display: flex;
      flex-direction: row;
      pointer-events: auto;
      justify-content: space-between;

      .tag-filter{
        width: 90% !important;
        margin-right: 15px;
      }

      Button{
        width: 40px !important;
        height: 40px !important;
        padding: 0px;
      }

      .add-contact-btn{
        height: 40px;
        padding: 0px;
        width: 40px !important;
        background-color: #FFF;
      }
    }

    .time-selected-btn{
      color:#FFF !important;
      background-color: #0077CD;
    }
    @media (max-width: 500px) {
      display: none;
    }
  }

  .header-mobile{
    display: none;
    @media (max-width: 500px) {
      display: flex;
      position: fixed;
      top: 10px;
      right: 16px;
      pointer-events: auto;

      Button{
        width: 60px !important;
        height: 35px !important;
        padding: 0px;
        border-radius: 4px;
      }
      .selected-num{
        margin-left: 15px;
        color: #02B8F6;
      }
      .closebtn{
        border: none;
        margin-left: 10px;
        width: 30px !important;
        height: 100% !important;
      }
    }
  }

  .search-wrapper:last-child {
    width: 100%;
  }

  .container {
    display: flex;
    justify-content: space-between;
    max-width: $wrapper-max-width;
    margin: 0 auto;

    #mini-page-item-1 {
      order: 1;
    }

    #mini-page-item-2 {
      order: 2;
    }

    @media screen and (max-width: 600.98px) {
      flex-wrap: wrap;

      #mini-page-item-1 {
        order: 2;
      }

      #mini-page-item-2 {
        order: 1;
        margin-bottom: 4px;
      }

      .d-flex {
        flex: 100%;
        justify-content: right;
      }
    }
  
    .show-medium-filters {
      @media screen and (min-width: 1281px) {
        display: none !important;
      }
    }

    .show-desktop-filters {
      @media screen and (max-width: 1280.98px) {
        display: none !important;
      }
    }

    .ant-input-search {
      .ant-input-affix-wrapper {
        height: 40px;
      }

      .ant-btn {
        border-top-right-radius: 5px !important;
        border-bottom-right-radius: 5px !important;
      }

      .ant-input-search-button {
        height: 40px;
      }
    }

    .list-filters {
      display: flex;
      // gap: 16px;

      button {
        height: 40px;
        margin: 0 5px;
      }
      .filter-sm {
        display: none;
      }
    }
  }

  @media (max-width: 600.98px) {
    .container {
      .list-filters {
        .filter-toggler {
          display: none;
        }
        .filter-sm {
          display: block;
        }
      }
    }
  }

  // @media (max-width: 991px) {
  //   .container {
  //     .list-filters {
  //       .filter-toggler {
  //         display: none;
  //       }
  //       .filter-sm {
  //         display: block;
  //       }
  //     }
  //   }
  // }
  @media (max-width: 992px) {
    padding: 16px 16px;
    div {
      gap: 4px;
    }
    .container {
      .list-filters {
        gap: 8px;
        button {
          margin: 0;
        }
      }
    }
  }
}
.content-wrapper {
  padding: 0 50px;
  margin-top: 20px;
  // margin-top: 90px;

  .content-container {
    max-width: $wrapper-max-width;
    margin: 0 auto;
    @media (max-width: 992px) {
      padding: 0px;
      height: calc(100vh - 128px);
    }
  }
  @media (max-width: 992px) {
    padding: 0px;
    height: calc(100vh - 128px);
    margin-top: 55px; //tablet view calendar
  }
  @media (max-width: 500px) {
    padding: 0px;
    height: calc(100vh - 128px);
    margin-top: -5px; //mobile view calendar
    // margin-top: 55px; //mobile view calendar
    // margin-top: 20px; //mobile view calendar
  }
}
.content-wrapper-full {
  width: 100%;
}

.collapse-filters {
  background: transparent;
  .ant-collapse-header {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    .ant-checkbox-group-item {
      margin-bottom: 8px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.page-header-banner {
  height: 270px;
  background-size: cover;
  background-color: transparent;
}

.block-message {
  color: rgba(0, 0, 0, 0.65);
  font-style: italic;
  text-align: center;
  line-height: 46px;
  display: block;
}

.banner-wrapper {
  height: 290px;
  background-color: rgba(0, 0, 0, 0.85);
  padding: 0 50px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media (max-width: 990px) {
    height: 240px;
  }

  .banner-container {
    max-width: $wrapper-max-width;
    padding: 32px 0;
    margin: auto;
  }

  @media (max-width: 992px) {
    padding: 0 16px;
  }
}

.banner-img-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 301px;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-top: 5px;
}

.profile-header-container {
  .profile-picture-div {
    position: relative;
    margin-right: 25px;

    .profile-avatar {
      height: 96px;
      width: 96px;
      border-radius: 50%;
      position: relative;
    }
    img {
      height: 100%;
      width: 100%;
      border-radius: 50%;
    }
    .no-avatar {
      background: #002962;
    }

    .btn-change-profile {
      cursor: pointer;
      height: 32px;
      width: 32px;
      padding: 5px;
      font-size: 22px;
      border-radius: 50%;
      background-color: #02b8f6;
      position: absolute;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 16px;
        width: 16px;
      }
    }
  }
  .profile-header-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    h3 {
      font-weight: 600;
      font-size: 28px;
      line-height: 33px;
      margin: 0;
    }
    .more-detail {
      display: flex;
      align-items: center;
      line-height: 30px;

      img {
        margin-right: 6px;
        width: 17.86px;
        height: 16.4px;
      }
      h5 {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin: 0;
        margin-top: 6px;
        color: rgba(0, 0, 0, 0.45);
      }
    }
  }
}

.event-list-controls {
  justify-content: space-between;
  align-items: center;
  display: flex;
  gap: 8px;

  .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button {
    border-radius: 0 5px 5px 0 !important;
  }

  button.ant-btn-circle {
    height: 40px;
    width: 40px;

    &.selected {
      border-color: #67d8ff;
      svg {
        color: #67d8ff;
      }
    }
  }
}

.ant-divider-inner-text {
  color: rgba(0, 0, 0, 0.45);
  font-style: italic;
}

.event-list {
  position: relative;
  // min-height: 100%;
  // display: flex;
  // flex-wrap: wrap;
  // gap: 24px;

  // &.row {
  //   flex-direction: column;
  // }

  // &.card {
  //   flex-direction: row;
  // }

  .list-divider {
    flex: 0 0 100%;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    justify-content: space-between;

    :nth-child(2) {
      color: #52c41a;
    }
  }

  .event-column {
    @media screen and (min-width: 992px) {
      max-width: 33.33% !important;
    }
    @media screen and (min-width: 577px) and (max-width: 991px) {
      max-width: 50% !important;
    }
    @media screen and (max-width: 576px) {
      max-width: 100% !important;
    }
  }

  .event-list-item {
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    background-color: #ffffff;
    flex-direction: row;
    border-radius: 10px;
    display: flex;
    cursor: pointer;

    &.row {
      flex-direction: start;
      align-items: flex-start;

      .header {
        border-radius: 10px 0 0 10px;
        width: 257px;
      }
    }

    &.card {
      // max-width: calc(100% / 3);
      // min-width: 315px;
      flex-direction: column;
      justify-content: space-between;
      // flex: 1 1 0px;

      .header {
        border-radius: 10px 10px 0 0px;
        padding: 16px;
        h4 {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: white;
        }
      }
    }

    &:hover {
      background: #fafafa;
    }

    &.hidden {
      background-color: unset;
    }

    .header {
      // min-width: 248px;
      width: 100%;
      min-height: 140px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    .header-no-img {
      display: flex;
      justify-content: space-between;
    }

    .content {
      flex-grow: 1;
      flex-shrink: 1;
      padding: 16px;

      .event-list-title {
        font-weight: 500;
        font-size: 20px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.85);
        word-wrap: break-word;
        margin-bottom: 5px;
      }

      .event-list-description {
        color: rgba(0, 0, 0, 0.45);
        -webkit-box-orient: vertical;
        white-space: pre-wrap;
        -webkit-line-clamp: 4;
        display: -webkit-box;
        line-height: 22px;
        overflow: hidden;
        font-weight: 400;
        font-size: 14px;
      }

      .event-list-item-footer {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.85);
        display: flex;
        flex-direction: column;
        gap: 8px;
        flex-wrap: wrap;
      }
      .event-club-details {
        margin-top: 5px;
        display: flex;
        align-items: center;

        .invite-status {
          position: relative;
          display: inline-block;

          .tooltip {
            visibility: hidden;
            min-width: 132px;
            max-width: 177px;
            top: -14px;
            left: 50%;
            background-color: #fff;
            border-radius: 2px;
            word-break: break-all;
            color: #000;
            box-shadow: 0px 0px 11px 1px rgba(0, 0, 0, 0.1),
              0px 0px 19px 14px rgba(0, 0, 0, 0.03);
            padding: 6px 8px;
            position: absolute;
            z-index: 1;
            h5 {
              color: #000;
            }
            span {
              color: rgba(0, 0, 0, 0.45);
            }
          }

          .tooltip::after {
            content: " ";
            position: absolute;
            top: 50%;
            right: 100%;
            margin-top: -6px;
            border-width: 6px;
            border-style: solid;
            border-color: transparent #f3f6f9 transparent transparent;
          }

          svg,
          h5 {
            color: #e74731;
          }
          svg {
            font-size: 28;
            margin-right: 5;
          }
        }

        .invite-status:hover {
          .tooltip {
            visibility: visible;
          }
        }

        div {
          width: 50%;
        }
        img {
          margin-right: 5px;
        }
        h5 {
          font-size: 14px;
          line-height: 22px;
          margin: 0;
          color: rgba(0, 0, 0, 0.45);
        }
      }
      .event-softskill-content {
        h4 {
          color: rgba(0, 0, 0, 0.45);
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
        }
        .event-softskill-bar {
          display: flex;
          width: 100%;
          .bar {
            height: 16px;
            font-size: 8px;
            padding: 2px 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
          }
          .bar:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }
          .bar:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
      }
      .event-softskill-legend {
        width: 100%;
        background: #f5f5f5;
        border-radius: 4px;
        padding: 8px;
        display: flex;
        flex-wrap: wrap;
        .softskill-legend {
          display: flex;
          align-items: center;
          width: 50%;
          height: 25px;
          .box {
            width: 12px;
            height: 12px;
            border-radius: 2px;
            margin-right: 5px;
          }
          label {
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.45);
          }
        }
      }
    }

    .footer {
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      padding-top: 0;
      flex-wrap: wrap;
      display: flex;
      gap: 10px;

      .event-status {
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
        border: 1px dashed #67d8ff;
        text-align: center;
        border-radius: 17px;
        padding: 5px 16px;
        color: #67d8ff;
        font-size: 14px;
        display: block;
        flex-grow: 1;
        position: relative;

        .tooltip {
          visibility: hidden;
          min-width: 132px;
          max-width: 177px;
          top: -22px;
          left: 110%;
          background-color: #fff;
          border-radius: 2px;
          word-break: break-all;
          color: #000;
          box-shadow: 0px 0px 11px 1px rgba(0, 0, 0, 0.1),
            0px 0px 19px 14px rgba(0, 0, 0, 0.03);
          padding: 6px 8px;
          position: absolute;
          z-index: 1;
          h5 {
            color: #000;
          }
          span {
            color: rgba(0, 0, 0, 0.45);
          }
        }

        .tooltip::after {
          content: " ";
          position: absolute;
          top: 50%;
          right: 100%;
          margin-top: -6px;
          border-width: 6px;
          border-style: solid;
          border-color: transparent #f3f6f9 transparent transparent;
        }
      }
      .event-status:hover {
        .tooltip {
          visibility: visible;
        }
      }

      button {
        flex-grow: 2;
      }
    }
  }
}

.event-page {
  .content-box {
    background: #ffffff;
    border-radius: 10px;
    padding: 24px;

    h2 {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: rgba(0, 0, 0, 0.85);
      margin-bottom: 16px;
    }

    p {
      -webkit-box-orient: vertical;
      white-space: pre-wrap;
      display: -webkit-box;
      line-height: 24px;
      color: #000000;
      font-weight: 400;
      font-size: 16px;
      word-break: break-all;
    }

    .text-highlight {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: rgba(0, 0, 0, 0.85);
    }

    .data-box-container {
      display: flex;
      justify-content: space-between;
      gap: 6px;

      span {
        text-align: center;
        padding: 16px;
        background: #fafafa;
        border-radius: 10px;
        max-width: 110px;
        flex-grow: 1;

        dt {
          font-weight: 500;
          font-size: 24px;
          line-height: 32px;
          color: rgba(0, 0, 0, 0.85);
        }

        dd {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: rgba(0, 0, 0, 0.45);
        }
      }
    }
  }

  .info-row {
    display: flex;
    gap: 6px 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
    flex-wrap: wrap;
  }
}

.participants-pane {
  .ant-tabs{
    height: 50px;
  }

  .ant-tabs-centered
    > .ant-tabs-nav
    .ant-tabs-nav-wrap:not([class*="ant-tabs-nav-wrap-ping"]) {
    justify-content: flex-end;
  }

  .participants-tabs{
    @media (max-width: 600.98px) {
      margin-bottom: 30px !important;
    }
    .ant-tabs-nav{
      @media (max-width: 600.98px) {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: left;
        margin-bottom: 50px !important;
      }
      
      .ant-tabs-extra-content{
        width: max-content;
        @media (max-width: 600.98px) {
          width: 100%;
        }
      }
      .ant-tabs-nav-wrap{
        display: flex;
        justify-content: flex-end;
        @media (max-width: 600.98px) {
          justify-content: flex-start;
        }
      }
    }
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: unset;
  }

  .ant-input-search-button {
    height: 40px;
  }

  .ant-tabs-tab .ant-tabs-tab-btn{
    border-radius: 4px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #FAFAFA;
   }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #FFF !important;
    border-radius: 4px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-bottom: none !important;
    background-color: $breeze-color-primary;
  }
  .ant-tabs-ink-bar {
    background: none;
  }

}

@media (max-width: 992px) {
  .event-list {
    display: flex;
    flex-direction: column;
    .event-list-item {
      flex-direction: column;

      > :last-child {
        margin-top: 12px;
        .event-status {
          display: inline-block;
          text-align: left;
          margin-left: 12px;
        }
      }
    }
  }
}

// @media (max-width: 790px) {
//   .event-list {
//     background-color: #0077CD;
//     .event-list-item {
//       flex-direction: column;

//       > :last-child {
//         margin-top: 12px;
//         .event-status {
//           display: inline-block;
//           text-align: left;
//           margin-left: 12px;
//         }
//       }
//     }
//   }
// }

.calendar-view-legends {
  display: flex;
  flex-direction: row;
  background-color: #FFF;
  padding-left: 24px;
  margin-bottom: 10px;
  @media (max-width: 992px) {
    margin-top: 0px;
    padding-top: 15px;
    // padding-left: 10px;
    // justify-content: space-around;
    justify-content: center;
  }

  .legend {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    margin-right: 40px;
    flex-direction: row;
    align-items: center;
    color: #595959;

    @media (max-width: 992px) {
      font-size: 12px;
      margin-right: 10px;
      margin-left: 10px;
    }

    .light-blue-circle {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border:1px solid #D9D9D9;
      background-color: #D3F4FF;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      // @media (max-width: 600.98px) {
      //   width: 14px;
      //   height: 14px;
      // }
    }

    .blue-circle {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border:1px solid #D9D9D9;
      background-color: #02B8F6;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      // @media (max-width: 600.98px) {
      //   width: 14px;
      //   height: 14px;
      // }
    }

    .grey-circle {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border:1px solid #D9D9D9;
      background-color: #859CA1;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      // @media (max-width: 600.98px) {
      //   width: 14px;
      //   height: 14px;
      // }
    }
  }
}
//override

.ant-layout-footer {
  // padding: 16px 50px 16px 50px;
  padding: 10px 50px;
  // background-color: white;
  // margin-top: 24px;
  // position: fixed;
  bottom: 0;
  width: 100%;
  @media (max-width: 992px) {
    display: none;
  }
  .footer-wrapper {
    max-width: $wrapper-max-width;
    margin: 0 auto;

    .footer-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      margin: 0;
      color: rgba(0, 0, 0, 0.45);
    }

    .footer-links {
      a {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.45);
      }
      a::after {
        content: "|";
        margin-left: 3px;
        margin-right: 3px;
      }
      a:last-child:after {
        content: "";
      }
    }
  }
  @media (max-width: 576px) {
    .footer-wrapper {
      .footer-links {
        display: none;
      }
    }
  }
}

.empty-state.state-no-events.app {
  display: none;
}

.empty-state {
  // width: 480px;
  flex-direction: row;
  margin: 60px auto 0 auto;
  margin-top: 150px;

  @media (max-width: 1500px) {
    margin-top: 50px;
    flex-direction: column;
    align-items: center;
    width: auto;
  }

  h1 {
    text-align: left;
    margin-top: 20px;
    font-weight: 500;
    font-size: 42px;
    line-height: 63px;
  }
  p {
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
  }
}

.empty-state-v2 {
  // width: 480px;
  flex-direction: row;
  margin: 60px auto 0 auto;
  margin-top: 150px;
  padding: 0 4%;

  @media (max-width: 1500px) {
    margin-top: 50px;
    flex-direction: column;
    align-items: center;
    width: auto;
  }

  h1 {
    text-align: center;
    margin-top: 20px;
    font-weight: 500;
    font-size: 84px;
    line-height: 87px;
  }
  p {
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
  }
  @media (max-width: 576px) {
    h1 {
      font-size: 44px;
    }
  }
}

.coming-soon-state {
  // width: 480px;
  flex-direction: column;
  margin: 60px auto 0 auto;
  margin-top: 150px;

  @media (max-width: 1500px) {
    margin-top: 50px;
    flex-direction: column;
    align-items: center;
    width: auto;
  }

  h1 {
    margin-top: 20px;
    font-weight: 500;
    font-size: 84px;
    line-height: 63px;
  }
  p {
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
  }
  img {
    width: 75%;
  }
}

.page-banner-wrapper {
  background-image: url("/assets/images/banner-bg.png");
  height: 220px;
  box-shadow: inset 0px -1px 0px #f0f0f0;
  background-color: white;
  position: sticky;
  padding: 0 50px;
  z-index: 20;
  top: 64px;
  background-size: cover;
  background-repeat: no-repeat;

  .page-banner-container {
    display: flex;
    flex-direction: column;
    height: 220px;
    max-width: $wrapper-max-width;
    justify-content: center;
    margin: 0 auto;

    .page-title {
      font-style: normal;
      font-weight: 500;
      font-size: 48px;
      line-height: 46px;
      color: #ffffff;
    }
    .sub-title {
      color: #ffffff;
    }
  }
  @media (max-width: 992px) {
    height: 172px;
    background-image: url("/assets/images/banner-bg-tablet.png");
    .page-banner-container {
      height: 172px;

      .page-title {
        font-size: 32px;
        margin-bottom: 10px;
      }
    }
  }
  @media (max-width: 992px) {
    padding: 0 16px;
    height: 124px;
    .page-banner-container {
      height: 124px;
    }
  }
  // @media (max-width: 992px) {
  //   height: 172px;
  //   background-image: url("/assets/images/banner-bg-tablet.png");
  //   .page-banner-container {
  //     height: 172px;

  //     .page-title {
  //       font-size: 32px;
  //       margin-bottom: 10px;
  //     }
  //   }
  // }
  // @media (max-width: 992px) {
  //   padding: 0 16px;
  //   height: 124px;
  //   .page-header-container {
  //     height: 124px;
  //     .page-title {
  //       font-size: 24px;
  //       line-height: 32px;
  //     }
  //   }
  // }
}

.privacy-policy{
  height: 100% !important;
}

.page-blog-content {
  // height: 100%;
  margin-top: 60px;
  margin-bottom: 50px;
  padding: 24px;
  h5 {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 20px;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #595959;

    b {
      font-size: 18px;
      color: black;
    }
  }
  span {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 25px;
    line-height: 24px;
  }
  ul {
    padding-left: 25px;
    li {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #595959;
    }
  }
}

.page-table-of-content-widget {
  margin-top: 60px;
  padding: 20px;
  .widget-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.85);
  }
  ul {
    padding: 0;
    li {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.85);
      list-style: none;
      border-radius: 4px;
      cursor: pointer;
      a {
        display: flex;
        height: 100%;
        width: 100%;
        padding: 8px 16px;
        color: rgba(0, 0, 0, 0.85);
      }
    }
    .active {
      a {
        color: white;
      }
      background: $breeze-color-blue-2;
    }
  }
}

.bg-white {
  background-color: white;
}

.table-striped-rows{
  padding-left: 10px;

  .ant-list-item-meta-content{
    flex:1.5;
  }
  .attendance-remarks{
    display: flex;
    flex-direction: row;
    vertical-align: middle;

    .edited-text{
      color:#808b96 ;
      margin-top:5px;
      margin-bottom:0px;
      font-size:14px;
    }
    .ant-btn{
      border: none;
      background-color: transparent;
      box-shadow: none;
    }
  }
}

.table-striped-rows:nth-child(2n-1){
  background-color: #F5F5F5;
}

.qr-pane-header{
  padding: 24px;
  background-color: #F0F0F0;

  h1{
    font-size: 38px;
  }
  p{
    font-size: 14px;
  }
}

.qr-btn-row{
  margin-top: 20px;
  display: flex;
  flex-direction: row;

  @media (max-width: 600.98px) {
    flex-direction: column;
  }
  
  Button{
    border-radius: 5px;
    margin-right: 20px;

    @media (max-width: 600.98px) {
      padding: 0px 5px;
      font-size: 12px;
      height: 30px!important;
      margin-bottom: 20px;
      margin-right: 0px;
    }
  }
}

.unavailable-qr{
  padding: 50px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  div{
    text-align: center;
  }

  Button{
    margin-top: 10px;
    border-radius: 5px;
  }
}

.mobile-reactive-btn{
  @media (max-width: 600.98px) {
    padding: 0px 5px;
    font-size: 12px;
    height: 30px!important;
    // max-width: 100px!important;
  }
}
