.ant-layout-header-breeze {
  z-index: 10 !important;
  background-color: $breeze-color-main;
  padding: 0px 15% !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  
  @media (max-width: 1450px) {
    padding: 0px 5% !important;
  }

  .ant-menu {
    background: transparent;
  }

  .ant-menu-item:hover {
    background-color: $breeze-color-main !important;
  }
  .ant-menu-item-selected {
    background-color: $breeze-color-main !important;
  }

  h2{
    margin-bottom: 0px;
  }
}

// .Home-Page-Header {
//   height: 120px;
//   background-color: white;
//   // background-color: white;
//   border-bottom: 1px solid gray;
// }

// .menu-class {
//   justify-content: end;
// }

// .logo-container {
//   display: inline-block;
//   color: white;
// }

// .logo-custom {
//   float: left;
//   width: 120px;
//   height: 31px;
//   margin: 16px 24px 16px 0;
//   // background: rgba(255, 255, 255, 0.2);
//   object-fit: fill;
// }

.homepage-content{
  height: calc(100vh - 64px);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15%;
  background-color: #00B7FD;

  @media (max-width: 1450px) {
    padding: 0px 5%;
  }
  @media (max-width: 1024px) {  //tablet size
    height: max-content;
    flex-direction: column;
    justify-content: space-between;
  }

  .graphic{
    z-index: 1;
    position: absolute;
    height: 80%;
    bottom: 0px;
    right: 100px;

    @media (min-width: 2350px) {
      right: 300px;
    }
    @media (max-width: 2000px) {
      right: 100px;
    }
    @media (max-width: 1450px) {
      right: 50px;
    }
    @media (max-width: 1200px) {
      
      right: -250px;
    }
    @media (max-width: 1024px) {
      position: relative;
      height: min-content;
      width: 100%;
      right: 0px;
      bottom: 0px;
      padding: 0px;
      object-fit:scale-down;
      display: flex;
      flex-direction: column;
      justify-self: flex-end;
    }
  }

  .content{
    z-index: 2;
    width: 50%;
    @media (max-width: 1024px) {
      
      width: 100%;
      padding: 50px 0px;
      text-align: center;
    }
    h1{
      
      color: #FFF;
      margin-bottom: 0px;

      @media (min-width: 2000px) {
        
        font-size: 86px;
      }
      @media (max-width: 1999px) {
        
        font-size: 66px;
      }
      @media (max-width: 1200px) {
        
        font-size: 50px;
        margin-bottom: 50px;
      }
      @media (max-width: 677px) {  //tablet size
        
        font-size: 40px;
        margin-bottom: 10px;
      }
    }

    h3{
      width: 85%;
      color: #FFF;
      margin-bottom: 25px;

      @media (min-width: 2000px) {
        
        font-size: 32px;
      }
      @media (max-width: 1999px) {
        
        font-size: 22px;
      }
      @media (max-width: 1450px) {
        
        width: 70%;
      }
      @media (max-width: 1200px) {
        
        width: 100%;
        font-size: 22px;
        margin-bottom: 50px;
      }
      @media (max-width: 677px) {  //tablet size
        
        width: 100%;
        font-size: 18px;
        margin-bottom: 40px;
      }
    }

    .login-btn{
      // padding: 24px 48px;
      // font-size: 18px;
      color:#FFF;
      background-color: #002962;
      border:none;
      // display: flex;
      // justify-content: center;
      // align-items:center;
      // text-align: center;

      @media (min-width: 2000px) {
        // height: 60px;
        // width: 200px;
        font-size: 30px;
      }
      @media (max-width: 1024px) {
        // width: 100%;
        margin-bottom: 20px;
      }
    }
    .google-login-btn{
      width: min-content;
    }
  }
}

.homepage-content-2{
  height: calc(100vh);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15%;
  background-color: #FFF;

  @media (max-width: 1450px) {
    
    padding: 0px 5%;
  }
  @media (max-width: 1024px) {  //tablet size
    height: max-content;
    flex-direction: column-reverse;
    justify-content: space-between;
  }

  .graphic{
    z-index: 1;
    position: relative;
    // height: 80%;
    width: 50%;
    bottom: 0px;

    // @media (min-width: 2350px) {
      
    //   right: 300px;
    // }
    // @media (max-width: 2000px) {
      
    //   right: 100px;
    // }
    // @media (max-width: 1450px) {
      
    //   right: 50px;
    // }
    // @media (max-width: 1200px) {
      
    //   right: -250px;
    // }
    @media (max-width: 1024px) {
      
      position: relative;
      height: min-content;
      width: 100%;
      right: 0px;
      bottom: 50px;
      padding: 0px;
      object-fit:scale-down;
      display: flex;
      flex-direction: column;
      justify-self: flex-end;
    }
  }

  .content{
    z-index: 2;
    width: 50%;
    @media (max-width: 1024px) {
      
      width: 100%;
      padding: 50px 0px;
      text-align: center;
    }
    h3{
      width: 85%;
      color: #002962;
      margin-bottom: 25px;

      @media (min-width: 2000px) {
        
        font-size: 32px;
      }
      @media (max-width: 1999px) {
        
        font-size: 22px;
      }
      @media (max-width: 1450px) {
        
        width: 70%;
      }
      @media (max-width: 1200px) {
        
        width: 100%;
        font-size: 22px;
        margin-bottom: 50px;
      }
      @media (max-width: 677px) {  //tablet size
        
        width: 100%;
        font-size: 18px;
        margin-bottom: 40px;
      }
    }
  }
}

.wave-bg{
  height: 100%;
  background-color: #002962;

  @media (max-width: 677px) { 
    height: 200vh;
  }

  h1{
    padding-top: 50px;
    font-size: 50px;
    color: #FFF;
    text-align: center;

    @media (max-width: 1024px) { 
      font-size: 40px;
    }
    @media (max-width: 677px) { 
      font-size: 30px;
    }
  }

  .wave{
    position: absolute;
    width: 100vw;
    min-height: 60%;
    background-size: 100%;
    background-image: url("../../images/wave-bg-long.png");
    background-repeat:no-repeat;
    background-position: center;
  }
}

.homepage-content-3{
  height: calc(70vh);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15%;

  @media (max-width: 1450px) {
    padding: 0px 5%;
  }
  @media (max-width: 1024px) {  //tablet size
    height: max-content;
    flex-direction: column;
    justify-content: space-between;
  }

  .graphic{
    z-index: 1;
    position: relative;
    width: 50%;
    bottom: 0px;

    @media (min-width: 2350px) {
      
    }
    @media (max-width: 2000px) {
      
    }
    @media (max-width: 1450px) {
      right: 50px;
    }
    @media (max-width: 1200px) {
      
      right: -250px;
    }
    @media (max-width: 1024px) {
      
      position: relative;
      height: min-content;
      width: 100%;
      right: 0px;
      bottom: 50px;
      padding: 0px;
      object-fit:scale-down;
      display: flex;
      flex-direction: column;
      justify-self: flex-end;
    }
  }

  .content{
    z-index: 2;
    width: 50%;
    @media (max-width: 1024px) {
      
      width: 100%;
      padding: 50px 0px;
      text-align: center;
    }
    h2{
      color: #FFF;
      margin-bottom: 0px;

      @media (min-width: 2000px) {
        
        font-size: 46px;
      }
      @media (max-width: 1999px) {
        
        font-size: 30px;
      }
      @media (max-width: 1200px) {
        
        font-size: 30px;
      }
      @media (max-width: 677px) {  //tablet size
        
        font-size: 20px;
      }
    }
    h3{
      width: 85%;
      color: #FFF;
      font-weight: 300 !important;
      margin-bottom: 25px;
      text-align: left;

      @media (min-width: 2000px) {
        
        font-size: 32px;
      }
      @media (max-width: 1999px) {
        
        font-size: 22px;
      }
      @media (max-width: 1450px) {
        
        width: 70%;
      }
      @media (max-width: 1200px) {
        
        width: 100%;
        font-size: 22px;
        margin-bottom: 50px;
      }
      @media (max-width: 677px) {  //tablet size
        
        width: 100%;
        font-size: 18px;
        margin-bottom: 40px;
      }
    }

    .login-btn{
      padding: 24px 48px;
      font-size: 18px;
      color:#FFF;
      background-color: #002962;
      border:none;
      display: flex;
      justify-content: center;
      align-items:center;
      text-align: center;

      @media (min-width: 2000px) {
        
        height: 60px;
        width: 200px;
        font-size: 30px;
      }
      @media (max-width: 1024px) {
        
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
}

.homepage-content-4{
  height: calc(100vh);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15%;

  @media (max-width: 1450px) {
    padding: 0px 5%;
  }
  @media (max-width: 1024px) {  //tablet size
    height: max-content;
    flex-direction: column-reverse;
    justify-content: space-between;
  }

  .graphic{
    z-index: 1;
    position: relative;
    width: 50%;
    bottom: 0px;

    @media (min-width: 2350px) {
      
    }
    @media (max-width: 2000px) {
      
    }
    @media (max-width: 1450px) {
      right: 50px;
    }
    @media (max-width: 1200px) {
      
      right: -250px;
    }
    @media (max-width: 1024px) {
      
      position: relative;
      height: min-content;
      width: 100%;
      right: 0px;
      bottom: 50px;
      padding: 0px;
      object-fit:scale-down;
      display: flex;
      flex-direction: column;
      justify-self: flex-end;
    }
  }

  .content{
    z-index: 2;
    width: 50%;
    @media (max-width: 1024px) {
      
      width: 100%;
      padding: 50px 0px;
      text-align: center;
    }
    h2{
      color: #FFF;
      margin-bottom: 0px;

      @media (min-width: 2000px) {
        
        font-size: 46px;
      }
      @media (max-width: 1999px) {
        
        font-size: 30px;
      }
      @media (max-width: 1200px) {
        
        font-size: 30px;
      }
      @media (max-width: 677px) {  //tablet size
        
        font-size: 20px;
      }
    }
    h3{
      width: 85%;
      color: #FFF;
      font-weight: 300 !important;
      margin-bottom: 25px;
      text-align: left;

      @media (min-width: 2000px) {
        
        font-size: 32px;
      }
      @media (max-width: 1999px) {
        
        font-size: 22px;
      }
      @media (max-width: 1450px) {
        
        width: 70%;
      }
      @media (max-width: 1200px) {
        
        width: 100%;
        font-size: 22px;
        margin-bottom: 50px;
      }
      @media (max-width: 677px) {  //tablet size
        
        width: 100%;
        font-size: 18px;
        margin-bottom: 40px;
      }
    }

    .login-btn{
      padding: 24px 48px;
      font-size: 18px;
      color:#FFF;
      background-color: #002962;
      border:none;
      display: flex;
      justify-content: center;
      align-items:center;
      text-align: center;

      @media (min-width: 2000px) {
        
        height: 60px;
        width: 200px;
        font-size: 30px;
      }
      @media (max-width: 1024px) {
        
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
}
