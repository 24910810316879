$breeze-color-main: #0ec1fe;
$breeze-color-blue-1: #1890ff;
$breeze-color-blue-2: #02b8f6;
$breeze-anim-color-1: #1fb9ec;
$breeze-btn-text: #ffffff;
$breeze-btn-text-white: #ffffff;
$breeze-white: #ffffff;
$breeze-light-gray: #8c8c8c;
$breeze-light-green: #52c41a;

.breeze-color-main {
  color: $breeze-color-main !important;
}

$breeze-border-color: #f0f0f0;

$breeze-color-primary: #02b8f6;
// $breeze-color-secondary: #02b8f6;

$breeze-color-dark-blue: #002962;

.bg-dark-blue {
  background-color: $breeze-color-dark-blue !important;
}

.text-orange {
  color: #ff585a;
}

.icon-green {
  svg {
    color: #169f5b;
  }
}

.icon-blue {
  svg {
    color: #1264bb;
  }
}

.icon-red {
  svg {
    color: #e74731;
  }
}

.icon-orange {
  svg {
    color: #ffa800;
  }
}
