.ant-popover-inner{
    border-radius: 8px;
}
.ant-popover-inner-content{
    padding: 0px !important;
}

.interest-filter{
    padding: 0px 0px;
    width: 300px;
    border-radius: 5px;
    // .interest-filter-header{
    //     font-size: 18px;
    //     display: flex;
    //     flex-direction: row;
    //     justify-content: space-between;
    //     border-bottom: 1px solid #F0F0F0;
    //     margin-bottom: 10px;
    // }
    
    // .interest-filter-options{
    //     display: flex;
    //     flex-direction: column;

    //     .ant-checkbox-group{
    //         display: flex;
    //         flex-direction: column;
    //     }
    // }

    .collapse-filters{
        .ant-collapse-header{
            border-bottom: 1px solid #f0f0f0;
        }
        .interest-checkbox{
            height: 50px;
            display: flex;
            align-items: center;
            margin-left: 0px;
            margin-bottom: 5px;
            h3{
                height: 50px;
                margin-bottom: 0px;
                p{
                    font-size: 14px;
                    font-weight: normal;
                }
            }
        }
    }
}

.red-asterisk{
    color:#F00;
}