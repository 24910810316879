@import "club-propose.scss";

.modal-club-member-invite {
  .ant-modal-body {
    padding: 16px;
    padding-top: 8px;
  }
  .modal-club-member-form {
    .selected-list {
      .ant-form-item-label {
        display: none;
      }
    }
    .ant-select-selection-item {
      display: none;
    }

    .ant-select {
      font-size: 16px !important;
    }
  }
}

.club-member-list {
  .list-controls {
    display: flex;
    // flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 20px;
    .ant-input-search-button {
      height: 40px;
    }
    .ant-input-affix-wrapper {
      height: 40px;
    }
    .list-filters {
      justify-content: center;
      align-items: center;
      display: flex;
      button {
        margin-left: 5px;
        height: 40px;
        width: 40px;
      }
      button:last-child {
        width: auto;
      }
    }

    @media screen and (max-width: 430px) {
      flex-flow: column;
      flex-direction: column-reverse;
      align-items: end;
      gap: 10px;
      .search-container {
        width: 100%;
        .ant-input-search {
          width: 100% !important;
        }
      }
      .list-filters {
        button {
          margin-left: 0px;
          height: 40px;
          width: 40px;
        }
        
      }
    }
  }

  .member-item-grid-view {
    height: 100%;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 5px;
    background-color: white;
    display: flex;
    flex-direction: column;

    .member-item-content {
      padding: 24px;
      .member-top-controls {
        display: flex;
        justify-content: space-between;

        .member-status {
          // color: #52c41a;
          font-weight: bold;
        }

        span {
          color: black;
          font-size: 22px;
          margin-top: -5px;
        }
      }
      .member-details {
        display: flex;
        flex-direction: column;
        align-items: center;
        .member-name {
          font-size: 16px;
          line-height: 24px;
          color: rgba(0, 0, 0, 0.85);
        }
        .member-type {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.45);
        }
      }
    }
    .skeleton-loader {
      padding: 24px;
      .skeleton-loader-header {
        display: flex;
        justify-content: space-between;
        .ant-skeleton {
          width: 50px;
          .ant-skeleton-title {
            width: 50px !important;
          }
        }
      }
      .skeleton-img {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .custom-input-sk {
        span {
          margin-bottom: 5px;
        }
      }
    }
  }

  .member-item-list-view {
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 5px;
    background-color: white;
    display: flex;
    width: 100%;
    .member-item-content {
      padding: 16px;
      display: flex;
      justify-content: space-between;
      width: 100%;

      .member-details {
        display: flex;
        flex-direction: column;
        .member-name {
          font-size: 16px;
          line-height: 24px;
          color: rgba(0, 0, 0, 0.85);
        }
        .member-type {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.45);
        }

        .member-status {
          // color: #52c41a;
          font-weight: bold;
        }
      }
      .member-bot-controls {
        .anticon {
          font-size: 22px;
          padding: 5px;
          color: black;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .list-controls {
      flex-wrap: nowrap;
      .ant-input-search {
        width: auto !important;
      }
    }
  }
}
