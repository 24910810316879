.modal-join-breeze{
    width: 550px !important;
    .ant-modal-content {
        border-radius: 32px;
        background-color: #00B7FD !important;
        background-image: url("../../../../assets/images/iphones-and-arrows.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size:contain;
        @media (max-width: 576px) {
            background-image: none;
        }
    }
    .ant-modal-body {
        padding: 24px;
        height: calc(100vh - 100px);
        max-height: 800px !important;    
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 576px) {
            max-height: 350px !important;    
        }
        
        .up{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .logo{
                height: 60px;
                margin-bottom: 10px;
            }
            h3{
                font-size: 24px;
                color:  #fff;
            }
        }
        .down{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            height: 110px;
            p{
                margin-bottom: 0px;
                color:  #fff;
            }
            .login{
                cursor: pointer;
            }
            .other{
                cursor: pointer;
                color: #00000073;
            }
        }
        
    }
    .ant-modal-header {
        height: 50px;
        border-radius: 10px 10px 0px 0px;
    }
    .ant-modal-footer {
        display: none !important;
    }
}