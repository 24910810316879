.contact-content-wrapper {
  padding: 0 50px;
  margin-top: 20px;
  // margin-top: 90px;

  .contact-content-container {
    max-width: $wrapper-max-width;
    margin: 0 auto;
    height: calc(100vh - 126px);
  }
  .social-navigation {
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    background-color: #fff;
  }

  @media (max-width: 992px) {
    padding: 0px;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    // height: 100vh;
    z-index: 99;
    background: #f3f6f9;

    .contact-page-content {
      height: auto;
      padding: 16px !important;
    }
    .contact-content-container {
      padding: 16px;
      width: 100%;
      height: auto !important;
    }
    .contact-inner {
      height: auto !important;
    }
  }
  @media (max-width: 992px) {
    .social-navigation {
      margin-bottom: 15px;
    }
  }
}
.contact-page-content {
  height: calc(100vh - 190px);
  .ant-input-group-addon {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .contact-list-container {
    margin-top: 20px;
    .list-title {
      font-size: 16px;
    }

    .contact-list {
      overflow-x: hidden;
      position: absolute;
      height: calc(100vh - 320px);
      display: flex;
      flex-direction: column;
      // background-color: #02B8F6;
      overflow-y: auto;
      width: 100%;
      padding-right: 8px;
      padding-left: 8px;

      .contact {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 8px;
        padding: 16px;
        background-color: #fff;
        margin-bottom: 10px;
        border: 1px solid #d9d9d9;

        .text-group {
          margin-left: 14px;
          h3 {
            margin-bottom: 0px;
            font-size: 16px;
          }
          .mutuals {
            display: flex;
            flex-direction: row;
            p {
              color: #8c8c8c;
              margin-left: 10px;
              margin-bottom: 0px;
            }
          }
        }
        .mutuals-container {
          .mutual-text {
            font-size: 12px;
            color: #8c8c8c;
          }
        }
      }
    }
  }
  @media (max-width: 992px) {
    // padding: 0 16px;
    .contact-list {
      position: relative !important;
      height: auto !important;
      .contact {
        h2 {
          font-size: 16px;
        }
        p {
          font-size: 14px;
        }
        button {
          height: auto;
          width: auto;
          // padding-left: 20px;
          // padding-right: 20px;
        }
        .ant-btn-icon-only {
          width: auto;
        }
        .mutuals-container {
          .mutual-text {
            font-size: 12px;
          }
        }
      }
      .contact-empty-state {
        height: 250px;
      }
    }
    // bottom: 40px;
  }
  @media (max-width: 767px) {
    // padding: 0 16px;
    .contact-list {
      position: relative !important;
      height: auto !important;
      .contact {
        // padding: 8px !important;
        h2 {
          font-size: 14px;
        }
        p {
          font-size: 12px;
        }
        .ant-avatar-circle {
          width: 36px !important;
          height: 36px !important;
          line-height: 36px !important;
          font-size: 16px !important;
        }
        .ant-avatar-string {
          line-height: 36px !important;
        }
        button {
          height: auto;
          width: auto;
          font-size: 12px !important;
        }
        .ant-btn-icon-only {
          width: auto;
        }
        .mutuals-container {
          .mutual-avatar {
            display: none;
          }
          .mutual-text {
            font-size: 10px;
          }
        }
        .contact-action {
          width: 100px;
        }
      }
      .contact-empty-state {
        height: 250px;
      }
    }
    // bottom: 40px;
  }
}
.social-action-btn {
  width: 272px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.14);
  -webkit-box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.14);
  padding: 0;
  border-radius: 8px;
  li {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .opt-title {
    font-size: 14px;
    color: #262626;
  }
  .opt-desc {
    color: #595959;
    font-size: 10px;
    line-height: 16px;
  }
}
