.participant-widget {
  .participant-widget-header {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .participant-widget-title {
      font-size: 16px;
    }

    .participant-widget-filter {
    }
  }
  .participant-widget-body {
    .ant-collapse {
      background-color: white;
      border: 1px solid #f0f0f0;
    }
    .ant-collapse-item {
      border-bottom: 1px solid #f0f0f0;
    }
    .ant-collapse-item-active {
      .ant-collapse-header {
        background-color: #fafafa;
      }
      
      .ant-collapse-content {
        border-top: none;
        .ant-list-item-meta-avatar {
          margin-top: 8px;
        }
        .ant-collapse-content-box {
          margin-top: 0;
        }
      }
    }
  }
}
.admin-participant-panel {
  .ant-collapse-content-box {
    padding-top: 0px;
  }
}
