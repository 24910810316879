.modal-banner-promotion{
    width: 800px !important;
    .ant-modal-content {
        border-radius: 10px;
    }
    .ant-modal-body {
        padding: 24px;
        min-height: 500px;    
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        .banner-carousel{
            a{
                width: 100%;
                display: flex !important;
                flex-direction: row;
                align-items: center;
                justify-content: center;
    
                img{
                    width: auto !important;
                    max-width: 752px !important;
                    max-height: calc(100vh - 150px);
                    @media (max-width: 992px) {
                        max-width: 100% !important;
                    }
                }
            }
            .slick-dots li button{
                width: 12px;
                height: 12px;
                border-radius: 50px;
                background-color: transparent;
                border: 3px solid #BFBFBF;
            }
            .slick-dots li.slick-active button{
                background-color:#BFBFBF;
            }
        }
    }
    .ant-modal-header {
        height: 50px;
        border-radius: 10px 10px 0px 0px;
    }
    .ant-modal-footer {
        display: none !important;
    }
}